import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import QRCode from "qrcode.react";
import dummyData from "./dummyData";
import ServerService from "../../../../API/ServerService";
import url from "../../../../API/baseURL/url";
import "./ViewReceipt.css";
import { Button, message, Modal } from "antd";

const ViewReceipt = React.forwardRef((props, ref) => {

  const history = useHistory();
  const [receiptsData, setReceiptsData] = useState(null);
  const [showingAll, setShowingAll] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes("all")) {
      setShowingAll(true);
    }

    if (props.spanId === null || props.spanId === undefined) {
      // Get reciept-data
      ServerService.GetReceiptData(props.receiptId, props.studentId)
        .then((res) => {
          // console.log(res);
          setReceiptsData(res.data);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
    else {
      ServerService.GetReportData(props.spanId, props.studentId)
        .then((res) => {
          // console.log(res);
          setReceiptsData(res.data);
        })
        .catch((err) => {
          // console.log(err);
        });
    }

  }, []);

  if (receiptsData === null) {
    return <div className="displayArea"></div>;
  }

  const DeleteReceipt = () => {
    Modal.confirm({
      title: 'Are you sure delete this receipt?',
      content: 'This is permanent',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {

        ServerService.DeleteReceipt(props.receiptId)
          .then((res) => {
            history.push(`/studentDetails/${props.studentId}`);
            message.success("Receipt Deleted Successfully");
          })
          .catch((err) => {
            message.error("Receipt Deletion Failed");
          })
      },
    });
  }



  return (
    <>
      {props.receiptId !== "all" && receiptsData[0].isEditable ? (
        <>
          <Button
            style={{ width: "803px", margin: "auto" }}
            onClick={() => history.push(`/studentDetails/${props.studentId}/edit-receipt/${props.receiptId}`)}
          >
            Edit Receipt
          </Button>
          <Button
            style={{ width: "803px", margin: "auto", color: "red", marginTop: "10px" }}
            onClick={DeleteReceipt}
          >
            Delete Receipt
          </Button>
        </>
      ) : null}
      <div ref={ref}>
        {receiptsData.map((receiptData, index) => {
          return (
            <div key={index} className="receiptContainer">
              <div className="pdfHeader">
                {receiptData.instituteDetail.logo === null ? (
                  <div
                    style={{
                      minWidth: "110px",
                      maxWidth: "110px",
                      minHeight: "90px",
                      maxHeight: "90px",
                    }}
                  ></div>
                ) : (
                  <img
                    style={{
                      minWidth: "110px",
                      maxWidth: "110px",
                      minHeight: "90px",
                      maxHeight: "90px",
                    }}
                    src={receiptData.instituteDetail.logo}
                    alt="instituteLogo"
                  />
                )}
                <div className="collegeDetails">
                  <h5 className="heading">Fee Receipt</h5>
                  <h6 className="collegeName">
                    {receiptData.instituteDetail.institutionName}
                  </h6>
                  <h6 className="location">
                    {receiptData.instituteDetail.address}
                  </h6>
                  <h6 className="">
                    {`Affiliated To: ${receiptData.instituteDetail.affiliatedTo}`}
                  </h6>
                  <h6 className="">
                    {`College Code: ${receiptData.instituteDetail.affiliationCode}`}
                  </h6>
                </div>
                <div className="scanner">
                  <div className="copy">Student Copy</div>
                  <QRCode
                    width="50px"
                    className="qrCode"
                    value={`${url}/api/fee/receipt/${receiptData.id}/?download=true`}
                  />
                  <h6>Receipt ID : {receiptData.recieptId}</h6>
                </div>
              </div>

              <div className="studentDetailsSection">
                <div className="details">
                  <div className="data">
                    <h6 className="label">Enrollment ID:</h6>
                    <h6 className="value">{receiptData.enrollmentId}</h6>
                  </div>
                  <div className="data">
                    <h6 className="label">Name:</h6>
                    <h6 className="value">{receiptData.studentName}</h6>
                  </div>
                  <div className="data">
                    <h6 className="label">Father's Name:</h6>
                    <h6 className="value">{receiptData.father_name}</h6>
                  </div>
                  <div className="data">
                    <h6 className="label">Submitted By:</h6>
                    <h6 className="value">{receiptData.submittedBy}</h6>
                  </div>
                  {receiptData.studentDetails.Address ? (<div className="data">
                    <h6 className="label">Address:</h6>
                    <h6 className="value">{receiptData.studentDetails.Address}</h6>
                  </div>) : null}
                </div>

                <div className="details">
                  <div className="data">
                    <h6 className="label">Course:</h6>
                    <h6 className="value">{receiptData.course}</h6>
                  </div>
                  <div className="data">
                    <h6 className="label">Branch:</h6>
                    <h6 className="value">{receiptData.branch}</h6>
                  </div>
                  <div className="data">
                    <h6 className="label">Semester:</h6>
                    <h6 className="value">{receiptData.passing_span}</h6>
                  </div>
                  <div className="data">
                    <h6 className="label">Batch:</h6>
                    <h6 className="value">{receiptData.batch}</h6>
                  </div>
                </div>
              </div>

              <div className="feeTable">
                <div className="tableHeader">
                  <h6 className="sno">S.No</h6>
                  <h6 className="feeType">Fee Type</h6>
                  <h6 className="amount">Amount</h6>
                  <h6 className="note">Payment Method</h6>
                </div>
                {receiptData.fee_detail.map((fee, index) => {
                  return (
                    <div className="tableRow">
                      <h6 className="sno">{index + 1}.</h6>
                      <h6 className="feeType">{fee.type}</h6>
                      <h6 className="amount">{fee.amount}</h6>
                      <h6 className="note">{fee.comment}</h6>
                    </div>
                  );
                })}
                <div className="tableFooter">
                  <h6 className="sno">Total Amount: </h6>
                  <h6 className="feeType">{`₹ ${receiptData.amount_paid}`}</h6>
                </div>
              </div>

              <div className="pdfFooter">
                <div className="paymentDetails">
                  <div className="paymentData">
                    <h6 className="label">Total Payable Amount: </h6>
                    <h6>{`₹ ${receiptData.totalPayableAmount}`}</h6>
                  </div>
                  <div className="paymentData">
                    <h6 className="label">Pending Amount: </h6>
                    {receiptData.due_amount < 0 ? (<h6>{`₹ ${receiptData.due_amount}`}</h6>) : (<h6 style={{ color: "red" }}>{`₹ ${receiptData.due_amount}`}</h6>)}
                  </div>
                  <div className="paymentData">
                    <h6 className="label">Date: </h6>
                    <h6>{receiptData.created_at.slice(0, 10)}</h6>
                  </div>
                </div>

                <div className="stampArea">
                  <h4>Authorized Signature</h4>
                  {/* <img className="stamp" src={dummyData.stampSrc} alt="stamp"/>
                  <img className="sign" src={dummyData.signSrc} alt="signature" /> */}
                </div>
              </div>
            </div>
          );
        })}

        {receiptsData.map((receiptData, index) => {
          return (
            <div key={index} className="receiptContainer">
              <div className="pdfHeader">
                {receiptData.instituteDetail.logo === null ? (
                  <div
                    style={{
                      minWidth: "110px",
                      maxWidth: "110px",
                      minHeight: "90px",
                      maxHeight: "90px",
                    }}
                  ></div>
                ) : (
                  <img
                    style={{
                      minWidth: "110px",
                      maxWidth: "110px",
                      minHeight: "90px",
                      maxHeight: "90px",
                    }}
                    src={receiptData.instituteDetail.logo}
                    alt="instituteLogo"
                  />
                )}
                <div className="collegeDetails">
                  <h5 className="heading">Fee Receipt</h5>
                  <h6 className="collegeName">
                    {receiptData.instituteDetail.institutionName}
                  </h6>
                  <h6 className="location">
                    {receiptData.instituteDetail.address}
                  </h6>
                  <h6 className="">
                    {`Affiliated To: ${receiptData.instituteDetail.affiliatedTo}`}
                  </h6>
                  <h6 className="">
                    {`College Code: ${receiptData.instituteDetail.affiliationCode}`}
                  </h6>
                </div>
                <div className="scanner">
                  <div className="copy">Institution Copy</div>
                  <QRCode
                    width="50px"
                    className="qrCode"
                    value={`${url}/api/fee/receipt/${receiptData.id}/?download=true`}
                  />
                  <h6>Receipt ID : {receiptData.recieptId}</h6>
                </div>
              </div>

              <div className="studentDetailsSection">
                <div className="details">
                  <div className="data">
                    <h6 className="label">Enrollment ID:</h6>
                    <h6 className="value">{receiptData.enrollmentId}</h6>
                  </div>
                  <div className="data">
                    <h6 className="label">Name:</h6>
                    <h6 className="value">{receiptData.studentName}</h6>
                  </div>
                  <div className="data">
                    <h6 className="label">Father's Name:</h6>
                    <h6 className="value">{receiptData.father_name}</h6>
                  </div>
                  <div className="data">
                    <h6 className="label">Submitted By:</h6>
                    <h6 className="value">{receiptData.submittedBy}</h6>
                  </div>
                  {receiptData.studentDetails.Address ? (<div className="data">
                    <h6 className="label">Address:</h6>
                    <h6 className="value">{receiptData.studentDetails.Address}</h6>
                  </div>) : null}
                </div>

                <div className="details">
                  <div className="data">
                    <h6 className="label">Course:</h6>
                    <h6 className="value">{receiptData.course}</h6>
                  </div>
                  <div className="data">
                    <h6 className="label">Branch:</h6>
                    <h6 className="value">{receiptData.branch}</h6>
                  </div>
                  <div className="data">
                    <h6 className="label">Year:</h6>
                    <h6 className="value">{receiptData.passing_span}</h6>
                  </div>
                  <div className="data">
                    <h6 className="label">Session:</h6>
                    <h6 className="value">{receiptData.batch}</h6>
                  </div>
                </div>
              </div>

              <div className="feeTable">
                <div className="tableHeader">
                  <h6 className="sno">S.No</h6>
                  <h6 className="feeType">Fee Type</h6>
                  <h6 className="amount">Amount</h6>
                  <h6 className="note">Payment Method</h6>
                </div>
                {receiptData.fee_detail.map((fee, index) => {
                  return (
                    <div className="tableRow">
                      <h6 className="sno">{index + 1}.</h6>
                      <h6 className="feeType">{fee.type}</h6>
                      <h6 className="amount">{fee.amount}</h6>
                      <h6 className="note">{fee.comment}</h6>
                    </div>
                  );
                })}
                <div className="tableFooter">
                  <h6 className="sno">Total Amount: </h6>
                  <h6 className="feeType">{`₹ ${receiptData.amount_paid}`}</h6>
                </div>
              </div>

              <div className="pdfFooter">
                <div className="paymentDetails">
                  <div className="paymentData">
                    <h6 className="label">Total Payable Amount: </h6>
                    <h6>{`₹ ${receiptData.totalPayableAmount}`}</h6>
                  </div>
                  <div className="paymentData">
                    <h6 className="label">Pending Amount: </h6>
                    {receiptData.due_amount < 0 ? (<h6>{`₹ ${receiptData.due_amount}`}</h6>) : (<h6 style={{ color: "red" }}>{`₹ ${receiptData.due_amount}`}</h6>)}
                  </div>
                  <div className="paymentData">
                    <h6 className="label">Date: </h6>
                    <h6>{receiptData.created_at.slice(0, 10)}</h6>
                  </div>
                </div>

                <div className="stampArea">
                  <h4>Authorized Signature</h4>
                  {/* <img className="stamp" src={dummyData.stampSrc} alt="stamp"/>
                  <img className="sign" src={dummyData.signSrc} alt="signature" /> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>

    </>
  );
});

export default ViewReceipt;
