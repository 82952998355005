import React, { useState, useEffect } from "react";
import { Tabs, Breadcrumb, Button, Modal } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory, useParams, Link, useLocation } from "react-router-dom";
// import dummyData from "./dummyData";
import BatchData from "../BatchData/BatchData";
import styles from "./ViewBatches.module.css";
import ServerService from "../../../API/ServerService";
import { useSelector } from "react-redux";

const {confirm} = Modal;

const { TabPane } = Tabs;

const ViewBatches = () => {
  const { branchId } = useParams();
  const UserType = useSelector((state) => {
    if (state.auth.user) {
      return state.auth.user.role
    }
  });

  const history = useHistory();
  const [allBatches, setAllBatches] = useState([]);
  const [courseId, setCourseId] = useState();
  const location = useLocation();

  const url = [
    { name: "Dashboard", link: "/AdminDashboard" },
    { name: "allCourses", link: "allCourses" },
    {
      name: location.state.courseName,
      link: "branches/" + location.state.courseId,
    },
    { name: location.state.branchName, link: "batches/" + branchId },
  ];
  // url.splice(2,1,"Dashboard")

  useEffect(() => {
    ServerService.GetBatchFee(branchId)
      .then((res) => {
        console.log(res);
        setAllBatches(res.data);
        if (res.data.length > 0) {
          setCourseId(res.data[0].courseId);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const DeleteBranch = () => {
    confirm({
      title: 'Are you sure delete this Branch?',
      icon: <ExclamationCircleOutlined />,
      content: 'All the student data within the branch will be deleted.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        console.log('OK');
        ServerService.DeleteBranch(branchId)
        .then((res) => {
          console.log(res);
          history.push(`/branches/${location.state.courseId}`);
        })
        .catch((err) => {
          console.log(err);
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  
  
  const handleTabChange = () => {console.log("tab change") };

  return (
    <div className={styles.displayArea}>
      <Breadcrumb style={{ marginBottom: "24px" }}>
        {url.map((entry, index) => {
          return (
            <Breadcrumb.Item key={index}>
              <Link style={{ textDecoration: "none" }} to={`/${entry.link}`}>
                {entry.name}
              </Link>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>

      {UserType === "admin" ? (
        <Button
          onClick={DeleteBranch}
          style={{
            float: "right",
          }}
        >
          <DeleteOutlined
            style={{
              color: "red",
              fontSize: "17.2px",
              paddingBottom: "0px",
              marginRight: "0px",
            }}
          />
          Delete Branch
        </Button>
      ) : null}

      <Tabs defaultActiveKey="0" onChange={handleTabChange}>
        <TabPane
          style={{
            backgroundColor: "white",
            minHeight: "70vh",
            border: "#ccc 1px solid",
            padding: "30px 16px",
            position: "relative",

          }}
          tab="Active Batches"
          key="0"
        >
          {
            UserType === "admin" ? (
              <>
                <Button
                  onClick={() => history.push(`/add-batch/${branchId}`)}
                  className={styles.addBatch}
                  type="primary"
                  style={{ color: "white" }}
                >
                  Create Batch
                </Button>
              </>
            ) : null}

          <Tabs style={{ minHeight: "60vh" }} tabPosition={"left"} >
            {allBatches.map((batch, index) => {
              return (
                <TabPane tab={batch.name} key={index}>
                  <BatchData batchData={batch} />
                </TabPane>
              );
            })}
          </Tabs>
        </TabPane>

        <TabPane
          style={{
            backgroundColor: "white",
            minHeight: "70vh",
            border: "#ccc 1px solid",
            padding: "30px 16px",
            position: "relative",
          }}
          tab="Inactive Batches"
          key="1"
        >
          {
            UserType === "admin" ? <Button
              onClick={() => history.push(`/add-fee`)}
              className={styles.addBatch}
              type="primary"
              style={{ color: "white" }}
            >
              Create Batch
            </Button> : null}


          <Tabs style={{ minHeight: "60vh" }} tabPosition={"left"}>
            {allBatches.map((batch, index) => {
              return (
                <TabPane tab={batch.batchName} key={index}>
                  <BatchData batchData={batch} />
                </TabPane>
              );
            })}
          </Tabs>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ViewBatches;
