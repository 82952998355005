import React from "react";
import { Input, Select, Switch } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import styles from "./Field.module.css";

const { Option } = Select;
const fieldTypeOptions = ["string","integer","float","number","boolean","date"]

const Field = (props) => {

  const handleFieldNameChange = (e) => {
    props.modifyFieldName(props.fieldIndex,e.target.value);
  };
  const handleFieldTypeChange = (fieldType) => {
    props.modifyFieldType(props.fieldIndex,fieldType);
  };
  const onToggleChange = (toggleVal) => {
    props.modifyFieldRequirement(props.fieldIndex,toggleVal);
  };
  const deleteHandler = () => {
    props.deleteField(props.fieldIndex);
  }

  const { fieldData } = props;

  return (
    <div className={styles.field}>
      <div className={styles.fieldHeader}>
        <div className={styles.fieldProp} style={{ width: "60%" }}>
          <h6 className={styles.fieldName}>Field Name :</h6>
          <Input
            style={{
              backgroundColor: "#EEF0F4",
              borderRadius: "2px",
              minWidth: "50%",
            }}
            value={fieldData.fieldName}
            placeholder="Type field-name here..."
            onChange={handleFieldNameChange}
          />
        </div>

        <div className={styles.fieldProp} style={{ flexGrow: "1" }}>
          <h6 className={styles.fieldName}>Field Type :</h6>
          <Select
            defaultValue={fieldTypeOptions[0]}
            style={{ width: "100%" }}
            onChange={handleFieldTypeChange}
            value={fieldData.fieldType}
          >
            {fieldTypeOptions.map((fieldTypeOption) => {
              return <Option value={fieldTypeOption}>{fieldTypeOption}</Option>;
            })}
          </Select>
        </div>
      </div>

      <div className={styles.fieldProp} style={{ display: "flex", alignItems: "center", marginRight:"40px" }}>
        <Switch onChange={onToggleChange} checked={fieldData.isRequired}/>
        <span className={styles.isRequired}>Required</span>
      </div>

      <div
        className={styles.fieldProp}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={deleteHandler}
      >
        <DeleteTwoTone className={styles.deleteIcon} twoToneColor="red" />
        <span className={styles.isRequired}>Delete field</span>
      </div>
    </div>
  );
};

export default Field;
