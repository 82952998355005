import React from "react";
import CountUp from "react-countup";
import Skeleton from "react-loading-skeleton";
import "./DashboardCard.css";
import { useHistory } from "react-router-dom";

const DashboardCard = (props) => {
  const history = useHistory();
  const colors = ["#66CDAA", "#CD8282", "#82A8CD", "#999999"];
  const labelOnClickHandeler = (label) => {
   if(label === 'Total Students'){
      history.push('view-students/all')
    }
    if(label === 'Courses'){
      history.push('allCourses')
    }
  }
  if (props.value === null) {
    return (
      <Skeleton
        style={{ borderRadius: "12px", marginBottom: "24px" }}
        width={300}
        height={125}
      />
    );
  }

  return (
    <div className="dashboardCard" onClick={()=>{labelOnClickHandeler(props.label)}}>
      
      {props.hatIcon ? <div
        style={{ backgroundColor: `${colors[props.color]}` }}
        className="cardIcon">
         <i class="fas fa-graduation-cap" />
      </div> 
      : <img src={props.icon} alt="moess icons for dashboard"/> }
      <div>
        <h6 >{props.label}</h6>
        <CountUp className="countUp" end={props.value} />
      </div>
    </div>
  );
};

export default DashboardCard;
