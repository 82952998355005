const intialState = {
  selectedReceipts: [],
};


const Receipts = (state = intialState, action) => {

  switch (action.type) {

    case 'VIEW_RECEIPTS':
      return { ...state, selectedReceipts: action.payload };
    default:
      return state;
  
  }
};

export default Receipts;