import React, { useEffect, useState } from "react";
import { EyeOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { useParams } from "react-router";
import LoadingData from "./LoadingData";
import BranchYearCard from "../../../../components/Cards/BranchYearCard/BranchYearCard";
import branchDummyData from "./branchDummyData";
import styles from "./RedirectionList.module.css";
import { useHistory, useLocation, Link } from "react-router-dom";
import ServerService from "../../../../API/ServerService";

const BranchesList = () => {
  const { courseId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [allBranches, setAllBranches] = useState(null);

  const url = [
    { name: "Dashboard", link: "/AdminDashboard" },
    { name: "view-students", link: "/view-students" },
  ];

  useEffect(() => {
    ServerService.GetBranches(courseId)
      .then((res) => {
        // console.log(res);
        setAllBranches(branchDummyData);
        setAllBranches(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (allBranches === null) {
    return <LoadingData />;
  }

  return (
    <div className={styles.displayArea}>
      <div style={{ display: "flex", gap: "10px" }}>
        <span className="back-arrow" onClick={() => history.goBack()}>
          <ArrowLeftOutlined />
        </span>
        <Breadcrumb style={{ marginBottom: "24px" }}>
          {url.map((entry, index) => {
            return (
              <Breadcrumb.Item key={index}>
                <Link style={{ textDecoration: "none" }} to={`${entry.link}`}>
                  {entry.name}
                </Link>
              </Breadcrumb.Item>
            );
          })}
          {location.state ? (
            <Breadcrumb.Item>{location.state.course}</Breadcrumb.Item>
          ) : (
            <></>
          )}
        </Breadcrumb>
      </div>
      <h6 className={styles.heading}>Available branches</h6>
      <h6
        onClick={() => history.push(`/view-students/${courseId}`)}
        className={styles.viewAllCourseButton}
      >
        View all students in course
        <EyeOutlined style={{ marginLeft: "6px" }} twoToneColor="#52c41a" />
      </h6>

      <div className={styles.allBranches}>
        {allBranches &&
          allBranches.map((branch, index) => {
            return (
              <BranchYearCard
                viewStudents={true}
                courseId={courseId}
                data={branch}
                index={index}
              />
            );
          })}
      </div>
    </div>
  );
};

export default BranchesList;
