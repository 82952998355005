import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from "./SearchResults.module.css";
import StudentSearchCard from '../../components/StudentSearchCard/StudentSearchCard.jsx';
import ServerService from '../../API/ServerService';
import { Link } from 'react-router-dom';

const SearchResults = () => {

  const { searchType, query } = useParams();
  const history = useHistory();
  const [studentDetails, setStudentDetails] = React.useState([]);
  const [nextPage, setNextPage] = React.useState(null);
  const [totalDataCount, setTotalDataCount] = React.useState(null);


  React.useEffect(() => {

    ServerService.GetSearchStudents(searchType, nextPage)
      .then((res) => {
        setStudentDetails(res.data);
        // setTotalDataCount(res.data.count);
      })
      .catch((err) => {
        console.log(err);
      })
  }, [])

  const filteredSearch = (students, query) => {

    query = query.toLowerCase();
    // else covert both to lowercase and match
    return students.filter((student) => {
      const studentName = student.full_name.toLowerCase();
      const studentId = student.enrollment_id.toLowerCase();
      const fatherName = student.father_name.toLowerCase();
      const receipt_id = student.receipt_id !== undefined ? student.receipt_id.toLowerCase() : [];
      return studentName.includes(query) || studentId.includes(query) || fatherName.includes(query) || receipt_id.includes(query);
    }).splice(0, 100);

  };

  // calling the search function
  const filteredSubject = filteredSearch(studentDetails, query);

  return (
    <div className={styles.displayArea}>
      <div className={styles.cardArea}>
        {query == "enteryourquery" || !studentDetails ? <h><b>Search using student code or name</b></h> :

          filteredSubject.map((item, index) => {

            return (<Link style={{ textDecoration: "none" }}
              to={searchType === 'student' ? `/studentDetails/${item.id}` : `/studentDetails/${item.studentId}/view-receipt/${item.id}`}>
              <StudentSearchCard key={index}
                data={item}
                cardType={searchType}
              /></Link>)

          })

        }

        {query != "enteryourquery" && studentDetails.length != 0 && filteredSubject.length == 0 ?
          <h><b>No  student record found</b>!</h> : null}



      </div>
    </div>
  );
};

export default SearchResults;