import { Doughnut } from 'react-chartjs-2';
import Skeleton from 'react-loading-skeleton';
import "./DoughnutGraph.css";

const DoughnutGraph = (props) => {

  let graphData = null;
  if(props.data){
    graphData ={
      // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
      labels: ['Male Students', 'Female Students'],
      datasets: [
        {
          label: '# of Votes',
          data: [props.data.males,props.data.females],
          backgroundColor: [
            '#E9E6FF',
            '#FFABD6',
            // 'rgba(255, 206, 86, 0.2)',
            // 'rgba(75, 192, 192, 0.2)',
            // 'rgba(153, 102, 255, 0.2)',
            // 'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            '#462AFF',
            '#E50061',
            // 'rgba(255, 206, 86, 1)',
            // 'rgba(75, 192, 192, 1)',
            // 'rgba(153, 102, 255, 1)',
            // 'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    }
  }


  if(!props.data){
    return <Skeleton width={360} height={408} style={{borderRadius:'12px',marginTop:'24px'}}/>
  }

  return (
      <div className='doughnutGraph'>
        <h6 className='heading'>STUDENTS</h6>
        <Doughnut
          width='50%'
          // height={365}
          height={390}
          data={graphData}
          // options={{ maintainAspectRatio: false, plugins: { legend: { position: 'bottom' } } }}
        />
      </div>
  );
};

export default DoughnutGraph;
