import StudentDetails from "./StudentDetails";
import { useParams } from "react-router";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { PrinterOutlined } from "@ant-design/icons";
import styles from "./StudentDetails.module.css";

const StudentDetail = () => {
  const { studentId } = useParams();
  const componentRef = useRef();

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <div style={{ position: "relative" }}>
            <div className={styles.printIcon}>
              <span>Print</span>
              <PrinterOutlined
                style={{ paddingLeft: "8px", marginRight: "0px" }}
              />
            </div>
          </div>
        )}
        content={() => componentRef.current}
      />
      <StudentDetails ref={componentRef} studentId={studentId} />
    </>
  );
};

export default StudentDetail;
