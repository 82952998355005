import React from "react";
import styles from "./ViewFaculty.module.css";
import Skeleton from "react-loading-skeleton";

const LoadingData = () => {
    const arr = [1, 1, 1, 1, 1, 1, 1];

    return (
        <div className={styles.displayArea}>
            <div className={styles.allFaculty}>

                {arr.map(() => {
                    return (
                        <Skeleton
                            style={{
                                borderRadius: "12px",
                                margin: "0px 20px 20px 0px"
                            }}
                            width={290}
                            height={150}
                        />
                    );
                })}

            </div>
        </div>
    );
};

export default LoadingData;