import React from 'react';
import {Link} from 'react-router-dom';



const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text,record) => <Link to={`viewAgent/profile/${record.id}`}>{text}</Link>,
    },
    {
      title: 'Commission',
      className: 'column-money',
      dataIndex: 'commission',
      align: 'left',
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
        title: 'Phone',
        dataIndex: 'phone_number',
      },
  ];
  
  


export default columns;


