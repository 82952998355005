import React, { useState } from "react";
import styles from "./AddBranch.module.css";
import { Form, Select, Input, Button, Breadcrumb } from "antd";
import { message, notification } from "antd";
import { Link, useLocation, useHistory } from 'react-router-dom';
import {
  BookOutlined,
  SmileOutlined,
  FrownOutlined,
} from "@ant-design/icons";
import ServerService from "../../../API/ServerService";
import { useEffect } from "react";

const { Option } = Select;

const AddBranch = () => {

  const [form] = Form.useForm();
  const [edit, setEdit] = useState("true");
  const [courseList, setCourseList] = useState([]);


  let location = useLocation();
  const history = useHistory();

  let url;
  if (location.state) {
    url = [{ name: "Dashboard", link: "/AdminDashboard" },
    { name: "AllBranches", link: "/branches/all" }
      , { name: "EditBranch", link: "/addBranch" }]
  }
  else if (!location.state) {
    url = [{ name: "Dashboard", link: "/AdminDashboard" },
    { name: "AddBranch", link: "/addBranch" }]
  }


  useEffect(() => {

    ServerService.GetCourses()
      .then((res) => {
        // console.log(res);
        setCourseList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [])

  const openNotification = (title, description, icon, duration) => {
    notification.open({
      message: title,
      description: description,
      icon: icon,
      duration: duration,
    });
  };

  const onSubmit = (values) => {
    let key = "updatable";
    //loading msg
    message.loading({ content: "Action in progress...", key });

    const newBranchData = {
      course: values.courseId,
      name: values.branchName,
      code: values.branchCode
    };
    // console.log(newBranchData);
    if (location.state) {
      newBranchData.course = location.state.courseId;

      ServerService.EditBranch(newBranchData, location.state.branchId)
        .then((res) => {
          // console.log(res);

          //If branch was added successfullly
          message.success({ content: "Action completed!", key, duration: 2 });
          openNotification(
            "Branch editted successfully!",
            "The branch was editted successfully. Please head to all branches to view all branches.",
            <SmileOutlined style={{ color: "#108ee9" }} />,
            4.5
          );
          setEdit(null)
        })
        .catch((err) => {
          console.log(err);
          let errMsg = "We are having some trouble creating the course. Please try again later";
          if (err.response.data.detail) {
            errMsg = `${err.response.data.detail}`;
          }

          //If some error occured
          message.error({ content: "Something went wrong!", key, duration: 2 });
          openNotification(
            "Something went wrong!",
            `${errMsg}`,
            // "We are having some trouble editting the course. Please try again later",
            <FrownOutlined style={{ color: "red" }} />,
            6
          );
          setEdit(null)
        });
    }
    else {
      ServerService.AddBranch(newBranchData)
        .then((res) => {

          //If branch was added successfullly
          message.success({ content: "Action completed!", key, duration: 2 });
          openNotification(
            "Branch added successfully!",
            "The branch was created successfully. Please head to all branches to view all branches.",
            <SmileOutlined style={{ color: "#108ee9" }} />,
            4.5
          );
          form.resetFields();
          console.log(location.state);
          !location.state?history.push(`/branches/${res.data.course}`):history.push(`/branches/all`);

        })
        .catch((err) => {
          console.log(err);

          let errMsg = "We are having some trouble creating the course. Please try again later";
          if (err.response.data.detail) {
            errMsg = `${err.response.data.detail}`;
          }

          //If some error occured
          message.error({ content: "Something went wrong!", key, duration: 2 });
          openNotification(
            "Something went wrong!",
            `${errMsg}`,
            <FrownOutlined style={{ color: "red" }} />,
            6
          );
          form.resetFields();
        });
    }
  };

  return (
    <div className={styles.displayArea}>
      <Breadcrumb style={{ marginBottom: "24px" }} className={styles.Breadcrumb}>
        {url.map((entry, index) => {
          return <Breadcrumb.Item key={index}>
            <Link style={{ textDecoration: 'none' }} to={`${entry.link}`}>
              {entry.name}
            </Link>
          </Breadcrumb.Item>
        }
        )}
      </Breadcrumb>
      <div className={styles.addBranchForm}>
        <Form
          form={form}
          name="normal_login"
          className="add-course-form"
          initialValues={location.state && edit ? {
            branchName: location.state.branchName,
            branchCode: location.state.branchId, courseId: location.state.CourseName
          } : { remember: true }}
          onFinish={onSubmit}
        >
          <h6 className={styles.formHeader}>{location.state ? "Edit Branch" : "Add New Branch"}</h6>

          <h6 className={styles.label}>Branch Name<span style={{ color: "red" }}>*</span></h6>
          <Form.Item
            name="branchName"
            rules={[
              {
                required: true,
                message: "Please enter the branch name!",
              },
            ]}
          >
            <Input
              autoComplete="off"
              prefix={<BookOutlined className="site-form-item-icon" />}
              placeholder="Branch Name"
            />
          </Form.Item>

          {/* <h6 className={styles.label}>Branch Code</h6>
          <Form.Item rules={[
            {
              required: true,
              message: "Please enter the branch code!",
            },
            {
              max: 5,
              message: "More than 5 characters not allowed!",
            }
          ]} name="branchCode">
            <Input
              disabled={location.state ? true : false}
              autoComplete="off"
              prefix={<BookOutlined className="site-form-item-icon" />}
              placeholder="Branch Code"
            />
          </Form.Item> */}

          <h6 className={styles.label}>Select Course<span style={{ color: "red" }}>*</span></h6>
          <Form.Item
            name="courseId"
            rules={[
              {
                required: true,
                message: "Please select an existing course!",
              },
            ]}
          >
            <Select allowClear>
              {courseList.map((course, index) => {
                return (
                  <Option key={index} value={course.id}>{course.course_name}</Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.addCourseButton}
            >
              {location.state ? "Edit Branch" : "Add Branch"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};


export default AddBranch;
