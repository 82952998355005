import React, { useState, useEffect } from "react";
import { Button, message, notification } from "antd";
import Field from "./Field/Field";
import styles from "./FormCreation.module.css";
import { useHistory } from "react-router-dom";
import { SmileOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import FormLoadingData from "./FormLoadingData";
import ServerService from "../../../../API/ServerService";

const new_form_data = [
  {
    fieldName: "",
    fieldType: "string",
    isRequired: false,
  },
];

const FormCreation = () => {
  
  const history = useHistory();
  const [formData, setFormData] = useState(null);
  const [formId, setFormId] = useState(null);
  const [instituteId, setInstituteId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    // If form was already created and user is editing it!
    // if(window.location.pathname.includes('edit-form')){
    //   setIsEditing(true);
    //   setTimeout(() => {
    //     setFormData(edit_form_data);
    //   },1200)
    // }
    // else{
    //   setFormData(new_form_data);
    // }

    ServerService.IsFormCreated()
      .then((res) => {
        // console.log(res);
        if (res.data.isCreated === false) {
          setIsEditing(false);
          setFormData(new_form_data);
        } 
        else {
          setIsEditing(true);
          setFormId(res.data.id);
          setInstituteId(res.data.institution);
          let created_fields = [];
          for (let property in res.data.fields) {
            let obj = {
              fieldName: property,
              fieldType: res.data.fields[property].type,
              isRequired: res.data.fields[property].required,
            };
            created_fields.push(obj);
          }
          setFormData(created_fields);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const openNotification = (msg, desc, icon, duration) => {
    notification.open({
      message: msg,
      description: desc,
      icon: icon,
      duration: duration,
    });
  };

  // FIELD MODIFICATION FUNCTIONS
  const modifyFieldName = (index, field_name) => {
    let newFormData = [...formData];
    newFormData[index].fieldName = field_name;
    setFormData(newFormData);
  };
  const modifyFieldType = (index, field_type) => {
    let newFormData = [...formData];
    newFormData[index].fieldType = field_type;
    setFormData(newFormData);
  };
  const modifyFieldRequirement = (index, field_req) => {
    let newFormData = [...formData];
    newFormData[index].isRequired = field_req;
    setFormData(newFormData);
  };

  // RELATED TO FIELDS
  const deleteField = (field_index) => {
    let newFormData = [];
    formData.map((field, index) => {
      if (field_index !== index) {
        newFormData.push(field);
      }
    });
    setFormData(newFormData);
  };
  const addField = () => {
    let newFormData = [...formData];
    newFormData.push({
      fieldName: "",
      fieldType: "string",
      isRequired: false,
    });
    setFormData(newFormData);
  };

  // FORM SUBMISSION
  const saveForm = () => {
    // console.log(formData);
    const reqBody = {};
    formData.map((field, index) => {
      reqBody[field.fieldName] = {
        type: field.fieldType,
        required: field.isRequired,
      };
    });

    // console.log(reqBody);
    let key = "updatable";
    message.loading({ content: "Action in progress...", key });

    if (isEditing) {

      let editReqBody = {
        id: formId,
        institution: instituteId,
        fields: reqBody,
      }
      console.log(editReqBody);
      ServerService.EditForm(editReqBody)
        .then((res) => {
          console.log(res);
          message.success({ content: "Action completed!", key, duration: 2 });
          openNotification(
            "Form edited successfully!",
            "The form was edited successfully.",
            <SmileOutlined style={{ color: "#108ee9" }} />,
            4
          );
        })
        .catch((err) => {
          console.log(err);
          message.error({ content: "Something went wrong!", key, duration: 2 });
          openNotification(
            "Something went wrong!",
            "We are unable to process your request right now! Please try again after sometime.",
            <ExclamationCircleOutlined style={{ color: "red" }} />,
            4
          );
        });
    } 
    else {
      ServerService.CreateNewForm(reqBody)
        .then((res) => {
          console.log(res);
          message.success({ content: "Action completed!", key, duration: 2 });
          openNotification(
            "Form edited successfully!",
            "The form was edited successfully.",
            <SmileOutlined style={{ color: "#108ee9" }} />,
            4
          );
        })
        .catch((err) => {
          console.log(err);
          message.error({ content: "Something went wrong!", key, duration: 2 });
          openNotification(
            "Something went wrong!",
            "We are unable to process your request right now! Please try again after sometime.",
            <ExclamationCircleOutlined style={{ color: "red" }} />,
            4
          );
        });
    }
  };

  if (formData === null) {
    return <FormLoadingData />;
  }

  return (
    <div className={styles.displayArea}>
      <div className={styles.formCreation}>
        {isEditing ? (
          <h6 className={styles.formHeader}>Edit created form</h6>
        ) : (
          <h6 className={styles.formHeader}>Please create a form</h6>
        )}

        {formData.map((field, index) => {
          return (
            <Field
              modifyFieldName={modifyFieldName}
              modifyFieldType={modifyFieldType}
              modifyFieldRequirement={modifyFieldRequirement}
              deleteField={deleteField}
              fieldIndex={index}
              fieldData={field}
            />
          );
        })}

        <div onClick={addField} className={styles.addField}>
          + Add Field
        </div>

        <div className={styles.footer}>
          <Button
            onClick={() => history.push("/")}
            className={styles.cancelButton}
          >
            Cancel
          </Button>
          <Button
            onClick={saveForm}
            type="primary"
            className={styles.saveButton}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormCreation;
