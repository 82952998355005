import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  DatePicker,
  message,
  notification,
} from "antd";
import {
  PlusOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  FormOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import moment from "moment";
import "./BasicForm.css";
import ServerService from "../../../../API/ServerService";
import { useHistory, useParams } from "react-router-dom";

const { Option } = Select;

const BasicForm = () => {
  const [form] = Form.useForm();
  const { studentId } = useParams();
  const history = useHistory();

  const [showAgentFields, setShowAgentFields] = useState(false);
  const [showCustomFeeFields, setshowCustomFeeFields] = useState(false);
  const [agentChoice, setAgentChoice] = useState(null);

  const [concessionFields, setConcessionFields] = useState(false);
  const [additionalFields, setAdditionalFields] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);

  const [choiceData, setChoiceData] = useState(null);

  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBranchId, setSelectedBranchId] = useState(null);

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedYearId, setSelectedYearId] = useState(null);

  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedSessionId, setSelectedSessionId] = useState(null);

  useEffect(() => {
    // For getting branches, batches & years
    ServerService.GetBranchSelectionData()
      .then((response) => {
        setChoiceData(response.data);
        // feeSpans=response.data!=undefined?response.data[0].fee_spans:null;
        // setConcessionFields(response.data!=undefined?response.data[0].fee_spans:null);
        
        // console.log(response.data.fee_spans);
        // console.log(response.data);

        // For getting additional form-fields
        ServerService.IsFormCreated()
          .then((res) => {
            if (res.data.isCreated === false) {
              openNotification(
                "Form does not exist!",
                "Please first create a form for your college.",
                <ExclamationCircleOutlined style={{ color: "red" }} />,
                4
              );
              history.push(`/form`);
            } else {
              setAdditionalFields(res.data.fields);
              ServerService.GetStudentDetails(studentId)
                .then((res) => {
                  // console.log(res.data);
                  // Handling image
                  if (res.data.image !== null) {
                    setSelectedImg(res.data.image);
                    setImageUrl(res.data.image);
                  }
                  // Handling agents
                  if (res.data.agent_detail !== null) {
                    setShowAgentFields(true);
                    form.setFieldsValue({
                      agent_detail: res.data.agent_detail,
                      commission: res.data.commission,
                    });
                  }
                  setshowCustomFeeFields(res.data.custom_fee?!showCustomFeeFields:showCustomFeeFields);
                  form.setFieldsValue({
                    first_name: res.data.first_name,
                    last_name: res.data.last_name,
                    father_name: res.data.father_name,
                    mother_name: res.data.mother_name,
                    gender: res.data.gender,
                    category: res.data.category,
                    date_of_birth: moment(res.data.date_of_birth),
                    custom_fee:res.data.custom_fee,
                    phone_number: res.data.phone_number,
                  });
                  

                  // console.log("choicedata -> ", response.data);
                  // For branch-session-year
                  response.data &&
                    response.data.map((branch) => {
                      if (
                        branch.label ===
                        `${res.data.course} | ${res.data.branch}`
                      ) {
                        form.setFieldsValue({
                          branch: `${res.data.course} | ${res.data.branch}`,
                        });
                        setSelectedBranch(branch);
                        setSelectedBranchId(branch.id);
                        setConcessionFields(branch.fee_spans);

                        branch.batches.map((batch) => {
                          if (batch.label === res.data.batch) {
                            setSelectedSession(batch);
                            setSelectedSessionId(batch.id);
                            form.setFieldsValue({
                              batch: res.data.batch,
                            });
                          }
                        });

                        branch.years.map((year) => {
                          if (year.label === res.data.passing_span) {
                            setSelectedYear(year);
                            setSelectedYearId(year.id);
                            form.setFieldsValue({
                              passing_span: res.data.passing_span,
                            });
                          }
                        });
                      }
                    });

                  // For additional details
                  for (let property in res.data.details) {
                    form.setFieldsValue({
                      [String(property)]: res.data.details[property],
                    });
                  }

                  for (let property in res.data.custom_fee_detail) {
                    form.setFieldsValue({
                      [property]: res.data.custom_fee_detail[property],
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    // For getting agent details
    ServerService.GetAgentsDropdown()
      .then((res) => {
        // console.log(res);
        setAgentChoice(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const openNotification = (title, description, icon, duration) => {
    notification.open({
      message: title,
      description: description,
      icon: icon,
      duration: duration,
    });
  };

  const handleUpload = (event) => {
    if (event.target.value.length > 0) {
      setImageUrl(URL.createObjectURL(event.target.files[0]));
      setSelectedImg(event.target.files[0]);
    }
  };

  // Branch-change-handler
  const handleChangeBranch = (value) => {
    choiceData.map((branch) => {
      if (branch.label === value) {
        setSelectedBranch(branch);
        setSelectedBranchId(branch.id);

        setSelectedSession(branch.batches[0]);
        setSelectedSessionId(branch.batches[0].id);
        form.setFieldsValue({
          batch: branch.batches[0].label,
        });

        setSelectedYear(branch.years[0]);
        setSelectedYearId(branch.years[0].id);
        form.setFieldsValue({
          passing_span: branch.years[0].label,
        });
        setConcessionFields(branch.batches[0].default_fee);
      }
    });
  };
  // Session-change-handler
  const handleChangeSession = (value) => {
    selectedBranch.batches.map((batch) => {
      if (batch.label === value) {
        setSelectedSession(batch);
        setSelectedSessionId(batch.id);
        setConcessionFields(batch.default_fee);
        batch.default_fee.map((fee) => {
          console.log(fee.id,fee.total_amount);
          form.setFieldsValue({
                [fee.id]: fee.total_amount,
              });
        })
      }
    });
  };
  // Year-change-handler
  const handleChangeYear = (value) => {
    selectedBranch.years.map((year) => {
      if (year.label === value) {
        setSelectedYear(year);
        setSelectedYearId(year.id);
      }
    });
  };
  
  const submitHandler = (values) => {
    let fetchedFields = [];
    for (let property in additionalFields) {
      fetchedFields.push({
        field_name: property,
        field_type: additionalFields[property].type,
      });
    }
    let customFeeDetails = {};
    if(showCustomFeeFields){
      // console.log("custom fee fields",concessionFields);
      for(let property in concessionFields){
        let field = concessionFields[property]['id'];
        // console.log(concessionFields[property]['id'])
        customFeeDetails[field] = values[field];
      }   
    } 
    
    // console.log(values);
    let fd = new FormData();
    for (let property in values) {
      if (property == "commission") {
        // console.log("ALERT");
        if (Number.isNaN(values[property])) {
          fd.append(property, 0);
        } else {
          fd.append(property, values[property]);
        }
      } else {
        fd.append(property, values[property]);
      }
    }

    fd.set("branch", selectedBranchId);
    fd.set("batch", selectedSessionId);
    fd.set("passing_span", selectedYearId);
    if (selectedImg !== null && typeof selectedImg !== "string") {
      fd.set("image", selectedImg);
    }
    fd.set("date_of_birth", moment(values.date_of_birth).format("YYYY-MM-DD"));
    // fd.append("commission", Number(values.commission));

    let additionalData = {};
    fetchedFields.forEach((field, index) => {
      if (
        (field.field_type === "number" ||
          field.field_type === "integer" ||
          field.field_type === "float") &&
        values[field.field_name]
      ) {
        additionalData[field.field_name] = Number(values[field.field_name]);
      } else if (field.field_type === "date" && values[field.field_name]) {
        additionalData[field.field_name] = moment(
          values[field.field_name]
        ).format("YYYY-MM-DD");
      } else {
        additionalData[field.field_name] = values[field.field_name];
      }
      // else if (field.field_type === "boolean") {
      //   if (values[field.field_name] === undefined) {
      //     additionalData[field.field_name] = true;
      //   } else {
      //     additionalData[field.field_name] = false;
      //   }
      // }
    });
    // reqBody.details = additionalData;
    fd.append("details", JSON.stringify(additionalData));
    fd.append("custom_fee", showCustomFeeFields?true:false);
    fd.append("custom_fee_detail", JSON.stringify(customFeeDetails));
    // for (var pair of fd.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    let key = "updatable";
    message.loading({ content: "Action in progress...", key });

    // Add-student
    if (studentId === undefined) {
      ServerService.AddIndividualStudent(fd)
        .then((res) => {
          // console.log(res);
          // If status = 200
          message.success({ content: "Action completed!", key, duration: 2 });
          openNotification(
            "Data uploaded successfully!",
            "The data was uploaded successfully and the student will be added to the database!",
            <SmileOutlined style={{ color: "#108ee9" }} />,
            4
          );
          form.resetFields();
          history.push(`/studentDetails/${res.data.id}`);
        })
        .catch((err) => {
          console.log(err);
          // Something went wrong
          message.error({ content: "Something went wrong!", key, duration: 2 });
          openNotification(
            "Something went wrong!",
            "We are unable to process your request right now! Please try again after sometime.",
            <ExclamationCircleOutlined style={{ color: "red" }} />,
            4
          );
        });
    } else {
      // Edit-student
      fd.append("id", studentId);
      ServerService.EditIndividualStudent(fd, studentId)
        .then((res) => {
          // console.log(res);
          // If status = 200
          message.success({ content: "Action completed!", key, duration: 2 });
          openNotification(
            "Data uploaded successfully!",
            "The data was uploaded successfully and the student will be added to the database!",
            <SmileOutlined style={{ color: "#108ee9" }} />,
            4
          );
          form.resetFields();
          history.push(`/studentDetails/${res.data.id}`);
        })
        .catch((err) => {
          console.log(err);
          // Something went wrong
          message.error({ content: "Something went wrong!", key, duration: 2 });
          openNotification(
            "Something went wrong!",
            "We are unable to process your request right now! Please try again after sometime.",
            <ExclamationCircleOutlined style={{ color: "red" }} />,
            4
          );
        });
    }
  };

  const createAdditionalFields = () => {
    if (!additionalFields) {
      return null;
    }
    // console.log(additionalFields);
    let fields = [];

    for (let property in additionalFields) {
      let fieldType = additionalFields[property].type;
      if (fieldType === "string") {
        fields.push(
          <Form.Item
            name={property}
            rules={[
              {
                required: additionalFields[property].required,
                message: "Required field!",
              },
            ]}
          >
            <Input
              prefix={<FormOutlined className="site-form-item-icon" />}
              placeholder={property}
              autoComplete="off"
            />
          </Form.Item>
        );
      } else if (
        fieldType === "integer" ||
        fieldType === "number" ||
        fieldType === "float"
      ) {
        fields.push(
          <Form.Item
            name={property}
            rules={[
              {
                required: additionalFields[property].required,
                message: "Required field!",
              },
            ]}
          >
            <Input
              prefix={<FormOutlined className="site-form-item-icon" />}
              placeholder={property}
              autoComplete="off"
              type="number"
            />
          </Form.Item>
        );
      } else if (fieldType === "date") {
        fields.push(
          <Form.Item
            name={property}
            rules={[
              {
                required: additionalFields[property].required,
                message: "Required field!",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder={property}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        );
      } else if (fieldType === "boolean") {
        fields.push(
          // <Form.Item name={property} valuePropName="checked" initialValue={true}>
          //   <Switch initialValue={true} defaultChecked={true} style={{ marginRight: "12px" }} />
          //   {property}
          // </Form.Item>
          <Form.Item
            name={property}
            valuePropName="checked"
            initialValue={true}
          >
            <Checkbox>{property}</Checkbox>
          </Form.Item>
        );
      }
    }

    return fields;
  };

  const createCustomFeeFields = () => {
    if (!concessionFields) {
      return null;
    }
    // console.log(additionalFields);
    let fields = [];

    concessionFields.forEach((field, index) => {
      fields.push(
        <>
        <div>
        <p>{field.label} Default Fee: {field.total_amount}</p>
        
        </div>
        <Form.Item
          id={index}
          name={field.id}
          rules={[
            {
              required: true,
              message: "Please enter fee amount!",
            },
          ]}
          initialValue={field.total_amount}
          >
          <Input
            prefix={"₹"}
            placeholder={field.label}
            autoComplete="off"
            type="number"
            />
        </Form.Item>
        </>
      );
    }, []);

    return fields;
  };

  if (choiceData === null) {
    return <h6>Loading...</h6>;
  }

  return (
    <div className="displayArea">
      <div className="addStudentForm">
        <h6 className="formHeader">
          {studentId ? `Edit Student` : `Add Student`}
        </h6>

        <Form
          form={form}
          name="add_student"
          initialValues={{ remember: true }}
          onFinish={submitHandler}
        >
          {/* STUDENT'S IMAGE */}
          <input
            type="file" 
            id="studentImg"
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleUpload}
          />
          <label htmlFor="studentImg">
            {imageUrl === null ? (
              <div className="uploadButton">
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            ) : (
              <img className="uploadButton" src={imageUrl} />
            )}
          </label>

          {/* FIRST NAME */}
          <Form.Item
            name="first_name"
            rules={[
              {
                required: true,
                message: "Please enter student's first-name!",
              },
            ]}
          >
            <Input
              style={
                {
                  // backgroundColor: "#EEF0F4",
                  // borderRadius: "8px",
                }
              }
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="First Name"
              autoComplete="off"
            />
          </Form.Item>

          {/* LAST NAME */}
          <Form.Item
            name="last_name"
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Last Name"
              autoComplete="off"
              initialValue=""
            />
          </Form.Item>

          {/* FATHER'S NAME */}
          <Form.Item
            name="father_name"
            rules={[
              {
                required: true,
                message: "Please enter father's name!",
              },
            ]}
          >
            <Input
              prefix={<FormOutlined className="site-form-item-icon" />}
              placeholder="Father's Name"
              autoComplete="off"
            />
          </Form.Item>

          {/* MOTHER'S NAME */}
          <Form.Item
            name="mother_name"
            rules={[
              {
                required: true,
                message: "Please enter mother's name!",
              },
            ]}
          >
            <Input
              prefix={<FormOutlined className="site-form-item-icon" />}
              placeholder="Mother's Name"
              autoComplete="off"
            />
          </Form.Item>

          {/* DATE OF BIRTH */}
          <Form.Item
            name="date_of_birth"
            rules={[
              {
                required: true,
                message: "Please enter student's date of birth!",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Date of Birth"
              format="DD-MM-YYYY"
            />
          </Form.Item>

          {/* GENDER SELECTION */}
          <Form.Item
            name="gender"
            rules={[
              {
                required: true,
                message: "Please select student's gender!",
              },
            ]}
          >
            <Select placeholder="Select gender" allowClear>
              <Option value="M">Male</Option>
              <Option value="F">Female</Option>
            </Select>
          </Form.Item>

          {/* CATEGORY SELECTION */}
          <Form.Item
            name="category"
            rules={[
              {
                required: true,
                message: "Please select student's category!",
              },
            ]}
          >
            <Select placeholder="Select category" allowClear>
              <Option value="General">General</Option>
              <Option value="OBC">OBC</Option>
              <Option value="SC">SC</Option>
              <Option value="ST">ST</Option>
              <Option value="O">Other</Option>
            </Select>
          </Form.Item>

          {/* STUDENT'S BRANCH */}
          <Form.Item
            name="branch"
            rules={[
              {
                required: true,
                message: "Please enter student's branch!",
              },
            ]}
          >
            <Select
              placeholder="Select a branch"
              onChange={handleChangeBranch}
              allowClear
              value={selectedBranch ? selectedBranch.label : null}
            >
              {choiceData
                ? choiceData.map((branch, index) => {
                    return (
                      <Option key={index} value={branch.label}>
                        {branch.label}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>

          {/* STUDENT'S SESSION */}
          <Form.Item
            name="batch"
            rules={[
              {
                required: true,
                message: "Please enter student's session!",
              },
            ]}
          >
            <Select
              placeholder="Select a session"
              disabled={selectedBranch === null}
              onChange={handleChangeSession}
              allowClear
              value={selectedSession ? selectedSession.label : null}
            >
              {selectedBranch !== null
                ? selectedBranch.batches.map((batch) => {
                    return <Option value={batch.label}>{batch.label}</Option>;
                  })
                : null}
            </Select>
          </Form.Item>

          {/* STUDENT'S YEAR */}
          <Form.Item
            name="passing_span"
            rules={[
              {
                required: true,
                message: "Please enter student's year!",
              },
            ]}
          >
            <Select
              placeholder="Select a academic year"
              disabled={selectedBranch === null}
              onChange={handleChangeYear}
              allowClear
              value={selectedYear ? selectedYear.label : null}
            >
              {selectedBranch !== null
                ? selectedBranch.years.map((year) => {
                    return <Option value={year.label}>{year.label}</Option>;
                  })
                : null}
            </Select>
          </Form.Item>

          {/* PHONE NUMBER */}
          <Form.Item
            name="phone_number"
            rules={[
              {
                required: true,
                message: "Please enter valid phone number!",
                pattern: new RegExp(/^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[6789]\d{9}|(\d[ -]?){10}\d$/)
              },
            ]}
          >
            <Input
              prefix={<FormOutlined className="site-form-item-icon" />}
              placeholder="Phone Number"
              autoComplete="off"
            />
          </Form.Item>

          {/* ADDITIONAL FIELDS */}
          {createAdditionalFields()}

          {/* CUSTOM FEE */}
          <Form.Item
            name="custom_fee"
            valuePropName="checked"
            // initialValue={false}
          >
            <Checkbox
              onChange={() => setshowCustomFeeFields(!showCustomFeeFields)}
              style={{ width: "100%" }}
              // defaultChecked={showAgentFields}
              checked={showCustomFeeFields}
            >
              <h6 className="agentCheckbox">Custom Fee</h6>
            </Checkbox>
          </Form.Item>

          {/* {createCustomFeeFields()} */}
          {showCustomFeeFields ? createCustomFeeFields(): null}


          {/* Agent-checkbox */}
          <Form.Item>
            <Checkbox
              onChange={() => setShowAgentFields(!showAgentFields)}
              style={{ width: "100%" }}
              // defaultChecked={showAgentFields}
              checked={showAgentFields}
            >
              <h6 className="agentCheckbox">Admitted by an agent?</h6>
            </Checkbox>
          </Form.Item>

          {showAgentFields ? (
            <>
              <hr
                style={{
                  marginBottom: "24px",
                  width: "80%",
                  height: "1px",
                  backgroundColor: "#ccc",
                  border: "none",
                }}
              />

              {/* AGENT SELECTION */}
              <Form.Item name="agent_detail">
                <Select placeholder="Select agent" allowClear>
                  {agentChoice &&
                    agentChoice.map((agent, index) => {
                      return (
                        <Option key={index} value={agent.id}>
                          {agent.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              {/* AGENT COMMISSION */}
              {/* <Form.Item name="commission">
                <Input
                  prefix={<PoundOutlined className="site-form-item-icon" />}
                  placeholder="Agent Commission"
                  autoComplete="off"
                  type="number"
                />
              </Form.Item> */}
            </>
          ) : null}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="add-student-form-button"
            >
              {studentId ? `Save Changes` : `Add Student`}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default BasicForm;
