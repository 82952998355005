import React from "react";
import styles from "./BatchData.module.css";
import { Checkbox, Button } from "antd";
import { useHistory } from "react-router-dom";
import { EditFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import ServerService from "../../../API/ServerService";
// import { StylesContext } from "@material-ui/styles";

const BatchData = (props) => {
  const history = useHistory();
  const UserType = useSelector((state) => {
    if (state.auth.user) {
      return state.auth.user.role
    }
  });

  console.log(props);
  const handlerRedirection = (feeId) => {
    // let batch = null;

    // if (fee) {
    //   batch = fee.batch_name;
    //   fee = fee.total_amount;
    // }
    // history.push({
    //   pathname: "/add-fee/new",
    //   state: { year: year, fee: fee, batch: batch },
    // });
    history.push(`/edit-fee/${feeId}`);
  };

  const DeleteBatch = () => {
    let confirm = window.confirm("Are you sure that you want to delete Batch?")
    if (confirm) {
      ServerService.DeleteBatch(props.batchData.id)
        .then((res) => {
          // console.log(res);
          history.push(`/branches/${props.batchData.courseId}`);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }


  return (
    <div className={styles.batch}>
      <h6 className={styles.yearsHeading}>Available {props.batchData.span_label} Fee Details</h6>
      <Button
        onClick={DeleteBatch}
        className={styles.deleteBatch}
        type="danger"
        style={{ color: "white" }}
      >
        Delete Batch
      </Button>
      <div>
        {props.batchData.years.map((year, index) => {
          if(year.fee)
          return (
            <div className={styles.yearCard} key={index} >
              <div style={{ display: "flex", justifyContent: "space-between" }} >
                <h4>{year.name} </h4>
                {
                  UserType === "admin" ? <EditFilled onClick={() => handlerRedirection(year.fee.id)} className={styles.editbtn} />
                    : null}
              </div>
              <h5> Fee Breakdown </h5>

              <div className={styles.feeDetails}>
                <div className={styles.header}>
                  <h6>Fee Type</h6>
                  <h6>Fee Amount</h6>
                  <h6>Note (Optional)</h6>
                  <h6>Include in base-fee</h6>
                </div>
                <div className={styles.statusBody}>
                  {year &&
                    year.fee.fee_detail.map((feeData, index) => {
                      return (
                        <div className={styles.feeRow} key={index} >
                          <div className={styles.feeType}>{feeData.name}</div>
                          <div className={styles.feeAmount}>{feeData.amount}</div>
                          <div className={styles.feeNote}>
                            <span className={styles.feeNoteNote}>
                              {feeData.comment}
                            </span>
                          </div>
                          <div className={styles.check}>
                            <Checkbox
                              checked={feeData.included_in_base_fee}
                              style={{
                                transform: "translateY(-3px)",
                              }}

                            />
                          </div>
                        </div>
                      );
                    })}
                  <div className={styles.feeRow} style={{ fontWeight: "600" }} >
                    <div className={styles.feeType} > Total amount</div>
                    <div className={styles.feeAmount}>{year.fee.total_amount}</div>  </div>
                </div>
              </div>


            </div>




            // <div className={styles.year} key={index}>
            //   <h6 className={styles.yearLabel}>
            //     <span className={styles.label}>Academic Year :</span>
            //     <span className={styles.value}>{year.name}</span>
            //   </h6>
            //   <h6 className={styles.feeLabel}>
            //     <span className={styles.label}>Fees :</span>
            //     <span className={styles.value}>
            //       {year.fee ? year.fee.total_amount : "_"}
            //     </span>
            //   </h6>
            //   <Button
            //     onClick={() => handlerRedirection(year.fee.id)}
            //     block
            //     style={{
            //       marginTop: "16px",
            //       background: "#09DCA8",
            //       color: "white",
            //     }}
            //   >
            //     {year.fee ? "Edit" : "Add"}{" "}
            //   </Button>
            // </div>
          );
        })}
      </div>
    </div>
  );
};

export default BatchData;
