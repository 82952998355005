const data = [
  {
    name: 'Course',
    selector: 'course',
    sortable: true,
  },
  {
    name: "Session",
    selector: "session",
    sortable: true,
  },
  {
    name: "Promoted From",
    selector: "promotedFrom",
    sortable: true,
  },
  {
    name: "Promoted To",
    selector: "promotedTo",
    sortable: true,
  },
  {
    name: "On Date",
    selector: "date",
    sortable: true,
  },
];

export default data;