import React from "react";
import { Form, Input, Button } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import styles from "./AddFee.module.css";

const AddFee = (props) => {
  const addFee = (values) => {
    props.AddFee(values);
  };

  return (
    <Form
      name="normal_login"
      className={styles.addFeeForm}
      initialValues={{ remember: true }}
      onFinish={addFee}
    >
      <div className={styles.formRow}>
        <Form.Item
          name="feeType"
          rules={[
            {
              required: true,
              message: "required*",
            },
          ]}
          style={{
            maxWidth: "24%",
            minWidth: "24%",
            marginRight: "18px",
          }}
        >
          <Input
            style={{
              backgroundColor: "#f3f4f8",
              borderRadius: "6px",
              padding: "9px 14px",
              border: "none",
            }}
            placeholder="Fee Type..."
          />
        </Form.Item>

        <Form.Item
          name="feeAmount"
          rules={[
            {
              required: true,
              message: "required*",
            },
            // {
            //   pattern: /^[0-9]\d{0,13}$/,
            //   message: "Only digits allowed",
            // },
          ]}
          style={{
            maxWidth: "26%",
            minWidth: "26%",
            marginRight: "18px",
          }}
        >
          <Input
            style={{
              backgroundColor: "#f3f4f8",
              borderRadius: "6px",
              padding: "9px 14px",
              border: "none",
            }}
            // prefix={<PhoneOutlined />}
            placeholder="Fee Amount..."
            autoComplete="off"
            type="number"
          />
        </Form.Item>

        <Form.Item
          name="feeNote"
          rules={[
            {
              // required: true,
              message: "required*",
            },
          ]}
          style={{
            flexGrow: "1",
            // marginRight: "18px",
          }}
        >
          <Input
            style={{
              backgroundColor: "#f3f4f8",
              borderRadius: "6px",
              padding: "9px 14px",
              border: "none",
            }}
            placeholder="Payment Method..."
          />
        </Form.Item>
        {/* <DeleteTwoTone
          style={{
            cursor: "pointer",
            transform: "scale(1.15) translateY(14px)",
          }}
          twoToneColor="red"
        /> */}
      </div>

      <Form.Item style={{ textAlign: "center" }}>
        <Button type="primary" htmlType="submit" className={styles.saveButton}>
          Save Details
        </Button>

        {/* <div htmlType="submit" className={styles.saveFee}>
          Save Fee-details
        </div> */}
      </Form.Item>
    </Form>
  );
};

export default AddFee;
