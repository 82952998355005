import React, { useState, useEffect } from "react";
import {Link,useHistory} from "react-router-dom";
import { Table,  Breadcrumb, Button } from "antd";
// import { DownOutlined, DownloadOutlined } from "@ant-design/icons";
import styles from './table.module.css';
import columns from './TableData/Table';
import ServerService from '../../API/ServerService'

const ViewAgent = ()=>{

    const [pageNo,setPageNo]=useState(1)
    const [pageSize]=useState(10)
    const [data,setData]=useState([{}]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);

    const handlePageChange = (currentPage) => {
        setPageNo(currentPage);
    };
    const history=useHistory()
    

     useEffect(()=>{
         ServerService.GetAgentsList(pageNo,pageSize)
         .then(res=>{
            //  console.log(res)
             let totPages = parseInt(res.data.count / pageSize);
             if (res.data.count % pageSize !== 0) {
               totPages += 1;
             }
             setTotalPages(totPages);
             setTotalItems(res.data.count);
             
             setData(res.data.results)
         })
         .catch(err=>{
             console.log(err)
         })
      },[pageNo])

       let url = window.location.href;
        url = url.split('/');
        url.splice(2,1,"Dashboard")


    return (
        <div className={styles.ViewAgent}>
            <h1>Agent Details</h1>
            <div style={{display:'flex',justifyContent:'space-between', marginBottom:"24px",marginRight:'4px', alignItems:"center"}}>
                <Breadcrumb >
                    {url.slice(2).map((entry,index)=> {
                    return<Breadcrumb.Item key={index}>
                            <Link style={{textDecoration:'none'}} to={`${entry}`}>
                                {entry}
                            </Link>
                            </Breadcrumb.Item>}
                    )}
                </Breadcrumb>
                <Button type="primary" onClick={()=>history.push('viewAgent/AddAgent')}>Add Agent</Button>
            </div>
           
            <div className={styles.Table}>
                <Table
                    currentPage={pageNo}
                    columns={columns}
                    dataSource={data}
                    bordered
                    showFooter={false} 
                    // totalNum={totalItems}
                    // displayNum={pageSize}
                    // totalPages={3}
                    pagination={{current:pageNo,pageSize:pageSize,total:totalItems,onChange:handlePageChange }}

                    />
            </div>
        </div>
    )

}


export default ViewAgent;
