import React from "react";
import styles from "./FacultyCard.module.css";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const FacultyCard = (props) => {
  const UserType = useSelector((state) => state.auth.user.role);
  const colors = ["#66CDAA", "#CD8282", "#82A8CD", "#999999"];

  return (
    <div
      style={{ borderColor: `${colors[props.index % 4]}` }}
      className={styles.facultyCard}
    >
      {props.data.photo ? (
        <Avatar size={64} className={styles.img} src={props.data.photo} />
      ) : (
        <Avatar size={64} className={styles.img} icon={<UserOutlined />} />
      )}

      <div className={styles.cardContent}>
        <h6 className={styles.courseName}>{props.data.name}</h6>
        <h5>
          Designation:{" "}
          <span className={styles.courseData}> {props.data.position} </span>
        </h5>
        <h5>
          Contact:{" "}
          <span className={styles.courseData}> {props.data.contact_no}</span>
        </h5>
        <h5>
          Experience:{" "}
          <span className={styles.courseData}> {props.data.experience}</span>
        </h5>
        <h5>
          Qualifications:{" "}
          <span className={styles.courseData}>
            {" "}
            {props.data.qualifications}
          </span>
        </h5>
        <h5>
          Publications:{" "}
          <span className={styles.courseData}> {props.data.publications}</span>
        </h5>
      </div>

      {UserType === "admin" ? (
        <Link
          to={{
            pathname: `/edit-faculty/${props.data.id}`,
            state: {
              name: props.data.name,
            },
          }}
        >
          <div className={styles.arrowIcon}>
            <i class="fas fa-chevron-right" />
          </div>
        </Link>
      ) : null}
    </div>
  );
};

export default FacultyCard;
