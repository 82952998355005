const initialState = {
  selectedStudents: [],
};

const PromoteStudents = (state = initialState, action) => {
  switch (action.type) {
    
    case "CHANGE_PROMOTION_LIST":
      let oldList = [...state.selectedStudents];
      let newList = [...state.selectedStudents];
      let newStudent = action.payload;

      let foundStudent = false;
      oldList.map((oldStudent) => {
        if (oldStudent.id === newStudent.id) {
          foundStudent = true;
        }
      });
      if (!foundStudent) {
        newList.push(newStudent);
      }
      return {
        ...state,
        selectedStudents: newList,
      };

    case "CLEAR_PROMOTION_LIST":
      return {
        ...state,
        selectedStudents: [],
      };

    case "DELETE_STUDENT_FROM_PROMOTION_LIST":
        let newList2 = [];
        state.selectedStudents.map((student) => {
          if(student.id !== action.payload){
            newList2.push(student);
          }
        })
        return {
          ...state,
          selectedStudents: newList2,
        }

    default:
      return state;
  }
};

export default PromoteStudents;
