import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./SideBar.css";
import { Avatar } from "antd";
// import SideBarData from "./SideBarData";
import SidebarOption from "./SideBarOption/SideBarOption";
import ServerService from "../../API/ServerService";
import DashBoardIcon from "../../assets/DashboardSVG.svg";
import DashBoardGreenIcon from "../../assets/DashboardGreenSVG.svg";

import ListIcon from "../../assets/ListIcon.svg";
import TeacherIcon from "../../assets/TeacherIcon.svg";
import StudentIcon from "../../assets/StudentIcon.svg";
import UserIcon from "../../assets/UserLogo.svg";


const AdminSidebarOptions = [
  {
    icon: DashBoardIcon,
    selectedIcon: DashBoardGreenIcon,
    heading: "Dashboard",
    redirect: "dashboard",
  },
  {
    icon: StudentIcon,
    selectedIcon: DashBoardGreenIcon,
    heading: "Students",
    subHeadings: [
      { title: "All Students", redirect: "view-students" },
      { title: "Add Student", redirect: "add-student/single" },
      { title: "Bulk Add Student", redirect: "add-student/bulk" },
      { title: "Promote Students", redirect: "promote" },
    ],
  },
  {
    icon: TeacherIcon,
    selectedIcon: DashBoardGreenIcon,
    heading: "Fee Status",
    subHeadings: [
      // { title: "Edit Fee", redirect: "edit-fee/new" },
      { title: "Fee Report", redirect: "fee-status" },
      // { title: "Passout Students", redirect: "passout/fee-status" },
    ],
  },
  {
    icon: ListIcon,
    selectedIcon: DashBoardGreenIcon,
    heading: "Courses",
    subHeadings: [
      { title: "All Courses", redirect: "allCourses" },
      { title: "Add Course", redirect: "addCourse" },
      { title: "Add Batch", redirect: "add-batch" },
      { title: "Create Form", redirect: "form" },
    ],
  },
  {
    icon: ListIcon,
    selectedIcon: DashBoardGreenIcon,
    heading: "Branches",
    subHeadings: [
      { title: "All Branches", redirect: "branches/all" },
      { title: "Add Branch", redirect: "addBranch" },
    ],
  },
  {
    icon: ListIcon,
    selectedIcon: DashBoardGreenIcon,
    heading: "Report",
    subHeadings: [
      { title: "Passout Students", redirect: "students/passout" },
      { title: "Failed Students", redirect: "students/failed" },
      { title: "Inactive Students", redirect: "students/inactive" },
    ],
  },
  {
    icon: TeacherIcon,
    selectedIcon: DashBoardGreenIcon,
    heading: "Faculty",
    subHeadings: [
      { title: "View Faculty", redirect: "view-faculty" },
      { title: "Add Faculty", redirect: "add-faculty" },
    ],
  },
  {
    icon: UserIcon,
    selectedIcon: DashBoardGreenIcon,
    heading: "Account",
    subHeadings: [
      { title: "View account", redirect: "viewAccount" },
      { title: "View agents", redirect: "viewAgent" },
    ],
  },
];
const StaffSidebarOptions = [
  {
    icon: DashBoardIcon,
    selectedIcon: DashBoardGreenIcon,
    heading: "Dashboard",
    redirect: "dashboard",
  },
  {
    icon: StudentIcon,
    selectedIcon: DashBoardGreenIcon,
    heading: "Students",
    subHeadings: [
      { title: "All Students", redirect: "view-students" },
      { title: "Add Student", redirect: "add-student/single" },
      { title: "Bulk Add Student", redirect: "add-student/bulk" },
    ],
  },
  {
    icon: TeacherIcon,
    selectedIcon: DashBoardGreenIcon,
    heading: "Fee Status",
    subHeadings: [
      { title: "Fee Report", redirect: "fee-status" },
    ],
  },
  {
    icon: ListIcon,
    selectedIcon: ListIcon,
    heading: "Courses",
    // subHeadings: [
    //   // { title: "All Courses", redirect: "allCourses" },
    //   // { title: "Add Course", redirect: "addCourse" },
    //   // { title: "Add Batch", redirect: "add-batch" },
    // ],
    redirect: "allCourses",
  },
  {
    icon: ListIcon,
    selectedIcon: ListIcon,
    heading: "Branches",
    redirect: "branches/all",
  },
  {
    icon: ListIcon,
    selectedIcon: DashBoardGreenIcon,
    heading: "Report",
    subHeadings: [
      { title: "Passout Students", redirect: "students/passout" },
      { title: "Failed Students", redirect: "students/failed" },
      { title: "Inactive Students", redirect: "students/inactive" },
    ],
  },
  {
    icon: TeacherIcon,
    selectedIcon: DashBoardGreenIcon,
    heading: "Faculty",
    subHeadings: [
      { title: "View Faculty", redirect: "view-faculty" },
    ],
  },
  {
    icon: UserIcon,
    selectedIcon: DashBoardGreenIcon,
    heading: "Account",
    subHeadings: [
      { title: "View account", redirect: "viewAccount" },
    ],
  },
]

const SideBar = () => {
  const [college, setCollege] = useState({ collegeName: "_", collegeLogo: "" });
  const UserType = useSelector((state) => state.auth.user.role);

  useEffect(() => {
    let data={"url":window.location.href};
    ServerService.ConfigApi(data)
      .then((res) => {
        console.log(res);
        setCollege((prevState) => ({
          ...prevState,
          collegeName: res.data.institutionName,
          collegeLogo: res.data.logo,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const SideOptions = () => {
    let SideBarData = [];
    if(UserType === "admin"){
      SideBarData = AdminSidebarOptions;
    }
    else{
      SideBarData = StaffSidebarOptions;
    }
    return SideBarData.map((item, index) => {
      return <SidebarOption key={index} item={item} />;
    });
  };

  let nameArray = college.collegeName.split(" ");
  let nameInitial = "";
  let first_two_letter = "";

  for (var i = 0; i < nameArray.length; i++) {
    nameInitial += nameArray[i][0];
    if (i < 2) {
      first_two_letter += nameArray[i][0];
    }
  }

  return (
    <div className="SideBar">
      <div className="SidebarHeader">
        {college.collegeLogo ? (
          <img src={college.collegeLogo} alt="college logo" />
        ) : (
          <Avatar>{first_two_letter}</Avatar>
        )}
        <h6>{nameInitial}</h6>
      </div>

      {<SideOptions />}
    </div>
  );
};

export default SideBar;
