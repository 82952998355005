import React from 'react';
import styles from './footer.module.css'
// import ServerService from '../../API/ServerService';

const Footer = ()=>{


    const [collegeName]=React.useState("Moces")


    // React.useEffect(()=>{
    //     ServerService.ConfigApi(window.url)
    //     .then(res=>{
    //         console.log(res)
    //         setCollege(res.data.institutionName)
    //     })
    //     .catch(err=>{
    //         console.log(err)
    //     })
    // },[])

    return(<>
            <div className={styles.footer}>
                <span className={styles.footerText}>
                    © Copyright 2021 <span className={styles.companyName}>{collegeName}</span>.All rights reserved.
                </span>
            </div>
        </>)

}
export default Footer;