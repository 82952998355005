import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import styles from "./Promotion.module.css";
import selectionData from "./dummyData";
import AllColumns from "./RecentPromotions/TableColumns";
import dummyTableData from "./RecentPromotions/dummyTableData";
import { Form, Select, Button, message, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import LoadingScreen from "./LoadingScreen";
import Table from "../../../components/Table/Table";
import RecentIcon from "../../../assets/RecentIcon.svg";
import ServerService from "../../../API/ServerService";

const { Option } = Select;

const Promotion = () => {
  const history = useHistory();
  const [choiceData, setChoiceData] = useState(null);

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  const [columns, setColumns] = useState(AllColumns);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(dummyTableData);

    ServerService.GetCourses()
      .then((res) => {
        // console.log(res);
        setChoiceData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const openNotification = (title, desc, icon, duration) => {
    notification.open({
      message: title,
      description: desc,
      icon: icon,
      duration: duration,
    });
  };

  // Course-change-handler
  const handleChangeCourse = (value) => {
    choiceData.map((course) => {
      if (course.course_name === value) {
        setSelectedCourse(course);
        setSelectedCourseId(course.id);
      }
    });
  };

  const proceedPromotion = () => {

    if(selectedCourseId === null){
      openNotification(
        "Course not selected!",
        "Please select a course before proceeding for promotion!",
        <ExclamationCircleOutlined style={{ color: "red" }} />,
        4.5
      );
      return;
    }

    let key = "updatable";
    // message.loading({ content: "Action in progress...", key });
    // message.success({ content: "Action completed!", key, duration: 2 });
    history.push(`/promote/${selectedCourseId}`);
  
  };

  if (choiceData === null) {
    return <LoadingScreen />;
  }

  return (
    <div className={styles.displayArea}>
      <h6 className={styles.heading}>Promote students</h6>

      <div className={styles.promoteBlock}>
        
        <div className={styles.choiceArea}>
          {/* Course-handling */}
          <div className={styles.choice}>
            <h6 className={styles.dataLabel}>Select Course</h6>
            <Form.Item
              name="course"
              rules={[
                {
                  required: true,
                  message: "Select course!",
                },
              ]}
            >
              <Select
                onChange={handleChangeCourse}
                value={selectedCourse ? selectedCourse.course_name : null}
              >
                {choiceData
                  ? choiceData.map((course, index) => {
                      return (
                        <Option key={index} value={course.course_name}>
                          {course.course_name}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>
          </div>
        </div>

        <div className={styles.footer}>
          <Button
            onClick={() => history.push("/")}
            className={styles.cancelButton}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={proceedPromotion}
            className={styles.addCourseButton}
          >
            Proceed
          </Button>
        </div>

      </div>

      <div className={styles.recentPromotionsTableBody}>
        <div className={styles.tableHeader}>
          <img src={RecentIcon} alt="recent-icon" />
          <h6>Recent Promotions</h6>
        </div>
        <Table
          columns={columns}
          data={tableData}
          noPagination={true}
          selectRow={false}
        />
      </div>
    </div>
  );
};

export default Promotion;
