import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";
import "./App.css";
import { useSelector, useDispatch } from "react-redux";
import { SetUser } from "./redux/actions/actions";

//is-auth verify 
// import AuthVerify from './auth-verify';

// Global-components
import LoggedOutNavbar from "./components/NavBar/LoggedOutNavbar/NavBar";
import UserNavbar from "./components/NavBar/UserNavbar/NavBar";
import SideBar from "./components/SideBar/SideBar";
import "antd/dist/antd.css";

// Initial-auth-screens
import SignUp from "./routes/SignUp/SignUp";
import Login from "./routes/Login/Login";
// import CollegeDetails from "./routes/CollegeDetails/CollegeDetails";

// Dashboard-related
import Dashboard from "./routes/Dashboard/Dashboard";

// Courses
import AllCourses from "./routes/Courses/AllCourses/AllCourses";
import AddCourse from "./routes/Courses/AddCourse/AddCourse";
// import FormCreationCoursesList from "./routes/Courses/CreateForm"
import FormCreation from "./routes/Courses/CreateForm/FormCreation/FormCreation";
// Branches
import ViewBranches from "./routes/Branches/ViewBranches/ViewBranches";
import AddBranch from "./routes/Branches/AddBranch/AddBranch";
// Batches
import ViewBatches from "./routes/Batches/ViewBatches/ViewBatches";
import AddBatch from "./routes/Courses/AddBatch/AddBatch";

// Fee-Status
import AllFeeStatus from "./routes/FeeStatus/AllFeeStatus"; 
import AddFee from "./routes/AddFee/EditFee";

// Account-related
import ViewAccount from "./routes/Account/ViewAccount";
import EditAccount from "./routes/Account/EditAccount";
import AddUser from "./routes/AddUser/AddUser";
import UserProfile from './routes/UserProfile/UserProfile'
import EditUserProfile from './routes/UserProfile/EditUserProfile'

// View-Students
import CoursesList from "./routes/Students/ViewStudents/Course-Branch-redirection/CoursesList";
import BranchesList from "./routes/Students/ViewStudents/Course-Branch-redirection/BranchesList";
import StudentsList from "./routes/Students/ViewStudents/StudentsList/StudentsList";
import ViewOtherStudents from "./routes/ViewOtherStudents/ViewOtherStudents";

// Specific-student-related
import StudentDetail from "./routes/Students/StudentDetails/index.js";
import FeeStatus from "./routes/Students/FeeStatus/FeeStatus";
import PrintViewReceipt from "./routes/Students/FeeStatus/ViewReceipt/PrintReceipt";
import PrintViewReport from "./routes/Students/FeeStatus/ViewReceipt/PrintReport";

// Adding student(s) to course-branches
import AddStudent from "./routes/Students/AddStudent/index";
import IndividualStudentForm from "./routes/Students/AddStudent/BasicForm/BasicForm";
import BulkAddStudent from "./routes/Students/AddStudent/BulkAddStudent/BulkAddStudent";

// Promotion-related
import Promotion from "./routes/Students/Promotion/Promotion";
import StudentSelection from "./routes/Students/Promotion/StudentSelection/StudentSelection";

// Search-related
import SearchResults from "./routes/SearchResults/SearchResults";
import AddFaculty from "./routes/Faculty/AddFaculty/AddFaculty";
import ViewFaculty from "./routes/Faculty/ViewFaculty/ViewFaculty";
import EditFaculty from "./routes/Faculty/EditFaculty/EditFaculty";

// Agents-related
import AddAgent from './routes/Agent/AddAgent'
import ViewAgent from './routes/Agent/ViewAgent'
import AgentProfile from './routes/Agent/AgentProfile';
import EditAgentProfile from './routes/Agent/EditAgentProfile';

// footer
import Footer from './components/Footer/footer';

// Unused-routes
// import StudentReceipts from "./routes/Students/StudentDetails/StudentReceipts/StudentReceipts";


const App = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      dispatch(SetUser(JSON.parse(localStorage.getItem("user"))));
    } 
    else {
      history.push("/UserLogin");
      // window.location.href = '/UserLogin';
    }
  }, []);

  const AdminGuardRoute = ({ component: Component, ...props }) => (
    <Route
      {...props}
      render={(routeProps) => {
        let UserType = localStorage.getItem("role");

        // check to see if the user is authenticated or not
        return UserType === "admin" ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to="/UserLogin" />
          // window.location.href = '/UserLogin'
        );
      }}
    />
  );

  const GuardRoute = ({ component: Component, ...props }) => (
    <Route
      {...props}
      render={(routeProps) => {
        let UserType = localStorage.getItem("role");

        // check to see if the user is authenticated or not
        return UserType !== null ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to="/UserLogin" />
        );
      }}
    />
  );

  return (
    <div className="App">
      
      <BrowserRouter>
        {user && <SideBar />}
        <div
          style={{
            marginLeft: user ? "220px" : "0px",
          }}
        >
          {!user && <LoggedOutNavbar/>}
          {user && <UserNavbar />}
          {/* <AuthVerify logOut={"a"}/> */}
          <Switch>

            {/* AUTH ROUTES */}
            
            <Route path="/UserLogin" exact component={Login} />
            {/* <Route path="/UserSignup" exact component={SignUp} /> */}
            {/* <Route path="/CollegeDetails" exact component={CollegeDetails} /> */}

            {/* ADMIN */}
            <GuardRoute path="/dashboard" exact component={Dashboard} />
            <AdminGuardRoute path="/promote" exact component={Promotion} />
            <AdminGuardRoute path="/promote/:courseId" exact component={StudentSelection} />
            <GuardRoute path="/add-user" exact component={AddUser} />

            {/* ADMIN-or-USER */}
            <GuardRoute path="/viewAccount" exact component={ViewAccount} />
            <GuardRoute path="/viewAccount/profile/:ProfileId" exact component={UserProfile} />
            <GuardRoute path="/viewAccount/profile/edit/:editId" exact component={EditUserProfile} />
            <GuardRoute path="/viewAccount/edit" exact component={EditAccount} />
            <GuardRoute path="/add-student" exact component={AddStudent} />
            <GuardRoute path="/add-student/single" exact component={IndividualStudentForm} />
            <GuardRoute path="/add-student/bulk" exact component={BulkAddStudent} />
            <GuardRoute path="/edit-student/:studentId" exact component={IndividualStudentForm} />
            
            {/* AGENT-RELATED */}
            <AdminGuardRoute path="/viewAgent/AddAgent" exact component={AddAgent} />
            <AdminGuardRoute path="/viewAgent" exact component={ViewAgent} />
            <AdminGuardRoute path="/viewAgent/profile/:ProfileId" exact component={AgentProfile} />
            <AdminGuardRoute path="/viewAgent/profile/edit/:editId" exact component={EditAgentProfile} />


            {/* COURSES */}
            <GuardRoute path="/allCourses" exact component={AllCourses} />
            <AdminGuardRoute path="/addCourse" exact component={AddCourse} />
            <AdminGuardRoute path="/form" exact component={FormCreation} />
            {/* <GuardRoute path="/form" exact component={FormCreationCoursesList} /> */}
            {/* <GuardRoute path="/form/create-form/:courseId" exact component={FormCreation} /> */}
            {/* <GuardRoute path="/form/edit-form/:courseId" exact component={FormCreation} /> */}

            {/* BRANCHES */}
            <GuardRoute path="/branches/:courseId" exact component={ViewBranches} />
            <AdminGuardRoute path="/addBranch" exact component={AddBranch} />

            {/* BATCHES */}
            <GuardRoute path="/batches/:branchId" exact component={ViewBatches}/>
            <AdminGuardRoute path="/add-batch" exact component={AddBatch} />
            <AdminGuardRoute path="/add-batch/:branchId" exact component={AddBatch} />

            {/* STUDENTS */}
            <GuardRoute path="/view-students" exact component={CoursesList}/>
            <GuardRoute path="/view-students/course/:courseId" exact component={BranchesList}/>
            
            <GuardRoute path="/view-students/all" exact component={StudentsList}/>
            <GuardRoute path="/view-students/:courseId" exact component={StudentsList}/>
            <GuardRoute path="/view-students/:courseId/:branchId" exact component={StudentsList} />
            <GuardRoute path="/view-students/:courseId/:branchId/:year" exact component={StudentsList} />
            <GuardRoute path="/students/:studentsType" exact component={ViewOtherStudents} />

            <GuardRoute path="/studentDetails/:studentId" exact component={StudentDetail} />
            <GuardRoute path="/studentDetails/:studentId/fee-status" exact component={FeeStatus} />
            <GuardRoute path="/studentDetails/:studentId/edit-receipt/:receiptId" exact component={FeeStatus} />
            <GuardRoute path="/studentDetails/:studentId/view-receipt/:receiptId" exact component={PrintViewReceipt} />
            <GuardRoute path="/studentDetails/:studentId/view-report/:span_id" exact component={PrintViewReport} />
            {/* <GuardRoute path="/view-receipts/:studentId" exact component={StudentReceipts} /> */}

            
            {/* REGARDING FEES */}
            <GuardRoute path="/fee-status" exact component={AllFeeStatus} />
            <AdminGuardRoute path="/edit-fee/:feeId" exact component={AddFee} />
            {/* <GuardRoute path="/edit-fee/new" exact component={AddFee} /> */}

            {/* FACULTY */}
            <GuardRoute path="/view-faculty" exact component={ViewFaculty} />
            <AdminGuardRoute path="/edit-faculty/:id" exact component={EditFaculty} />
            <AdminGuardRoute path="/add-faculty" exact component={AddFaculty} />

            {/* SEARCH */}
            <GuardRoute path="/search/:searchType/:query" exact component={SearchResults} />
            
            <Redirect to="/dashboard" />

            
          </Switch>
          <Footer/>
        </div>
      </BrowserRouter>
      
    </div>
  );
};

export default App;