import React, { Component } from "react";
import "./NavBar.css";
import SearchBar from "./NavComponents/SearchBar/SearchBar";
import UserDetail from "./NavComponents/UserDetail/UserDetail";

class NavBar extends Component {

  render() {
    return (
      <nav className="navBar dashboardNav">
        <SearchBar/>
        <UserDetail/>
      </nav>
    );
  }
}

export default NavBar;