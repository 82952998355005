// To set user-data at sign-in
export const SetUser = (user) => ({
  type: 'SET_USER',
  payload: user,
});

// For viewing receipts of a student
export const SetViewReceipts = (receiptsData) => ({
  type: 'VIEW_RECEIPTS',
  payload: receiptsData,
})

// For promotion-students-list change
export const ChangePromotionSelectedList = (row) => ({
  type: 'CHANGE_PROMOTION_LIST',
  payload: row,
})
export const ClearPromotionSelectedList = () => ({
  type: 'CLEAR_PROMOTION_LIST',
})
export const DeleteStudentFromPromotionList = (id) => ({
  type: 'DELETE_STUDENT_FROM_PROMOTION_LIST',
  payload: id,
})