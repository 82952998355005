import React from "react";
import { Link, useLocation } from 'react-router-dom';
import styles from "./AddCourse.module.css";
import { Form, Input, Button, Select, Slider, Breadcrumb } from "antd";
import ServerService from "../../../API/ServerService";
import { message, notification } from "antd";
import {
  BookOutlined,
  SwitcherOutlined,
  SmileOutlined,
  FrownOutlined,
} from "@ant-design/icons";

const { Option } = Select;

const AddCourse = () => {

  const [form] = Form.useForm();
  const location = useLocation();

  let url;


  if (location.state) {
    url = [{ name: "Dashboard", link: "/AdminDashboard" },
    { name: "AllCourses", link: "/allCourses" }
      , { name: "EditCourse", link: "/addCourse" }]
  }
  else if (!location.state) {
    url = [{ name: "Dashboard", link: "/AdminDashboard" },
      , { name: "AddCourse", link: "/addCourse" }]
  }


  const openNotification = (title, desc, icon, duration) => {
    notification.open({
      message: title,
      description: desc,
      icon: icon,
      duration: duration,
    });
  };

  const onSubmit = (values) => {
    console.log(values);

    let key = "updatable";
    message.loading({ content: "Action in progress...", key });

    const newCourseData = {
      name: values.courseName,
      code: values.courseCode,
      duration: `${365 * Number(values.courseDuration)} 00:00:00`,
      length_passing_timespan: values.passingSpan,
      length_fee_timespan: values.feeSpan,
    };
    // console.log(newCourseData);
    if (location.state) {

      ServerService.EditCourse(location.state.id, newCourseData)
        .then((res) => {
          console.log(res);

          // If course added successfully
          message.success({ content: "Action completed!", key, duration: 2 });
          openNotification(
            "Course editted successful!",
            "The course was editted successfully. Please head to all courses to view all courses.",
            <SmileOutlined style={{ color: "#108ee9" }} />,
            4.5
          );
          form.resetFields();

        })
        .catch((err) => {
          console.log(err);
          let errMsg = "We are having some trouble creating the course. Please try again later";
          if (err.response.data.detail) {
            errMsg = `${err.response.data.detail}`;
          }

          //If some error occured
          message.error({ content: "Something went wrong!", key, duration: 2 });
          openNotification(
            "Something went wrong!",
            `${errMsg}!`,
            // "We are having some trouble editting the course. Please try again later",
            <FrownOutlined style={{ color: "red" }} />,
            6
          );
          form.resetFields();
        });
    }
    else {
      ServerService.AddCourse(newCourseData)
        .then((res) => {
          console.log(res);

          // If course added successfully
          message.success({ content: "Action completed!", key, duration: 2 });
          openNotification(
            "Course added successful!",
            "The course was created successfully. Please head to all courses to view all courses.",
            <SmileOutlined style={{ color: "#108ee9" }} />,
            4.5
          );
          form.resetFields();

        })
        .catch((err) => {
          console.log(err.response);
          let errMsg = "We are having some trouble creating the course. Please try again later";
          if (err.response.data.detail) {
            errMsg = `${err.response.data.detail}`;
          }

          //If some error occured
          message.error({ content: "Something went wrong!", key, duration: 2 });
          openNotification(
            "Something went wrong!",
            `${errMsg}!`,
            // "We are having some trouble creating the course. Please try again later",
            <FrownOutlined style={{ color: "red" }} />,
            6
          );
          form.resetFields();
        });
    }
  };

  return (
    <div className={styles.displayArea}>
      <Breadcrumb style={{ marginBottom: "24px" }} className={styles.Breadcrumb}>
        {url.map((entry, index) => {
          return <Breadcrumb.Item key={index}>
            <Link style={{ textDecoration: 'none' }} to={`${entry.link}`}>
              {entry.name}
            </Link>
          </Breadcrumb.Item>
        }
        )}
      </Breadcrumb>
      <div className={styles.addCourseForm}>
        <Form
          form={form}
          name="normal_login"
          className="add-course-form"
          initialValues={location.state ?
            {
              courseName: location.state.name, courseCode: location.state.code,
              passingSpan: location.state.length_passing_timespan,
              courseDuration: location.state.duration, feeSpan: location.state.length_fee_timespan
            }
            : null}
          onFinish={onSubmit}
        >
          <h6 className={styles.formHeader}>{location.state ? "Edit Course" : "Add New Course"}{location.state ? location.state.course_duration:null}</h6>

          <h6 className={styles.label}>
            Course Name<span style={{ color: "red" }}>*</span>
          </h6>
          <Form.Item
            name="courseName"
            rules={[
              {
                required: true,
                message: "Please enter the course name!",
              },
            ]}
          >
            <Input
              autoComplete="off"
              prefix={<BookOutlined className="site-form-item-icon" />}
              placeholder="Course Name"
            />
          </Form.Item>

          {/* <h6 className={styles.label}>Course Code</h6> */}
          {/* <Form.Item name="courseCode"
            rules={[
              { max: 6, message: 'Length must be less than 7 characters' },
            ]}
          >
            <Input
              autoComplete="off"
              prefix={<SwitcherOutlined className="site-form-item-icon" />}
              placeholder="Course Code"
              // disabled={location.state ? true : false}

            />
          </Form.Item> */}

          <h6 className={styles.label}>
            Passing Time Span<span style={{ color: "red" }}>*</span>
          </h6>
          <Form.Item
            name="passingSpan"
            rules={[
              {
                required: true,
                message: "Please select an option!",
              },
            ]}
          >
            <Select allowClear >
              <Option value={12}>Year</Option>
              <Option value={6}>Semester</Option>
            </Select>
          </Form.Item>

          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "46%" }}>
              
            </div>
            <div style={{ width: "46%" }}>
              <h6 className={styles.label}>
                Fee Time Span<span style={{ color: "red" }}>*</span>
              </h6>
              <Form.Item
                name="feeSpan"
                rules={[
                  {
                    required: true,
                    message: "Please select an option!",
                  },
                ]}
              >
                <Select allowClear disabled={location.state ? true : false}>
                  <Option value={12}>Year</Option>
                  <Option value={6}>Semester</Option>
                </Select>
              </Form.Item>
            </div>
          </div> */}

          <h6 className={styles.label}>
            Number of Academic years<span style={{ color: "red" }}>*</span>
          </h6>
          <Form.Item name="courseDuration">
            <Slider
              marks={{
                0: "0",
                1: "1",
                2: "2",
                3: "3",
                4: "4",
                5: "5",
              }}
              min={0}
              max={5}
              // disabled={location.state ? true : false}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.addCourseButton}
            >
              {location.state ? "Edit Course" : "Add Course"}

            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddCourse;
