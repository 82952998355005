import React, { useState } from "react";
import "./SearchBar.css";
import SearchIcon from "../../../../../assets/SearchIcon.svg";
import { useHistory } from "react-router-dom";
import { Dropdown, Button, Menu } from "antd";
import { UserOutlined, FileDoneOutlined } from '@ant-design/icons';

const SearchBar = () => {
  const history = useHistory();
  const [query, setQuery] = useState("");
  const [searchSetting, setSearchSetting] = useState({ "searchType":"student" ,"icon": (<UserOutlined />), "text": "Enter student name or student ID to search" });

  const handleChange = (e) => {
    let query = e.target.value;
    setQuery(query);
    if (!query) {
      history.push(`/search/enteryourquery`);
    }
    else history.push(`/search/${searchSetting.searchType}/${query}`);
  };
  const studentSearchText = "Enter student name, father name or enrollment ID";
  const receiptSearchText = "Enter Receipt ID of student to search";

  function handleMenuClick(e) {
    setSearchSetting(e.key === "student" ? { "searchType":e.key, "icon": (<UserOutlined />), "text": studentSearchText} : {"searchType":e.key, "icon": (<FileDoneOutlined />), "text": receiptSearchText});
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="student" icon={<UserOutlined />}>
        Student Search
      </Menu.Item>
      <Menu.Item key="receipt" icon={<FileDoneOutlined />}>
        Receipt Search
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="searchBar">
      {/* <form onSubmit={submitHandler}> */}
      <Dropdown overlay={menu}>
        <Button style={{ margin: "0 20px" }} icon={searchSetting.icon}>
        </Button>
      </Dropdown>
      <img src={SearchIcon} alt="moess query search" />
      <input
        value={query}
        onChange={handleChange}
        placeholder={searchSetting.text}
      />
      {/* </form> */}
    </div>
  );
};

export default SearchBar;
