import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./LoadingData.module.css";

const LoadingData = () => {
  return (
    <div className={styles.displayArea}>
      <div className={styles.studentsListTableBody}>
        <div className={styles.tableTop}>
          <h6 className={styles.tableTopHeading}><Skeleton height={16} width={160}/></h6>
          <div>
            <Skeleton height={12} width={100} style={{ marginRight: "12px" }}/>
            <Skeleton height={12} width={100}/>
          </div>
        </div>
      </div>
      <div className={styles.tableBody}>
        <div className={styles.header}>
          <Skeleton height={12} width={100}/>
          <Skeleton height={12} width={100}/>
          <Skeleton height={12} width={100}/>
          <Skeleton height={12} width={100}/>
          <Skeleton height={12} width={100}/>
        </div>
        <div className={styles.row}>
          <Skeleton height={8} width={700}/>
        </div>
        <div className={styles.row}>
          <Skeleton height={8} width={700}/>
        </div>
        <div className={styles.row}>
          <Skeleton height={8} width={700}/>
        </div>
        <div className={styles.row}>
          <Skeleton height={8} width={700}/>
        </div>
        <div className={styles.row}>
          <Skeleton height={8} width={700}/>
        </div>
        <div className={styles.row}>
          <Skeleton height={8} width={700}/>
        </div>
        <div className={styles.row}>
          <Skeleton height={8} width={700}/>
        </div>
        <div className={styles.row}>
          <Skeleton height={8} width={700}/>
        </div>
      </div>
      <div className={styles.pagination}>
        <Skeleton width={120} height={12}/>
        <Skeleton width={120} height={12}/>
      </div>
    </div>
  );
};

export default LoadingData;
