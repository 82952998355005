import React,{useState} from 'react';
import ServerService from '../../../API/ServerService'
const NavBar = () => {

  const [college,setCollege] = useState({collegeName:'_'})

  React.useEffect(()=>{
    let data={"url":window.location.href};
    ServerService.ConfigApi(data)
    .then(res=>{
        // console.log(res)
        setCollege(prevState=>({...prevState,collegeName:res.data.institutionName}))
    })
    .catch(err=>{
        // console.log(err)
    })
},[])

  // let nameArray= college.collegeName.split(' ')
  // let nameInitial='';


  // for(var i=0;i<nameArray.length;i++){
  //   nameInitial+=nameArray[i][0]
  // }

  return (
    <nav className="navBar">
      <h6 className="logoName">{college.collegeName}</h6>
    </nav>
  );
};

export default NavBar;