import React, { Component } from "react";
import "./AddUser.css";
import { Form, Input, Button, Breadcrumb, message, notification } from "antd";
import {
  SmileOutlined,
  // PlusOutlined,
  // FrownOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  FormOutlined,
} from "@ant-design/icons";
import {Link,Redirect} from 'react-router-dom';
import ServerService from '../../API/ServerService'

class AddUser extends Component {
  state = {
    imageUrl: null,
    selectedImg: null,
    redirect:null,
  };

  openNotification = (title, description, icon, duration) => {
    notification.open({
      message: title,
      description: description,
      icon: icon,
      duration: duration,
    });
  };

  // handleUpload = (event) => {
  //   if (event.target.value.length > 0) {
  //     this.setState({
  //       imageUrl: URL.createObjectURL(event.target.files[0]),
  //       selectedImg: event.target.files[0],
  //     });
  //   }
  // };

  submitHandler = (values) => {
    let key = "updatable";

    //loading msg
    message.loading({ content: "Action in progress...", key });

    
    const reqBody = {
      fullName: values.fullName,
      email: values.email,
      phone: values.phone,
      designation: values.designation,
      password: values.password,
      editDetails: true,
      // img: this.state.selectedImg,
    };
    // console.log(reqBody);

    ServerService.AddNewUser(reqBody)
    .then(res=>{
      // console.log(res)
      this.setState({redirect:"true"})
      message.success({ content: "Action completed!", key, duration: 2 });
    this.openNotification(
      "User created successfully!",
      "Creation successful! Head over to admin-profile to view list of all users.",
      <SmileOutlined style={{ color: "#108ee9" }} />,
      4.5
    );
    }).catch(err=>{
      // console.log(rr)
      message.error({ content: "Something went wrong!", key, duration: 2 });
    this.openNotification(
      "Something went wrong!",
      "We are unable to process your request right now! Please try again after sometime.",
      <ExclamationCircleOutlined style={{ color: "red" }} />,
      4.5
    );
    })
   
  };

  render() {

    if(this.state.redirect){
      return <Redirect to="/viewAccount" />
    }

    const url = [ {name: "DashBoard",link:"/AdminDashboard"}, 
    {name:"View Account",link:"/viewAccount"}, 
    {name:"Add User",link: `/add-user`}]

    return (
      <div className="displayArea">
       <Breadcrumb style={{ marginBottom:"24px"}}>
                    {url.map((entry,index)=> {
                    return<Breadcrumb.Item key={index}>
                            <Link style={{textDecoration:'none'}} to={`${entry.link}`}>
                                {entry.name}
                            </Link>
                            </Breadcrumb.Item>}
                    )}
        </Breadcrumb>  
        <div className="addUserForm">
          <div className="userAddHeader">
            <div className="arrowIcon">
              <Link style={{textDecoration:"none", color:"#808080"}} to="/viewAccount">
                <i class="fas fa-chevron-left" />
              </Link>
            </div>
            <h6>Add User</h6>
          </div>

          <Form
            name="add_user"
            initialValues={{ remember: true }}
            onFinish={this.submitHandler}
          >
            {/* <input
              type="file"
              id="userImg"
              style={{ display: "none" }}
              accept="image/*"
              onChange={this.handleUpload}
            />

            <label htmlFor="userImg">
              {this.state.imageUrl === null ? (
                <div className="uploadButton">
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              ) : (
                <img className="uploadButton" src={this.state.imageUrl} />
              )}
            </label> */}

            {/* USER'S FULL-NAME */}
            <Form.Item
              name="fullName"
              rules={[
                {
                  required: true,
                  message: "Please enter user's full-name!",
                },
              ]}
            >
              <Input
                style={{
                  backgroundColor: "#EEF0F4",
                  borderRadius: "8px",
                }}
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Full Name"
              />
            </Form.Item>

            {/* USER E-MAIL */}
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                { required: true, message: "Please input your E-Mail!" },
              ]}
            >
              <Input
                style={{
                  backgroundColor: "#EEF0F4",
                  borderRadius: "8px",
                }}
                prefix={<MailOutlined />}
                placeholder="E-Mail"
              />
            </Form.Item>

            {/* USER PHONE-NUMBER */}
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input your Phone number!",
                },
                {
                  pattern: /^[0-9]\d{0,13}$/,
                  message: "Value should be less than 15 digits",
                },
              ]}
            >
              <Input
                style={{
                  backgroundColor: "#EEF0F4",
                  borderRadius: "8px",
                }}
                prefix={<PhoneOutlined />}
                placeholder="Phone Number"
              />
            </Form.Item>

            {/* USER'S DESIGNATION */}
            <Form.Item
              name="designation"
              rules={[
                {
                  required: true,
                  message: "Please enter user's full-name!",
                },
              ]}
            >
              <Input
                style={{
                  backgroundColor: "#EEF0F4",
                  borderRadius: "8px",
                }}
                prefix={<FormOutlined className="site-form-item-icon" />}
                placeholder="Designation"
              />
            </Form.Item>

            {/* USER-PASSWORD */}
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
                {
                  pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                  message:
                    "Minimum eight characters, at least one letter, one number and one special character:",
                },
              ]}
            >
              <Input.Password
                style={{
                  backgroundColor: "#EEF0F4",
                  borderRadius: "8px",
                }}
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            {/* <Form.Item
              name="editDetails"
              className="switchBox"
              label="Access to edit details"
            >
              <Switch />
            </Form.Item> */}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Create User
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default AddUser;
