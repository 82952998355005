import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./BranchYearCard.module.css";

const BranchYearCard = (props) => {
  const history = useHistory();
  const colors = ["#66CDAA", "#CD8282", "#82A8CD", "#999999"];
  const location = useLocation();

  const handleRedirection = () => {
    if (props.viewStudents) {
      // props.data.id is branch-ID
      history.push({
        pathname: `/view-students/${props.courseId}/${props.data.id}`,
        state: {
          course: props.data.courseName,
          branch: props.data.branch_name,
        },
      });
    }
  };

  const handleYearRedirection = (year) => {
    if (props.viewStudents) {
      // props.data.id is branch-ID
      history.push({
        pathname: `/view-students/${props.courseId}/${props.data.id}/${year}`,
        state: {
          course: props.data.courseName,
          branch: props.data.branch_name,
          year: year,
        },
      });
    }
  };

  return (
    <div
      style={{ borderColor: `${colors[props.index % 4]}` }}
      className={styles.branchCard}
    >
      <div className={styles.branchCardHeader}>
        <div
          style={{ backgroundColor: `${colors[props.index % 4]}` }}
          className={styles.cardIcon}
        >
          {/* <i class="fas fa-graduation-cap" /> */}
          <i class="fas fa-university" />
        </div>

        <h6 onClick={handleRedirection} className={styles.viewAll}>
          View All
        </h6>

        <div className={styles.cardContent}>
          <h6 className={styles.courseName}>{props.data.branch_name}</h6>
          <h6>
            <span style={{ paddingRight: "6px" }} className={styles.courseData}>
              {props.data.total_passing_timespans} {props.data.span_label}
            </span>
            <span style={{ paddingLeft: "6px" }} className={styles.courseData}>
              {props.data.courseName}
            </span>
          </h6>
          <h6>
            <span className={styles.courseData}>
              {props.data.numOfStudents} Students
            </span>
          </h6>
        </div>
      </div>

      <div className={styles.yearsList}>
        {props.data.passing_timespans &&
          props.data.passing_timespans.map((year, index) => {
            return (
              <div key={index} className={styles.yearContainer}>
                <span>{`${year} ${props.data.span_label}`}</span>
                <div
                  onClick={() => handleYearRedirection(year)}
                  className={styles.arrowIcon}
                >
                  <i class="fas fa-chevron-right" />
                </div>
              </div>
            );
          })}
      </div>

      <div className={styles.yearList}></div>
    </div>
  );
};

export default BranchYearCard;
