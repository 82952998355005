import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { NavLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Menu, Dropdown, Button, Select } from "antd";
import {
  DownOutlined,
  SmileOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { message, notification } from "antd";
import Table from "../../../../components/Table/Table";
import dummyData from "./dummyData";
import SortList from "./SortingProp";
// import SearchIcon from "../../../../assets/SearchIcon.svg";.
import {
  ChangePromotionSelectedList,
  ClearPromotionSelectedList,
} from "../../../../redux/actions/actions";
import SelectedSideWindow from "./SideWindow/SelectedSideWindow";
import MaleIcon from "../../../../assets/MaleUser.svg";
import FemaleIcon from "../../../../assets/FemailUser.svg";
import ServerService from "../../../../API/ServerService";
import styles from "./StudentSelection.module.css";

const { Option } = Select;
const { SubMenu } = Menu;

const StudentSelection = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { courseId } = useParams();

  const selectedStudentsList = useSelector(
    (state) => state.promote_students.selectedStudents
  );

  const AllColumns = [
    {
      title: '',
      dataIndex: '',
      render: (row) => {
        if (row.gender === 'M') {
          return (
            <img src={MaleIcon} alt="male-icon" />
          )
        }
        else {
          return (
            <img src={FemaleIcon} alt="femail-icon" />
          )
        }
      },
      width: 50,
    },
    {
      title: 'Enrollment ID',
      dataIndex: ['enrollment_id', 'id'],
      key: 'enrollment_id',
      width: 120,
      render: (text, row) => {
        return (
          <NavLink style={{ color: 'black' }} to={`/studentDetails/${row['id']}`}>
            <div>{row['enrollment_id']}</div>
          </NavLink>
        )
      }
    },
    {
      title: 'Name',
      dataIndex: 'studentName',
      key: 'studentName',
      render: (text, row) => {
        return (
          <span
            onClick={() => dispatch(ChangePromotionSelectedList(row))}
            style={{ cursor: "pointer" }}
          >
            {`${row.first_name} ${row.last_name}`}
          </span>
        );
      },
    },
    {
      title: 'Course',
      dataIndex: 'course',
      key: 'course',
      // width: 100,
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
    },
    // {
    //   name: "DOB",
    //   selector: "date_of_birth",
    // },
    // {
    //   name: "Phone No.",
    //   selector: "phone_number",
    // },
  ];

  const [sortProp, setSortProp] = useState(SortList[0].value);
  const [sortValue, setSortValue] = useState("asc");

  // const [query, setQuery] = useState("");
  // const [selectedRows, setSelectedRows] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [displayNumItems, setDisplayNumItems] = useState(50);
  const [totalItems, setTotalItems] = useState(0);

  const [columns, setColumns] = useState(AllColumns);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // Clearing selected-students list
    dispatch(ClearPromotionSelectedList());
  }, []);

  useEffect(() => {
    console.log("CourseId -> ", courseId);

    // API call
    const reqBody = {
      courses: [Number(courseId)],
      order_by: sortProp,
      order: sortValue,
      type: "new",
    };
    ServerService.GetViewStudents(displayNumItems, currPage, reqBody)
      .then((res) => {
        // console.log(res);
        let totPages = parseInt(res.data.count / displayNumItems);
        if (res.data.count % displayNumItems != 0) {
          totPages += 1;
        }
        setTotalPages(totPages);
        setTotalItems(res.data.count);
        setTableData(res.data.results);
        openHintNotification();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [courseId, currPage, sortProp, sortValue, displayNumItems]);

  const openHintNotification = () => {
    const args = {
      message: 'Promotion Hints',
      description:
        'Click on student name to fail that student.',
      duration: 5,
    };
    notification.open(args);
  };

  const openNotification = (message, desc, icon, duration) => {
    notification.open({
      message: message,
      description: desc,
      icon: icon,
      duration: duration,
    });
  };

  const handleSubmit = () => {
    let key = "updatable";
    message.loading({ content: "Action in progress...", key });
    // console.log(selectedStudentsList);

    let idList = [];
    selectedStudentsList.map((row) => {
      idList.push(row.id);
      return null;
    });
    // console.log(idList);

    let reqBody = {
      course: Number(courseId),
      ids: idList
    }
    ServerService.PromoteStudents(reqBody)
      .then((res) => {
        // console.log(res);
        // If status-code = 200
        message.success({ content: "Action completed!", key, duration: 2 });
        openNotification(
          "Promotion was successful!",
          "The students were promoted successfully to subsequent academic years!",
          <SmileOutlined style={{ color: "#108ee9" }} />,
          4.5
        );
        history.push(`/promote`);
      })
      .catch((err) => {
        console.log(err);
        // Something went wrong
        message.error({ content: "Something went wrong!", key, duration: 2 });
        openNotification(
          "Something went wrong!",
          "We are unable to process your request right now! Please try again after sometime.",
          <ExclamationCircleOutlined style={{ color: "red" }} />,
          4.5
        );
      });
  };

  // Pagination + Row selection
  const handlePageChange = (currentPage) => {
    setCurrPage(currentPage);
  };

  // Number of rows to display handler
  const handleRowCountChange = (selection) => {
    setDisplayNumItems(selection.value);
    setCurrPage(1);
  };

  // Sorting handler
  const sortHandler = (sortProperty, sortType) => {
    setSortProp(sortProperty);
    setSortValue(sortType);
    setCurrPage(1);
  };
  const sortMenu = (
    <Menu>
      {SortList.map((sortProp) => {
        return (
          <SubMenu title={sortProp.label}>
            <Menu.Item onClick={() => sortHandler(sortProp.value, "asc")}>
              Ascending A-Z
            </Menu.Item>
            <Menu.Item onClick={() => sortHandler(sortProp.value, "desc")}>
              Descending Z-A
            </Menu.Item>
          </SubMenu>
        );
      })}
    </Menu>
  );

  // For search query
  // const submitQuery = (e) => {
  //   e.preventDefault();
  //   // API call
  //   const reqBody = {
  //     courseId: courseId,
  //     batchId: batchId,
  //     yearId: yearId,
  //     query: query,
  //     pageNumber: currPage,
  //     sortProp: sortProp,
  //     sortType: sortValue,
  //     numOfRows: displayNumItems,
  //   };
  //   alert(query);
  //   console.log(reqBody);
  // };

  return (
    <div className={styles.displayArea}>
      <div className={styles.promotionListTableBody}>
        <div className={styles.tableTop}>
          <h6 className={styles.tableTopHeading}>Promote Students</h6>
          <div>
            <Dropdown overlay={sortMenu}>
              <Button style={{ width: "100px", marginRight: "14px" }}>
                Sort
                <DownOutlined />
              </Button>
            </Dropdown>

            <Select
              trigger={["hover", "click"]}
              labelInValue
              defaultValue={{ value: 50 }}
              style={{ width: 162, marginRight: "14px" }}
              onChange={handleRowCountChange}
            >
              <Option value={50}>Show 50 students</Option>
              <Option value={100}>Show 100 students</Option>
              <Option value={200}>Show 200 students</Option>
              <Option value={500}>Show 500 students</Option>
            </Select>

            {/* <form
              style={{ display: "inline" }}
              className={styles.searchForm}
              onSubmit={submitQuery}
            >
              <img src={SearchIcon} alt="searchIcon" />
              <input
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Enter Student's Name/ID"
              />
            </form> */}
          </div>
        </div>

        <Table
          currentPage={currPage}
          changePage={handlePageChange}
          columns={columns}
          data={tableData}
          totalNum={totalItems}
          displayNum={displayNumItems}
          totalPages={totalPages}
          selectRow={false}
        />

        <SelectedSideWindow />
      </div>

      <Button
        onClick={handleSubmit}
        type="primary"
        className={styles.promoteButton}
      >
        Promote Students
      </Button>
    </div>
  );
};

export default StudentSelection;
