import React, { Component } from "react";
import { connect } from "react-redux";
import { message, notification } from "antd";
import { FrownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import "./Login.css";
import { Redirect } from "react-router-dom";
import LoginForm from "./LoginForm/LoginForm";
import ServerService from "../../API/ServerService";
import { SetUser } from "../../redux/actions/actions";

class Login extends Component {
  state = {
    redirect: false,
    name: "",
    logo: "",
  };

  componentDidMount() {
    localStorage.clear();
    this.props.setUserData(null);
    let data={"url":window.location.href};
    ServerService.ConfigApi(data)
      .then((res) => {
        // console.log(res);
        this.setState({ name: res.data.institutionName, logo: res.data.logo });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  openNotification = (message, desc, icon, duration) => {
    notification.open({
      message: message,
      description: desc,
      icon: icon,
      duration: duration,
    });
  };

  onFinish = (userData) => {
    // console.log(userData);

    let key = "updatable";
    //loading msg
    message.loading({ content: "Action in progress...", key });

    const signinData = {
      email: userData.email,
      password: userData.password,
    };

    // comment these 7 lines out later
    // ***********************************
    // this.props.setUserData("SignedIn");
    // localStorage.setItem("accessToken", "udcbyu89r9880irhf");
    // localStorage.setItem("CollegList-user", "SignedIn");
    // localStorage.setItem("role", "admin");
    // this.setState({
    //   redirect: "/AdminDashboard",
    // });
    // ***********************************

    //SignIn api call
    ServerService.SigninInstitution(signinData)
      .then((res) => {
        console.log(res);
        //to stop the loading
        message.success({ content: "Action completed!", key, duration: 2 });

        if (res.status === 200) {
          // if login is successful
          this.props.setUserData(res.data.userData);
          localStorage.setItem("accessToken", res.data.access);
          localStorage.setItem("refreshToken", res.data.refresh);
          localStorage.setItem("user", JSON.stringify(res.data.userData));
          // console.log(res.data.userData.stringify());

          if (res.data.role === "admin") {
            localStorage.setItem("role", "admin");
            this.setState({
              redirect: "/dashboard",
            });
          } else {
            localStorage.setItem("role", "staff");
            this.setState({
              redirect: "/dashboard",
            });
          }
        }
      })
      .catch((err) => {
        //to stop the loading
        message.error({ content: "Something went wrong!", key, duration: 2 });
        const error = { ...err };

        if (error.response && error.response.status === 400) {
          // if password doesn't match
          this.openNotification(
            "Authentication failed!",
            "The password you entered is incorrect! Try Again.",
            <FrownOutlined style={{ color: "red" }} />,
            5
          );
          return;
        } else if (error.response && error.response.status === 404) {
          //email does not exist
          this.openNotification(
            "Account doesn't exist!",
            "The Email you entered is not linked to any account! Please signup to proceed further.",
            <ExclamationCircleOutlined style={{ color: "red" }} />,
            6
          );
          return;
        } else if (error.response && error.response.status === 406) {
          //not yet verified
          this.openNotification(
            "Account not verified!",
            "Your account is yet to be verified by the admin! Please try again after sometime.",
            <ExclamationCircleOutlined style={{ color: "red" }} />,
            6
          );
          return;
        } else {
          //any other error
          this.openNotification(
            "Something went wrong!",
            "We are unable to process your request right now! Please try again after sometime.",
            <ExclamationCircleOutlined style={{ color: "red" }} />,
            5
          );
        }
      });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        {/* <NavBar canGoBack={true} /> */}
        <div className="bg">
          <div className="signUpHeader">
            <img
              src={this.state.logo}
              alt="logo"
              style={{ marginBottom: "40px", width: "100px" }}
            />
            <h6 className="headline">It's great to have you back ;)</h6>
          </div>

          <div className="signUpForm loginForm">
            <LoginForm submitHandler={this.onFinish} />
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (user) => dispatch(SetUser(user)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
