import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import "./Table.css";
import { useEffect } from "react";
import { Table } from "antd";


const CustomTable = (props) => {
  const useStyles = makeStyles((theme) => ({
    footer: {
      flexGrow: 1,
      height: "4rem !important",
      padding: "0 1rem",
      boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.05)",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
    },
  }));
  const classes = useStyles();

  //For row-color class
  const [rowColorClass, setRowColorClass] = useState("colorTableRows");
  useEffect(() => {
    if (props.showColoredRows === false) {
      setRowColorClass("");
    }
  }, []);

  //For pagination
  const [currentPage, setCurrentPage] = useState(props.currentPage);
  const [data, setData] = useState(props.data);

  const handleChange = (e, page) => {
    props.changePage(page);
    setCurrentPage(page);
  };

  //For row selection
  const handleRowClicked = (row) => {
    props.selectedRowChange(row);
  };

  let itemsData = (
    <>
      ({(props.currentPage - 1) * props.displayNum + 1} -{" "}
      {(props.currentPage - 1) * props.displayNum + props.displayNum})
    </>
  );
  if (props.currentPage === props.totalPages) {
    itemsData = (
      <>
        ({(props.currentPage - 1) * props.displayNum + 1} - {props.totalNum})
      </>
    );
  }
  return (
    <div>
      <Table
        // responsive
        // // persistTableHead={true}
        // selectableRows={props.selectRow === undefined ? true : props.selectRow}
        // selectableRowsHighlight
        // defaultSortAsc
        // fixedHeader
        // onSelectedRowsChange={handleRowClicked}
        columns={props.columns}
        dataSource={props.data}
        scroll={{
          y: 500,
          x: props.scrollX,
        }}
        pagination={false} 
        // style={
        //   props.data && props.data.length === 0
        //     ? { minHeight: "calc(90vh - 185px)" }
        //     : null
        // }
        // className={`${props.className} ${rowColorClass}`}
      // customStyles={customStyles}
      />

      {props.noPagination ? null : (
        <Grid
          container
          justify="space-between"
          className={classes.footer}
          style={{ display: props.pagination === false ? "none" : "" }}
        >
          <span style={{ fontSize: "1rem", fontWeight: "400" }}>
            Showing {itemsData} of {props.totalNum} results
          </span>

          <Pagination
            onChange={handleChange}
            count={props.totalPages}
            size="small"
            page={props.currentPage}
          />
        </Grid>
      )}
    </div>
  );
};

export default CustomTable;
