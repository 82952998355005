import React from "react";
import { useHistory } from "react-router-dom";
import { LogoutOutlined, CloseOutlined } from '@ant-design/icons';
import styles from "./AddStudentOverlay.module.css";

const AddStudentOverlay = (props) => {
  const history = useHistory();

  const handleRedirection = (type) => {
    if (type === "individual") {
      history.push("/add-student/single");
    } else {
      history.push("/add-student/bulk");
    }
  };

  return (
    <div className={styles.addOptionBlock} onClick={(e) => e.stopPropagation()}>
      <CloseOutlined color="#f5f5f5" onClick={() => history.goBack()} className={styles.closeIcon}/>
      <p className={styles.header}>Add New Student</p>
      <div
        className={styles.optionButtons}
        onClick={() => handleRedirection("individual")}
      >
        Individual student
        <LogoutOutlined style={{ fontSize:'16.8px' }} />
      </div>
      <div
        className={styles.optionButtons}
        onClick={() => handleRedirection("bulk")}
      >
        Bulk upload
        <LogoutOutlined style={{ fontSize:'16.8px' }} />
      </div>
    </div>
  );
};

export default AddStudentOverlay;
