import React from "react";
import { Form, Input, Button, Checkbox } from "antd";
// import { DeleteTwoTone } from "@ant-design/icons";
import styles from "./AddFeeType.module.css";
import { useEffect } from "react";
import {useLocation } from 'react-router-dom';

const AddFee = (props) => {
  const [form] = Form.useForm();
  const location =useLocation()

  const addFee = (values) => {
    props.AddFee(values);
  };

  useEffect(() => {
    form.setFieldsValue({
      included_in_base_fee: true,
    });
  }, []);
  const initialAmount = location.state ? location.state.fee : null
  const initialValues = {remember:true, amount:initialAmount }

  return (
    <Form
      form={form}
      name="normal_login"
      className={styles.addFeeForm}
      initialValues={initialValues}
      onFinish={addFee}
    >
      <div className={styles.formRow}>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "required*",
            },
          ]}
          style={{
            maxWidth: "24%",
            minWidth: "24%",
            marginRight: "18px",
          }}
        >
          <Input
            autoComplete="off"
            style={{
              backgroundColor: "#f3f4f8",
              borderRadius: "6px",
              padding: "9px 14px",
              border: "none",
            }}
            placeholder="Fee Type..."
          />
        </Form.Item>

        <Form.Item
          name="amount"
          rules={[
            {
              required: true,
              message: "required*",
            },
            {
              pattern: /^[0-9]\d{0,13}$/,
              message: "Only digits allowed",
            },
          ]}
          style={{
            maxWidth: "18%",
            minWidth: "18%",
            marginRight: "18px",
          }}
        >
          <Input
            style={{
              backgroundColor: "#f3f4f8",
              borderRadius: "6px",
              padding: "9px 14px",
              border: "none",
            }}
            autoComplete="off"
            placeholder="Fee Amount..."
          />
        </Form.Item>

        <Form.Item
          name="comment"
          
          style={{
            flexGrow: "1",
          }}
        >
          <Input
            style={{
              backgroundColor: "#f3f4f8",
              borderRadius: "6px",
              padding: "9px 14px",
              border: "none",
            }}
            autoComplete="off"
            placeholder="Fee Note..."
          />
        </Form.Item>

        <Form.Item
          name="included_in_base_fee"
          valuePropName="checked"
          style={{
            minWidth: "14%",
            textAlign: "center",
          }}
        >
          <Checkbox />
        </Form.Item>
      </div>

      <Form.Item style={{ textAlign: "center" }}>
        {/* <Button type="primary" htmlType="submit" className={styles.addFeeType}>
          Save Fee Type
        </Button> */}

        {/* <Button
          htmlType="submit"
          style={{
            margin: "0px 24px",
            width: "94.4%"
          }}
        >
          Save Fee-Type
        </Button> */}

        <div
          style={{
            textAlign: "center",
          }}
        >
          <Button htmlType="submit" type="link">
            Save fee-type
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default AddFee;
