import React, { useState } from "react";
import Calendar from "react-calendar";
import "./Calendar.css";
import 'react-calendar/dist/Calendar.css';

const Calender = () => {

  const [value,onChange] = useState(new Date());

  return (
    <div className="calender">
      <h6 className='heading'>CALENDAR</h6>
      <Calendar onChange={onChange} value={value} />
    </div>
  );
};

export default Calender;
