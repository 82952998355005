const data = [
  {
    label: 'Enrollment ID',
    value: 'enrollment_id',
  },
  {
    label: 'Name',
    value: 'first_name',
  },
  {
    label: 'Course',
    value: 'course',
  },
  {
    label: 'Branch',
    value: 'branch',
  },
  {
    label: 'DOB',
    value: 'date_of_birth',
  },
  {
    label: 'Phone No.',
    value: 'phone_number',
  }
];

export default data;