const data = [
  {
    label: 'Receipt ID',
    value: 'recieptId',
  },
  {
    label: 'Roll No',
    value: 'enrollmentId',
  },
  {
    label: 'Academic Year',
    value: 'passing_span',
  },
  {
    label: 'Session',
    value: 'batch',
  },
  {
    label: 'Submitted By',
    value: 'createdAt',
  },
  {
    label: 'Created At',
    value: 'createdAt',
  },
  {
    label: 'Amount Paid',
    value: 'amount_paid',
  },
];

export default data;