import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Button, message, notification, Select, Modal } from "antd";
import {
  DeleteTwoTone,
  SmileOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import styles from "./FeeStatus.module.css";
// import UserImg from "../../../assets/UserImage.svg";
// import dummyData from "./dummyData";
import DefaultProfilePic from "../../../assets/DefaultProfilePic.png";
import AddFee from "./AddFee/AddFee";
import ServerService from "../../../API/ServerService";
import Skeleton from "react-loading-skeleton";

const { Option } = Select;
const { confirm } = Modal;

const FeeStatus = () => {
  const { studentId } = useParams();
  const { receiptId } = useParams();
  const history = useHistory();

  const [studentData, setStudentData] = useState(null);
  const [totFee, setTotFee] = useState(0);
  const [feeStatusData, setFeeStatusData] = useState([]);
  const [showAddField, setShowAddField] = useState(false);
  const [choiceData, setChoiceData] = useState([]);
  const [selectedSpan, setSelectedSpan] = useState(null);

  useEffect(() => {
    ServerService.GetStudentDetails(studentId)
      .then((res) => {
        // console.log(res);
        setStudentData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // console.log(receiptId);
    ServerService.GetSubmitFeeDropdown(studentId)
      .then((res) => {
        // console.log(res);
        setChoiceData(res.data.fee);
        setSelectedSpan(res.data.default);
        setFeeStatusData(res.data.default.fee_details);
        let totFee = 0;
        res.data.default.fee_details.map((fee) => {
          totFee += fee.amount;
        });
        setTotFee(totFee);

      })
      .catch((err) => {
        console.log(err);
        let key = "updatable";
        message.error({ content: "Something went wrong!", key, duration: 2 });
        openNotification(
          "Fees does not exist!",
          "Please create a fees for the batch before submitting a student's fee",
          <ExclamationCircleOutlined style={{ color: "red" }} />,
          4.5
        );
        history.push(`/add-fee/new`);
      });

    // Edit Receipt
    if (receiptId !== undefined) {
      ServerService.GetReceiptData(receiptId)
        .then((res) => {
          // console.log(res);

          // if this receipt is non-editable
          if (!res.data[0].isEditable) {
            history.push(`/studentDetails/${studentId}`);
          }

          // if receipt is editable
          setFeeStatusData(res.data[0].fee_detail);

          let selectedSpan = {
            id: res.data[0].fee,
            label: res.data[0].passing_span,
          };
          setSelectedSpan(selectedSpan);

          let totFee = 0;
          res.data[0].fee_detail.map((fee) => {
            totFee += fee.amount;
          });
          setTotFee(totFee);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const openNotification = (message, desc, icon, duration) => {
    notification.open({
      message: message,
      description: desc,
      icon: icon,
      duration: duration,
    });
  };

  const handleChangeSpan = (value) => {
    choiceData.map((span) => {
      if (span.label === value) {
        setSelectedSpan(span);
        setFeeStatusData(span.fee_details);
        // console.log(span);
        let totFee = 0;
        span.fee_details.map((fee) => {
          totFee += fee.amount;
        });
        setTotFee(totFee);
        return;
      }
    });
  };

  const AddFeeDetails = (feeDetails) => {
    let newFeeData = {
      type: feeDetails.feeType,
      amount: Number(feeDetails.feeAmount),
      comment: feeDetails.feeNote,
    };

    let feeData = [...feeStatusData];
    feeData.push(newFeeData);
    setFeeStatusData(feeData);
    setShowAddField(false);
    setTotFee((prevTot) => {
      return prevTot + newFeeData.amount;
    });
  };

  const deleteField = (idx) => {
    let feeData = [];
    let tot_fee = 0;
    feeStatusData.map((fee, index) => {
      if (index !== idx) {
        feeData.push(fee);
        tot_fee += fee.amount;
      }
    });
    setTotFee(tot_fee);
    setFeeStatusData(feeData);
  };

  const SaveForm = () => {
    confirm({
      title: 'Are you sure to submit fee!',
      icon: <ExclamationCircleOutlined />,
      // content: 'All the student data within the branch will be deleted.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {



        if (totFee == 0) {
          openNotification(
            "Something went wrong!",
            "Please add fee-details before submitting the student's fees!",
            <ExclamationCircleOutlined style={{ color: "red" }} />,
            4.5
          );
          return;
        }

        let reqBody = {
          fee: Number(selectedSpan.id),
          student: Number(studentId),
        };
        let feeArr = [];
        feeStatusData.map((fees, index) => {
          feeArr.push(fees);
        });
        reqBody.fee_detail = feeArr;
        // console.log(reqBody);
        let key = "updatable";
        message.loading({ content: "Action in progress...", key });

        if (receiptId !== undefined) {
          // Put request
          ServerService.EditStudentFee(reqBody, receiptId)
            .then((res) => {
              // console.log(res);
              // If status-code = 200
              message.success({ content: "Action completed!", key, duration: 2 });
              openNotification(
                "Fee edited successfully!",
                "The fees for student was edited successfully. Here's the generated receipt!",
                <SmileOutlined style={{ color: "#108ee9" }} />,
                4.5
              );
              history.push(
                `/studentDetails/${studentId}/view-receipt/${receiptId}`
              );
            })
            .catch((err) => {
              // console.log(err.response.detail);
              // Something went wrong
              message.error({ content: "Something went wrong!", key, duration: 2 });
              openNotification(
                "Something went wrong!",
                "We are unable to process your request right now! Please try again after sometime.",
                <ExclamationCircleOutlined style={{ color: "red" }} />,
                4.5
              );
            });
        } else {
          // Post-request
          ServerService.SubmitStudentFee(reqBody)
            .then((res) => {
              // console.log(res);
              // If status-code = 200
              message.success({ content: "Action completed!", key, duration: 2 });
              openNotification(
                "Fee submitted successfully!",
                "The fees for student was submitted successfully. Here's the generated receipt!",
                <SmileOutlined style={{ color: "#108ee9" }} />,
                4.5
              );
              let receipt_id = res.data.id;
              history.push(
                `/studentDetails/${studentId}/view-receipt/${receipt_id}`
              );
            })
            .catch((err) => {
              console.log(err.response.data.detail);
              // Something went wrong
              message.error({ content: err.response.data.detail[0], key, duration: 2 });
              // openNotification(
              //   "Something went wrong!",
              //   "We are unable to process your request right now! Please try again after sometime.",
              //   <ExclamationCircleOutlined style={{ color: "red" }} />,
              //   4.5
              // );
            });
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  let studentDataDisplay = (
    <>
      <Skeleton className={styles.userImage} height={102} width={102} />
      <div className={styles.userData}>
        <h6 className={styles.label}>
          <Skeleton height={12.2} width={50} />
        </h6>
        <h6 className={styles.data}>
          <Skeleton height={12.2} width={90} />
        </h6>
      </div>
      <div className={styles.userData}>
        <h6 className={styles.label}>
          <Skeleton height={12.2} width={58} />
        </h6>
        <h6 className={styles.data}>
          <Skeleton height={12.2} width={128} />
        </h6>
      </div>
      <div className={styles.userData}>
        <h6 className={styles.label}>
          <Skeleton height={12.2} width={50} />
        </h6>
        <h6 className={styles.data}><Skeleton height={12.2} width={95} /></h6>
      </div>
      <div className={styles.userData}>
        <h6 className={styles.label}><Skeleton height={12.2} width={60} /></h6>
        <h6 className={styles.data}>
          <Skeleton height={12.2} width={60} />
        </h6>
      </div>
      <div className={styles.userData}>
        <h6 className={styles.label}>
          <Skeleton height={12.2} width={50} />
        </h6>
        <h6 className={styles.data}>
          <Skeleton height={12.2} width={120} />
        </h6>
      </div>
      <div className={styles.userData}>
        <h6 className={styles.label}>
          <Skeleton height={12.2} width={60} />
        </h6>
        <h6 className={styles.data}>
          <Skeleton height={12.2} width={90} />
        </h6>
      </div>
      <div className={styles.userData}>
        <h6 className={styles.label}>
          <Skeleton height={12.2} width={50} />
        </h6>
        <h6 className={styles.data}>
          <Skeleton height={12.2} width={110} />
        </h6>
      </div>
      <div className={styles.userData}>
        <h6 className={styles.label}>
          <Skeleton height={12.2} width={60} />
        </h6>
        <h6 className={styles.data}>
          <Skeleton height={12.2} width={105} />
        </h6>
      </div>
      <div className={styles.userData}>
        <h6 className={styles.label}>
          <Skeleton height={12.2} width={50} />
        </h6>
        <h6 className={styles.data}>
          <Skeleton height={12.2} width={90} />
        </h6>
      </div>
    </>
  );
  if (studentData !== null) {
    studentDataDisplay = (
      <>
        <img src={studentData.image ? studentData.image : DefaultProfilePic} className={styles.userImage} alt="studentImg" />
        <div className={styles.userData}>
          <h6 className={styles.label}>Roll No:</h6>
          <h6 className={styles.data}>{studentData.enrollment_id}</h6>
        </div>
        <div className={styles.userData}>
          <h6 className={styles.label}>Name:</h6>
          <h6 className={styles.data}>{studentData.full_name}</h6>
        </div>
        <div className={styles.userData}>
          <h6 className={styles.label}>Father's Name:</h6>
          <h6 className={styles.data}>{studentData.father_name}</h6>
        </div>
        <div className={styles.userData}>
          <h6 className={styles.label}>Course:</h6>
          <h6 className={styles.data}>{studentData.course}</h6>
        </div>
        <div className={styles.userData}>
          <h6 className={styles.label}>Session:</h6>
          <h6 className={styles.data}>{studentData.batch}</h6>
        </div>
        <div className={styles.userData}>
          <h6 className={styles.label}>Year:</h6>
          <h6 className={styles.data}>{studentData.passing_span}</h6>
        </div>
        <div className={styles.userData}>
          <h6 className={styles.label}>Branch:</h6>
          <h6 className={styles.data}>{studentData.branch}</h6>
        </div>
        <div className={styles.userData}>
          <h6 className={styles.label}>Gender:</h6>
          <h6 className={styles.data}>{studentData.gender}</h6>
        </div>
        <div className={styles.userData}>
          <h6 className={styles.label}>Pending Fee:</h6>
          <h6 className={styles.pendingFee}>₹ {studentData.due_amount}</h6>
        </div>
      </>
    );
  }

  return (
    <div className={styles.displayArea}>
      <div className={styles.leftContainer}>
        <div className={styles.feeSpanSelection}>
          <h6>Select fee-span</h6>
          <Select
            onChange={handleChangeSpan}
            value={selectedSpan ? selectedSpan.label : null}
          >
            {choiceData
              ? choiceData.map((span, index) => {
                return (
                  <Option key={index} value={span.label}>
                    {span.label}
                  </Option>
                );
              })
              : null}
          </Select>
        </div>

        <div className={styles.feeDetails}>
          <div className={styles.header}>
            <h6>Fee Type</h6>
            <h6>Fee Amount</h6>
            <h6>Payment Method</h6>
          </div>

          <div className={styles.statusBody}>
            {feeStatusData && feeStatusData.map((feeData, index) => {
              return (
                <div className={styles.feeRow}>
                  <div className={styles.feeType}>{feeData.type}</div>
                  <div className={styles.feeAmount}>{feeData.amount}</div>
                  <div className={styles.feeNote}>
                    <span className={styles.feeNoteNote}>
                      {feeData.comment}
                    </span>
                    <DeleteTwoTone
                      onClick={() => deleteField(index)}
                      style={{ cursor: "pointer", transform: "scale(1.15)" }}
                      twoToneColor="red"
                    />
                  </div>
                </div>
              );
            })}

            {showAddField ? (
              <AddFee AddFee={AddFeeDetails} />
            ) : (
              <div
                onClick={() => setShowAddField(true)}
                className={styles.addFee}
              >
                + Add Fee-details
              </div>
            )}
          </div>

          <div className={styles.footer}>
            <div>
              Total Amount :
              <span style={{ fontWeight: "600", marginLeft: "6px" }}>
                {`₹ ${totFee}`}
              </span>
            </div>
            <Button
              onClick={SaveForm}
              type="primary"
              className={styles.saveForm}
            >
              Save Form
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.profileDetails}>{studentDataDisplay}</div>
    </div>
  );
};

export default FeeStatus;
