import React from "react";
import { NavLink } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";

function LoginForm(props) {

  const onFinish = (values) => {
    const userData = {
      email : values.email,
      password : values.password
    }
    props.submitHandler(userData);
  }

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          { required: true, message: "Please input your E-Mail!" },
        ]}
      >
        <Input
          style={{
            backgroundColor: "#EEF0F4",
            borderRadius: "8px",
          }}
          prefix={<MailOutlined />}
          placeholder="E-Mail"
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          { 
            required: true, 
            message: "Please input your Password!" 
          },
          // {
          //   pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
          //   message: "Minimum eight characters, at least one letter and one number required"
          // }
        ]}
      >
        <Input.Password
          style={{
            backgroundColor: "#EEF0F4",
            borderRadius: "8px",
          }}
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Sign In
        </Button>
      </Form.Item>

      {/* <h6 className="formFooter">
        Don't have an acccount?
        <NavLink to="/UserSignup">Create Account</NavLink>
      </h6> */}
    </Form>
  );
}

export default LoginForm;
