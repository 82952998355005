import React, { Component } from 'react';
import { NavLink, Redirect } from "react-router-dom";
import "./SideBarOption.css";

class SideBarOption extends Component {

  state = {
    seeOptions: false
  }

  toggle = () => {
    let newtoggle = !this.state.seeOptions;
    this.setState({
      seeOptions: newtoggle,
    });
  }

  redirectHelper = (value) => {
    return <Redirect to={`/${value}`} />;
  };

  render() {
    let subOptions = null;
    if (this.state.seeOptions && this.props.item.subHeadings) {
      subOptions = (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
          className="subOptionsContainer"
        >
          {this.props.item.subHeadings.map((subOpt, index) => {

            return (
              <NavLink
                activeStyle={{
                  backgroundColor: "#E8F7F1",
                  color: "#1AB273",
                  borderRadius: "4px 0px 0px 4px"
                }}
                activeClassName="activeSubOption"
                to={`/${subOpt.redirect}`}
                style={{ color: "black", width: "86%" }}
              >
                <div className="subOptions">
                  <h6 size="small">{subOpt.title}</h6>
                </div>
              </NavLink>
            );
          })}
        </div>
      );
    }

    if (!this.props.item.subHeadings) {
      return (
        <>
          <NavLink
            className="sideoptionLink"
            activeClassName="activeSideoptionLink"
            to={`/${this.props.item.redirect}`}
          >
            <div className="sidebarOption">
              <img className="icon" src={this.props.item.icon} alt="moess side icons" />
              <img className="activeIcon" src={this.props.item.selectedIcon} alt="moess side icons"/>
              <h6 size='normal' className="heading">{this.props.item.heading}</h6>
              {this.props.item.subHeadings ? (
                <span className="arrow">{`>`}</span>
              ) : null}
            </div>
          </NavLink>
        </>
      );
    }

    return (
      <>
        <div className="sidebarOption" onClick={this.toggle}>
          <img src={this.props.item.icon}  alt="moess side icons"/>
          <h6 className="heading">
            {this.props.item.heading}
          </h6>
          {this.props.item.subHeadings ? (
            <span className="arrow">{`>`}</span>
          ) : null}
        </div>

        {subOptions}
      </>
    );
  }
}

export default SideBarOption;