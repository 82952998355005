import React from 'react';
import AddStudentOverlay from '../AddStudentOverlay/AddStudentOverlay';
import styles from "./Background.module.css";

const Background = (props) => {

  const closeOverlay = () => {
    if(props.closeOverlay !== false){
      document.getElementById("overlaybackground").style.display = "none";
    }
  }

  let overlayContent = null;
  if(props.addStudent){
    overlayContent = <AddStudentOverlay closeOverlay={closeOverlay}/>
  }

  return (
    <div className={styles.overlayBackground} id="overlaybackground" onClick={closeOverlay}>
      { overlayContent }    
    </div>
  );
};

export default Background;