import React, { useState,  } from "react";
import { Button,Input,Spin,Modal, notification} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import styles from "./AdminAccount.module.css";
import LockIcon from "../../../../assets/LockIcon.svg";
import { useHistory } from "react-router";
import ServerService from "../../../../API/ServerService"
import {
    SmileOutlined,
    FrownOutlined,
  } from "@ant-design/icons";

const AdminDetails = ({collegeData:collegeData}) => {

  
    const history = useHistory()
    const [loading,setLoading] = useState(false);
    const [visible,setVisible] = useState(false);
    const [password,setPassword] = useState({oldPassword:null, newPassword:null, confirmPassword:null});

    const showModal = () => {
        setVisible(true);
    };
    const openNotification = (title, desc, icon, duration) => {
        notification.open({
          message: title,
          description: desc,
          icon: icon,
          duration: duration,
        });
      };
    

    // sumbit change password handler
    const handleOk = () => {
        setLoading(true);
        const data ={password:password.oldPassword, new_password:password.newPassword}

        if(password.newPassword !== password.confirmPassword){
            setLoading(false);
            // setVisible(false);
            openNotification(
                "Error",
                "Oops, confirm and new password dont match!",
                <FrownOutlined style={{ color: "red" }} />,
              ); 
        }
        else{
            ServerService.ResetPassword(data)
            .then((res) => {
                // console.log(res);
                openNotification(
                    "Success",
                    "Password changed successfully!",
                    <SmileOutlined style={{ color: "#108ee9" }} />,
                  );
                setLoading(false);
                setVisible(false);

            })
            .catch((err) => {
                // console.log(err);
                openNotification(
                    "Error",
                    "Old password dont match!",
                    <FrownOutlined style={{ color: "red" }} />,
                  ); 
                setLoading(false);
                setVisible(false);
            })
        }
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const PasswordHandler = (type,event)=> {
        if(type ==="old"){
            setPassword(prevState=>({...prevState,oldPassword:event.target.value}))
        }
        else if(type ==="new"){
            setPassword(prevState=>({...prevState,newPassword:event.target.value}))
        }
        else if(type ==="confirm"){
            setPassword(prevState=>({...prevState,confirmPassword:event.target.value}))
        }
    }

    
  return(

    

      <div className={styles.institutionData}>
        {!collegeData ? <div style={{textAlign:"center"}}><Spin size="large" /></div> : (
        <div>
            <div className={styles.firstRow}>
                    <div>
                        <h6 className={styles.dataLabel}>Institution Name</h6>
                        <h6 className={styles.dataValue}>{collegeData.institutionName ? collegeData.institutionName : "___"}</h6>
                    </div>
                    <div  onClick={showModal} className={styles.Resetpassword}>
                        <img src={LockIcon} alt="moess forget password" />
                        <h6>CHANGE PASSWORD</h6>
                    </div>
            </div>
            {/* <h6 className={styles.dataLabel}>Email Address</h6> */}
            {/* <h6 className={styles.dataValue}>{collegeData ? collegeData.email : "___"}</h6> */}
            <h6 className={styles.dataLabel}>Phone Number</h6>
            <h6 className={styles.dataValue}>
            {collegeData.contactNumber ? collegeData.contactNumber : "___"}
            </h6>

       
            {/* <div className={styles.editDetails}>Edit Details</div> */}
         
            

            <h6 className={styles.dataLabel}>Affilation Code</h6>
            <h6 className={styles.dataValue}>{collegeData.affiliationCode ? collegeData.affiliationCode : "___"}</h6>

            <h6 className={styles.dataLabel}>Affilated To </h6>
            <h6 className={styles.dataValue}>{collegeData.affiliatedTo ? collegeData.affiliatedTo : "___"}</h6>

            <h6 className={styles.dataLabel}>Established in</h6>
            <h6 className={styles.dataValue}>{collegeData.establishedIn ? collegeData.establishedIn : "___"}</h6>

            <h6 className={styles.dataLabel}>About</h6>
            <h6 className={styles.dataValue}>{collegeData.about ? collegeData.about : "___"}</h6>

            <h6 className={styles.dataLabel}>Address</h6>
            <h6 className={styles.dataValue}>{collegeData.address ? collegeData.address : "___"}</h6>

            <h6 className={styles.dataLabel}>Institution Email</h6>
            <h6 className={styles.dataValue}>{collegeData.email ? collegeData.email : "___"}</h6>

            <h3 style={{color:'grey',textAlign:"center",textWeight:"normal"}}>Settings</h3>
            {/* <hr style={{color:'lightgrey',opacity:'0.6'}} / > */}
            {/* <br/> */}
            <div className={styles.setting}>
                <h6 className={styles.dataLabel}>Enrollment id prefix</h6>
                <h6 className={styles.dataValue}>{collegeData.enrollment_id_prefix ? collegeData.enrollment_id_prefix : "___"}</h6>

                <h6 className={styles.dataLabel}>Enrollment id digit length</h6>
                <h6 className={styles.dataValue}>{collegeData.enrollment_id_digit_length ? collegeData.enrollment_id_digit_length : "___"}</h6>

                <h6 className={styles.dataLabel}>Receipt id prefix</h6>
                <h6 className={styles.dataValue}>{collegeData.receipt_id_prefix ? collegeData.receipt_id_prefix : "___"}</h6>

                <h6 className={styles.dataLabel}>Receipt start prefix</h6>
                <h6 className={styles.dataValue}>{collegeData.receipt_start_digit ? collegeData.receipt_start_digit : "___"}</h6>
            </div>
            <div onClick={() => history.push('viewAccount/edit')} className={styles.editDetails}>Edit Details</div> 

        </div>


        )}
            <Modal title="Password Reset"    
                   visible={visible}
                   onOk={handleOk}
                   onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                        Return
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                        Reset Password
                        </Button>
                            ]}
            >
                <div className={styles.password}>
                    <h6 className={styles.passwordLabel}>Enter old Password</h6>
                    <Input.Password 
                        className={styles.passwordInput} 
                        type="password"
                        onChange={(event)=>{PasswordHandler("old",event)}} 
                        // style={{maxWidth:'100vw'}}
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}/>

                        <h6 className={styles.passwordLabel}>Enter new Password</h6>

                        <Input.Password 
                        className={styles.passwordInput} 
                        type="password" 
                        onChange={(event)=>{PasswordHandler("new",event)}} 
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}/>

                        <h6 className={styles.passwordLabel}>Confirm new Password</h6>

                        <Input.Password 
                        className={styles.passwordInput} 
                        type="password" 
                        onChange={(event)=>{PasswordHandler("confirm",event)}} 
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}/>

                    {/* <Button style={{color:"white",background:"#09DCA8"}}>Reset</Button> */}
                </div>
            </Modal>

      </div>

  );
};

export default AdminDetails;
