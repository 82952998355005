import React from "react";
import styles from "./FormCreation.module.css";
import Skeleton from "react-loading-skeleton";

const LoadingData = () => {
  return (
    <div className={styles.displayArea}>
      <div style={{ width:'800px' }} className={styles.formCreation}>
        <div className={styles.formHeader}>
          <Skeleton width={300} />
        </div>

        <div
          style={{
            border: "1.2px solid #ccc",
            borderRadius: "8px",
            padding: "19px 16px",
            margin: "0px 24px",
            marginBottom: "28px",
          }}
        >
          <Skeleton height={12} width={500} />
          <Skeleton height={12} width={600} />
          <Skeleton height={12} width={700} />
          <Skeleton height={10} width={600} />
          <Skeleton height={10} width={400} />
        </div>
        <div
          style={{
            border: "1.2px solid #ccc",
            borderRadius: "8px",
            padding: "19px 16px",
            margin: "0px 24px",
            marginBottom: "28px",
          }}
        >
          <Skeleton height={12} width={500} />
          <Skeleton height={12} width={600} />
          <Skeleton height={12} width={700} />
          <Skeleton height={10} width={600} />
          <Skeleton height={10} width={400} />
        </div>

        <div className={styles.footer} style={{ marginTop:'40px' }}>
          <Skeleton height={30} width={160} />
          <Skeleton height={30} width={160} />
        </div>
      </div>
    </div>
  );
};

export default LoadingData;
