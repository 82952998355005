import React from "react";
// import { useSelector } from "react-redux";
import AdminAccount from "./AccountType/AdminAccount/AdminAccount";

const ViewAccount = () => {
  // const UserType = useSelector((state) => state.auth.user.role);

  return <AdminAccount />;
};

export default ViewAccount;
