import React, { useState, useEffect } from "react";
// import dummyData from "./dummyData";
import { Breadcrumb} from 'antd';
// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import styles from "./AdminAccount.module.css";
// import LockIcon from "../../../../assets/LockIcon.svg";
import AddIcon from "../../../../assets/AddIcon.svg";
import { useHistory } from "react-router";
import {Link} from 'react-router-dom'
import ServerService from "../../../../API/ServerService";
import AdminDetails from './Detail';
import { useSelector } from "react-redux";

const AdminAccount = () => {

  const [staffData, setStaffData] = useState(null);
  const [collegeData, setCollegeData] = useState(null);
  const history = useHistory();
  const UserType = useSelector((state) => {
    if(state.auth.user){
      return state.auth.user.role
    }
  });

  useEffect(() => {

    ServerService.GetStaffList()
      .then((res) => {
        console.log(res);
        setStaffData(res.data.adminDetails.users);
        setCollegeData(res.data.adminDetails.institutionDetails);
      })
      .catch((err) => {
        console.log(err);
      })

  },[])

  let url = window.location.href;
  url = url.split('/');
  url.splice(2,1,"Dashboard")

  console.log(staffData)
  return (
    <div className={styles.displayArea}>
      <h6 className={styles.heading}>Account Details</h6>
      <h6 className={styles.subHeading}>{UserType==="admin" ? "ADMIN ACCOUNT" : "USER ACCOUNT"}</h6>

      <Breadcrumb style={{marginBottom:"24px"}}>
        {url.slice(2).map((entry,index)=> {
          return<Breadcrumb.Item key={index}>
                  <Link style={{textDecoration:'none'}} to={`${entry}`}>
                    {entry}
                  </Link>
                </Breadcrumb.Item>}
        )}
      </Breadcrumb>
      {UserType === "admin" ?  <div className={styles.accountData}>
        <h6 className={styles.dataLabel}>Added Users</h6>
        <div className={styles.accountsDisplay}>
          {staffData && staffData.map((user) => {
            return (
              <div className={styles.userBlock}>
                <div className={styles.userIcon}>
                  <i class="fas fa-user"></i>
                </div>

                <div className={styles.userData}>
                  <h6 className={styles.userName}>{user ? user.fullName : "___"}</h6>
                  <h6 className={styles.userDesig}>{user ? user.designation : "___"}</h6>
                  <h6 className={styles.userEmail}>
                    {user && user.email.length > 20
                      ? `${user.email.slice(0, 20)}...`
                      : user.email}
                  </h6>
                </div>

                <div onClick={()=>history.push(`viewAccount/profile/${user ? user.id : null}`)} className={styles.arrowIcon}>
                  <i class="fas fa-chevron-right" />
                </div>
              </div>
            );
          })}
          <div onClick={() => history.push('/add-user')} className={styles.addUser}>
            <span>Add User</span>
            <img src={AddIcon} alt="moess more users "/>
          </div>
        </div>
      </div> : null}
     

     <AdminDetails collegeData={collegeData}/>


    </div>
  );
};

export default AdminAccount;
