import React, { useState, useEffect } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { Select, Button, message, notification } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import dummyData from "./dummyTableData";
import AllColumns from "./TableColumns";
import Table from "../../../../components/Table/Table";
import SearchIcon from "../../../../assets/SearchIcon.svg";
import styles from "./ReceiptTable.module.css";
import { SetViewReceipts } from "../../../../redux/actions/actions";
import { useDispatch } from "react-redux";
import ServerService from "../../../../API/ServerService";

const ReceiptTable = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { studentId } = useParams();

  const [selectedRows, setSelectedRows] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [displayNumItems, setDisplayNumItems] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([
    {
      title: 'Receipt ID',
      dataIndex: ['recieptId','studentId','id'],
      key: 'recieptId',
      width: 20,
      render: (text, row) => {
        return (
          <NavLink style={{ color: 'black' }} to={`/studentDetails/${row['studentId']}/view-receipt/${row['id']}`}>
            <div>{row['recieptId']}</div>
          </NavLink>
        )
      }
    },
    {
      title: 'Amount Paid',
      dataIndex: 'amount_paid',
      key: 'amount_paid',
      width: 20,
      ellipsis: {
        showTitle: false,
      },
      render: (text, row) => {
        return (
          <div>{`₹ ${row['amount_paid']}`}</div>
        )
      }
    },
    {
      title: 'Pending Amount',
      dataIndex: 'due_amount',
      key: 'due_amount',
      width: 20,
      render: (text, row) => {
        return (
          <div>{`₹ ${text}`}</div>
        )
      }
    },
    {
      title: 'Submitted At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 20,
      render: (text, row) => {
        return (
          <div>{`${row['createdAt']}`}</div>
        )
      }
    },
    {
      title: 'Submitted By',
      dataIndex: 'submitted_by',
      key: 'submitted_by',
      width: 20,
    },


  ]);

  useEffect(() => {
    // API call
    // ServerService.GetStudentReceipts(props.studentId)
    //   .then((res) => {
    //     console.log(res);
    //     setTableData(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    setTableData(props.report.tableData);
  }, []);

  const selectedRowChange = (row) => {
    // console.log(row);
    setSelectedRows([...row.selectedRows]);
  };

  // const viewReceipts = (type) => {
  //   let key = "updatable";
  //   //loading msg
  //   message.loading({ content: "Action in progress...", key });
  //   // message.success({ content: "Action completed!", key, duration: 2 });
  //   // message.error({ content: "Something went wrong!", key, duration: 2 });

  //   if (type === "selected") {
  //     // API call for data
  //     const reqBody = {
  //       studentId: props.studentId,
  //       selectedReceipts: selectedRows,
  //     };

  //     // dispatch(SetViewReceipts(selectedRows));
  //     history.push(
  //       `/studentDetails/${studentId}/view-receipt/${props.studentId}`
  //     );
  //   } else if (type === "all") {
  //     // API call for data
  //     const reqBody = {
  //       studentId: props.studentId,
  //     };

  //     // dispatch(SetViewReceipts(selectedRows));
  //     history.push(
  //       `/studentDetails/${studentId}/view-receipt/${props.studentId}`
  //     );
  //   }
  // };

  return (
    <>
      <div className={styles.receiptTableBody}>
        <div className={styles.tableTop}>
          <h6 className={styles.tableTopHeading}>{props.year}</h6>
          {tableData.length === 0 ? null : (
            <div>
              {/* <Button
                onClick={() => viewReceipts("selected")}
                style={{ marginRight: "14px" }}
              >
                View Selected
                <EyeOutlined style={{ marginLeft: "6px" }} />
              </Button> */}

              <Button
                // onClick={() => viewReceipts("all")}
                onClick={() => history.push(`/studentDetails/${studentId}/view-report/${props.span_name}`)}
                style={{ marginRight: "14px" }}
              >
                View Report
                <EyeOutlined style={{ marginLeft: "6px" }} />
              </Button>
              <Button
                // onClick={() => viewReceipts("all")}
                onClick={() => history.push(`/studentDetails/${studentId}/view-receipt/all`)}
                style={{ marginRight: "14px" }}
              >
                View All Reciepts
                <EyeOutlined style={{ marginLeft: "6px" }} />
              </Button>
            </div>
          )}
        </div>

        <Table
          currentPage={currPage}
          columns={columns}
          data={tableData}
          totalNum={totalItems}
          displayNum={displayNumItems}
          totalPages={totalPages}
          selectedRowChange={selectedRowChange}
          noPagination={true}
          scrollX="60vw"
        />
        <br />
        <div className={styles.tableBottom}>
          <h3>Pending Amount: <span style={props.report.totalDueAmount<=0?{color: "green"}:{ color: "red" }}>₹ {props.report.totalDueAmount}</span></h3>
          <h3>Payable Amount: ₹ {props.report.totalPayableAmount}</h3>
          <h3>Paid Amount ₹ {props.report.totalPaidAmount}</h3>
        </div>
      </div>
    </>
  );
};

export default ReceiptTable;
