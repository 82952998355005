import React, { Component } from "react";
import "../AddUser/AddUser.css";
import { Form, Input, Button, Breadcrumb, message, notification, InputNumber} from "antd";
import {
  SmileOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import {Link,Redirect} from 'react-router-dom';
import ServerService from '../../API/ServerService'

class AddAgent extends Component {
  state = {
    imageUrl: null,
    selectedImg: null,
    redirect:null,
  };


  openNotification = (title, description, icon, duration) => {
    notification.open({
      message: title,
      description: description,
      icon: icon,
      duration: duration,
    });
  };

  handleUpload = (event) => {
    if (event.target.value.length > 0) {
      this.setState({
        imageUrl: URL.createObjectURL(event.target.files[0]),
        selectedImg: event.target.files[0],
      });
    }
  };

  submitHandler = (values) => {
    let key = "updatable";

    //loading msg
    message.loading({ content: "Action in progress...", key });

    
    const reqBody = {
      name: values.name,
      phone_number: values.phone,
      commission: values.commission,
      address: values.address,

    };
    // console.log(reqBody);

    ServerService.AddAgent(reqBody)
    .then(res=>{
      // console.log(res)
      this.setState({redirect:"true"})
       message.success({ content: "Action completed!", key, duration: 2 });
    this.openNotification(
      "Agent created successfully!",
      "Creation successful! ",
      <SmileOutlined style={{ color: "#108ee9" }} />,
      4.5
    );
    }).catch(err=>{
      // console.log(err)
      message.error({ content: "Something went wrong!", key, duration: 2 });
    this.openNotification(
      "Something went wrong!",
      "We are unable to process your request right now! Please try again after sometime.",
      <ExclamationCircleOutlined style={{ color: "red" }} />,
      4.5
    );
    })

  };

  render() {

    const url = [ {name: "DashBoard",link:"/AdminDashboard"}, 
    {name:"View Agent",link:"/viewAgent"}, 
    {name:"Add Agent",link: `/viewAgent/AddAgent`}]

    if(this.state.redirect){
        return <Redirect to="/viewAgent"/>
    }
    return (
      <div className="displayArea">
       <Breadcrumb style={{ marginBottom:"24px"}}>
                    {url.map((entry,index)=> {
                    return<Breadcrumb.Item key={index}>
                            <Link style={{textDecoration:'none'}} to={`${entry.link}`}>
                                {entry.name}
                            </Link>
                            </Breadcrumb.Item>}
                    )}
        </Breadcrumb>    
        <div className="addUserForm">
          <div className="userAddHeader">
            <div className="arrowIcon">
              <Link style={{textDecoration:"none", color:"#808080"}} to="/viewAgent">
                <i class="fas fa-chevron-left" />
              </Link>
            </div>
            <h6>Add Agent</h6>
          </div>

          <Form
            name="add_user"
            initialValues={{ remember: true }}
            onFinish={this.submitHandler}
          >
            

            {/* USER'S NAME */}
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter agent's name!",
                },
              ]}
            >
              <Input
                style={{
                  backgroundColor: "#EEF0F4",
                  borderRadius: "8px",
                }}
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Enter agent's name"
              />
            </Form.Item>


            {/* USER PHONE-NUMBER */}
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please enter valid phone number!",
                  pattern: new RegExp(/^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[6789]\d{9}|(\d[ -]?){10}\d$/)
                },
              ]}
            >
              <Input
                style={{
                  backgroundColor: "#EEF0F4",
                  borderRadius: "8px",
                }}
                prefix={<PhoneOutlined />}
                placeholder="Enter Phone Number"
              />
            </Form.Item>

            <Form.Item
              name="commission"
              initialValue={0}
              // rules={[
              //   {
              //     type:"number",
              //     required: true,
              //     message: "Please input commission!",
              //   },
              // ]}
            >
              <InputNumber
                style={{
                  backgroundColor: "#EEF0F4",
                  borderRadius: "8px",
                  width:"100%",
                }}
                prefix={<UserOutlined />}
                placeholder="Enter Commission"
              />
            </Form.Item>

            <Form.Item
              name="address"
              rules={[
                {
                  required: false,
                //   message: "Please enter agent's name!",
                },
              ]}
            >
              <Input
                style={{
                  backgroundColor: "#EEF0F4",
                  borderRadius: "8px",
                }}
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Enter agent's address"
              />
            </Form.Item>


            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Create Agent
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default AddAgent;
