import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./StudentDetails.module.css";

const LoadingScreen = () => {
  return (
    <div className={styles.displayArea}>
      <div className={styles.studentDetails}>
        <div className={styles.header}>
          <Skeleton width={200} />
        </div>

        <div className={styles.studentData}>
          <div className={styles.dataDisplay}>
            <Skeleton className={styles.userImg} />
            <div className={styles.studentDataDisplay}>
              <div className={styles.box}>
                <Skeleton width={90} />
                <Skeleton className={styles.value} />
                <Skeleton width={90} />
                <Skeleton className={styles.value} />
                <Skeleton width={90} />
                <Skeleton className={styles.value} />
                <Skeleton width={90} />
                <Skeleton className={styles.value} />
              </div>
              <div className={styles.box}>
                <Skeleton width={90} />
                <Skeleton className={styles.value} />
                <Skeleton width={90} />
                <Skeleton className={styles.value} />
                <Skeleton width={90} />
                <Skeleton className={styles.value} />
              </div>
              <div className={styles.box}>
                <Skeleton width={90} />
                <Skeleton width={130} />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.extraDetails}>
          <div>
            <Skeleton width={70}/><br/>
            <Skeleton width={100}/>
          </div>
          <div>
            <Skeleton width={70}/><br/>
            <Skeleton width={100}/>
          </div>
          <div>
            <Skeleton width={70}/><br/>
            <Skeleton width={100}/>
          </div>
          <div>
            <Skeleton width={70}/><br/>
            <Skeleton width={100}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
