import React, { useEffect, useState } from "react";
import styles from "./StudentDetails.module.css";
import DefaultProfilePicBoy from "../../../assets/default-boy.png";
import DefaultProfilePicGirl from "../../../assets/default-avatar-girl.png";
import CheckIcon from "../../../assets/CheckIcon.svg";
import LoadingScreen from "./LoadingScreen";
import ReceiptTable from "./ReceiptTable/ReceiptTable";
import {
  FormOutlined,
  PrinterOutlined,
  DeleteOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,

} from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useHistory } from "react-router-dom";
import ServerService from "../../../API/ServerService";

const { confirm } = Modal;

const StudentDetails = React.forwardRef((props, ref) => {
  const history = useHistory();
  const [data, setData] = useState(null);

  useEffect(() => {
    ServerService.GetStudentDetails(props.studentId)
      .then((res) => {
        // console.log(res);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getGender = () => {
    if (data.gender === "M") {
      return "Male";
    } else {
      return "Female";
    }
  };

  // /DeleteStudentData
  const DeleteStudentData = (id) => {
    confirm({
      title: 'Are you sure to delete student data?',
      icon: <ExclamationCircleOutlined />,
      content: 'This process cannot be revert back data related with this student will be lost.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        ServerService.DeleteStudentData(id)
          .then((res) => {
            // console.log(res);
            // setData(res.data);
            history.push("/view-students/all");
          })
          .catch((err) => {
            console.log(err);
          });
      },
      onCancel() {
        // console.log('Cancel');
      },
    });

  }

  const getFeeStatus = () => {
    // if (data.due_amount === 0 || data.due_amount === undefined) {
    //   return (
    //     <>
    //       <h6 className={styles.label}>Fee Status</h6>
    //       <h6 className={`${styles.feeStatus} ${styles.paid}`}>
    //         <img src={CheckIcon} />
    //         <span>Paid</span>
    //       </h6>
    //     </>
    //   );
    // }
    return (
      <>
        <h6 className={styles.label}>Fee Status</h6>
        {data.due_amount === 0 || data.due_amount === undefined ? (
          <>
            <h6 className={`${styles.feeStatus} ${styles.paid}`}>
              <img src={CheckIcon} />
              <span>Paid</span>
            </h6>
          </>
        ) : (
          <>
            <h6 className={`${styles.feeStatus} ${styles.unpaid}`}>
              <span>Unpaid</span>
            </h6>
            <h6 className={styles.label}>Total Pending Amount</h6>
            <h6 className={styles.dueAmount}>₹{data.due_amount}</h6>
          </>
        )}
      </>
    );
  };

  const getAdditionalFields = () => {
    let fields = [];
    for (let property in data.details) {
      fields.push(
        <div>
          <h6 className={styles.label}>{property}</h6>
          <h6 className={styles.value}>{data.details[property]}</h6>
        </div>
      );
    }
    return fields;
  };

  const getReportData = () => {
    let fields = [];
    const reportData = data.fee_report.report;
    for (const key in reportData) {
      fields.push(
        <ReceiptTable report={reportData[key]}
          year={key}
          span_name={reportData[key].span_name}
        />
      );
    }
    return fields;
  };

  if (data === null) {
    return <LoadingScreen />;
  }

  return (
    <div ref={ref} className={styles.displayArea}>
      <div className={styles.studentDetails}>
        <div className={styles.header}>
          <h6>Student Report</h6>
          <div className={styles.iconsDisplay}>
            <div className={`${styles.icons} ${styles.deleteIcon}`}
              onClick={() =>
                DeleteStudentData(props.studentId)
              }
            >
              <DeleteOutlined
                // onClick={() => DeleteStudentData(props.studentId)}
                twoToneColor="red"
                style={{
                  color: "red",
                  fontSize: "17.2px",
                  paddingBottom: "0px",
                  marginRight: "0px",
                }}
              />
            </div>
            <div
              onClick={() =>
                history.push(`/studentDetails/${props.studentId}/fee-status`)
              }
              className={styles.icons}
            >
              <span>Submit Fee</span>
              <FormOutlined
                style={{ paddingBottom: "0px", marginRight: "0px" }}
              />
            </div>
            <div
              onClick={() => history.push(`/edit-student/${props.studentId}`)}
              className={styles.icons}
            >
              <span>Edit</span>
              <FormOutlined
                style={{ paddingBottom: "0px", marginRight: "0px" }}
              />
            </div>
          </div>
        </div>

        <div className={styles.studentData}>
          <div className={styles.dataDisplay}>
            <img
              className={styles.userImg}
              src={data.image ? data.image : (data.gender === 'M' ? DefaultProfilePicBoy : DefaultProfilePicGirl)}
            />
            <div className={styles.studentDataDisplay}>
              <div className={styles.box}>
                <h6 className={styles.label}>Name</h6>
                <h6 className={styles.value}>{`${data.full_name}`}</h6>
                <h6 className={styles.label}>Enrollment No</h6>
                <h6 className={styles.value}>{data.enrollment_id}</h6>
                <h6 className={styles.label}>Father's Name</h6>
                <h6 className={styles.value}>{data.father_name}</h6>
                <h6 className={styles.label}>Mother's Name</h6>
                <h6 className={styles.value}>{data.mother_name}</h6>
              </div>
              <div className={styles.box}>
                <h6 className={styles.label}>Course</h6>
                <h6 className={styles.value}>{data.course}</h6>
                <h6 className={styles.label}>Branch</h6>
                <h6 className={styles.value}>{data.branch}</h6>
                <h6 className={styles.label}>Batch</h6>
                <h6 className={styles.value}>{data.batch}</h6>
                <h6 className={styles.label}>Date of Birth</h6>
                <h6 className={styles.value}>{data.date_of_birth}</h6>
              </div>
              <div className={styles.box}>
                {getFeeStatus()}
                <h6 className={styles.label}>Year</h6>
                <h6 className={styles.value}>{data.passing_span}</h6>
                <h6 className={styles.label}>Registration Date</h6>
                <h6 className={styles.value}>{data.created_at}</h6>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.extraDetails}>
          <div>
            <h6 className={styles.label}>Gender</h6>
            <h6 className={styles.value}>{getGender()}</h6>
          </div>
          <div>
            <h6 className={styles.label}>Category</h6>
            <h6 className={styles.value}>{data.category}</h6>
          </div>
          {getAdditionalFields()}
        </div>
      </div>
      <div className=""><br /></div>
      <div className={styles.studentDetails}>
        <div className={styles.header}>
          <h6>Fee Report</h6>

          <Button

            // onClick={() => viewReceipts("all")}
            onClick={() =>
              history.push(`/studentDetails/${props.studentId}/fee-status`)
            }
            style={{ marginRight: "14px" }}
          >
            Add Installment
          </Button>
          {data.fee_report.submissionExists ? <Button

            // onClick={() => viewReceipts("all")}
            onClick={() => history.push(`/studentDetails/${props.studentId}/view-report/all`)}
            style={{ marginRight: "14px" }}
          >
            View complete report
            <EyeOutlined style={{ marginLeft: "6px" }} />
          </Button> : null}
        </div>
        {getReportData()}
        <div className={styles.footer}>
          <h3>Total Pending Amount:  <span style={data.fee_report.totalDueAmount <= 0 ? {} : { color: "red" }}>₹ {data.fee_report.totalDueAmount}</span></h3>
          <h3>Total Payable Amount: ₹ {data.fee_report.totalPayableAmount}</h3>
          <h3>Total Paid Amount ₹ {data.fee_report.totalPaidAmount}</h3>
        </div>

      </div>

      {/* <ReceiptTable studentId={props.studentId} /> */}
    </div>
  );
});

export default StudentDetails;
