import React from "react";
import { useHistory } from "react-router";
import styles from "./CourseCard.module.css";
import { EditFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";

const CourseCard = (props) => {
  const history = useHistory();
  const colors = ["#66CDAA", "#CD8282", "#82A8CD", "#999999"];
  const UserType = useSelector((state) => {
    if (state.auth.user) {
      return state.auth.user.role;
    }
  });

  const editHandler = () => {
    if (props.viewCourse) {
      // history.push(`/branches/${props.data.id}`);
      history.push({
        pathname: `/addCourse`,
        state: {
          name: props.data.course_name,
          code: props.data.code,
          id: props.data.id,
          duration: props.data.duration.split(' ')[0]/365,
          course_duration: props.data.course_duration,
          length_fee_timespan: props.data.length_fee_timespan,
          length_passing_timespan: props.data.length_passing_timespan,
        },
      });
    } else if (props.viewStudents) {
      history.push(`/view-students/course/${props.data.id}`);
    } else if (props.createForm) {
      if (props.formCreated) {
        history.push(`/form/edit-form/${props.data.id}`);
      } else {
        history.push(`/form/create-form/${props.data.id}`);
      }
    }
  };

  const handleRedirection = () => {
    if (props.viewCourse) {
      history.push(`/branches/${props.data.id}`);
    } else if (props.viewStudents) {
      history.push(`/view-students/course/${props.data.id}`);
    } else if (props.createForm) {
      if (props.formCreated) {
        history.push(`/form/edit-form/${props.data.id}`);
      } else {
        history.push(`/form/create-form/${props.data.id}`);
      }
    }
  };

  return (
    <div
      style={{ borderColor: `${colors[props.index % 4]}` }}
      className={styles.courseCard}
    >
      <div
        style={{ backgroundColor: `${colors[props.index % 4]}` }}
        className={styles.cardIcon}
      >
        <i class="fas fa-graduation-cap" />
      </div>

      <div className={styles.cardContent}>
        <h6 className={styles.courseName}>{props.data.course_name}</h6>
        <h6 className={styles.courseData}>
            {props.data.course_duration} Academic {props.data.span_label}
        </h6>
        <h6 className={styles.courseData}>
        {props.data.numOfBranches} { props.data.numOfBranches>1 ? "Branches":"Branch"}
        </h6>
        <h6>
          <span className={styles.courseData}>
            {props.data.numOfStudents} Students
          </span>
        </h6>
      </div>

      <div onClick={handleRedirection} className={styles.arrowIcon}>
        <i class="fas fa-chevron-right" />
      </div>

      {UserType === "admin" ? (
        props.viewStudents ? null : (
          <span onClick={editHandler} className={styles.editButton}>
            <EditFilled />
          </span>
        )
      ) : null}
    </div>
  );
};

export default CourseCard;
