import React, { useState } from "react";
import { UpOutlined, DownOutlined, DeleteTwoTone } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { DeleteStudentFromPromotionList } from "../../../../../redux/actions/actions";
import MaleIcon from "../../../../../assets/MaleUser.svg";
import FemaleIcon from "../../../../../assets/FemailUser.svg";
import styles from "./SelectedSideWindow.module.css";

const SlectedSideWindow = () => {
  const dispatch = useDispatch();

  const selectedStudentsList = useSelector(
    (state) => state.promote_students.selectedStudents
  );

  const [expand, setExpand] = useState(true);

  const handleDelete = (id) => {
    dispatch(DeleteStudentFromPromotionList(id));
  };

  return (
    <div className={styles.sideWindow}>
      <div className={styles.listHeader}>
        <h6>Students not being promoted</h6>
        <div onClick={() => setExpand(!expand)} className={styles.arrow}>
          {expand ? <DownOutlined /> : <UpOutlined />}
        </div>
      </div>

      {expand ? (
        <div className={styles.list} style={{ overflowY: "scroll" }}>
          {selectedStudentsList.map((student) => {
            let genderPic = FemaleIcon;
            if (student.gender === "M") {
              genderPic = MaleIcon;
            }

            return (
              <div className={styles.studentBlock}>
                <img src={genderPic} />
                <div className={styles.studentData}>
                  <h6 className={styles.studentName}>{`${student.first_name} ${student.last_name}`}</h6>
                  <h6 className={styles.studentId}>{student.enrollment_id}</h6>
                </div>
                <div
                  onClick={() => handleDelete(student.id)}
                  className={styles.deleteDiv}
                >
                  <DeleteTwoTone twoToneColor="red" />
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default SlectedSideWindow;
