import { useEffect, useState } from "react";
import {
    Form,
    Input,
    Button,
    message,
    Breadcrumb,
    Popconfirm

} from "antd";
import {
    PlusOutlined,
    UserOutlined,
    FormOutlined,
    DeleteOutlined
} from "@ant-design/icons";
import ServerService from "../../../API/ServerService";
import { useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";

const { TextArea } = Input;

const EditFaculty = () => {
    const [img, setImg] = useState("");
    const [imageUrl, setImageUrl] = useState(null);
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { id } = useParams();
    const location = useLocation();

    console.log(location);

    const url = [{ name: "Dashboard", link: "/AdminDashboard" }, { name: "Faculty", link: "/view-faculty" }, { name: location.state.name, link: location.pathname }]

    useEffect(() => {
        ServerService.ViewFaculty(id).then(res => {
            // console.log(res.data.results[0])
            setImageUrl(res.data.results[0].photo)
            setDetails(res.data.results[0])
        }).catch(err => {
            console.log(err.response)
        })
    }, [])

    const handleUpload = (event) => {
        if (event.target.value.length > 0) {
            setImageUrl(URL.createObjectURL(event.target.files[0]));
            setImg(event.target.files[0]);
        }
    };


    const submitHandler = (values) => {
        setLoading(true);
        const data = {
            ...values,
            photo: img
        }
        // console.log(data);
        const fd = new FormData();

        for (let formElement in data) {
            fd.append(formElement, data[formElement]);
        }


        ServerService.EditFaculty(id, fd).then(res => {
            setLoading(false);
            message.success({ content: "Action completed!", duration: 2 });

            const timer = setTimeout(
                () => history.push("/view-faculty")
                ,
                1500
            );
            return () => clearTimeout(timer);
        }).catch(err => {
            message.error({ content: "Action failed!", duration: 2 });
            console.log(err.response)
        })
    }

    const handleDelete = () => {
        ServerService.DeleteFaculty(id).then(res => {
            console.log(res)
            message.success({ content: "Faculty member deleted successfully!", duration: 2 });

            const timer = setTimeout(
                () => history.push("/view-faculty")
                ,
                1500
            );
            return () => clearTimeout(timer);
        }).catch(err => {
            console.log(err.response)
        })
    }

    if (details)
        return (
            <>
                <div className="displayArea">
                    <Breadcrumb style={{ marginBottom: "24px" }}>
                        {url.map((entry, index) => {
                            return <Breadcrumb.Item key={index}>
                                <Link style={{ textDecoration: 'none' }} to={`${entry.link}`}>
                                    {entry.name}
                                </Link>
                            </Breadcrumb.Item>
                        }
                        )}
                    </Breadcrumb>
                    <div className="addStudentForm">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h6 className="formHeader" >Edit Faculty</h6>
                            <Popconfirm
                                title="Are you sure to delete this faculty member?"
                                onConfirm={handleDelete}
                                okText="Yes"
                                cancelText="No"
                                placement="leftTop"
                            >
                                <DeleteOutlined style={{ fontSize: "20px" }} />
                            </Popconfirm>
                        </div>
                        <Form
                            name="add_student"
                            initialValues={{ ...details }}
                            onFinish={submitHandler}
                        >
                            {/* FACULTY'S IMAGE */}

                            <input
                                type="file"
                                id="facultyImg"
                                style={{ display: "none" }}
                                accept="image/*"
                                onChange={handleUpload}
                            />
                            <label htmlFor="facultyImg">
                                {imageUrl === null ? (
                                    <div className="uploadButton">
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>Upload</div>
                                    </div>
                                ) : (
                                    <img className="uploadButton" src={imageUrl} />
                                )}
                            </label>


                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter the faculty's name!",
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    placeholder="Name"
                                    autoComplete="off"

                                />
                            </Form.Item>
                            <Form.Item
                                name="position"

                            >
                                <Input
                                    prefix={<FormOutlined className="site-form-item-icon" />}
                                    placeholder="Position"
                                    autoComplete="off"

                                />
                            </Form.Item>

                            <Form.Item
                                name="contact_no"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter the faculty's contact number!",
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<FormOutlined className="site-form-item-icon" />}
                                    placeholder="Contact No."
                                    autoComplete="off"
                                    type="number"

                                />

                            </Form.Item>

                            <Form.Item
                                name="address"

                            >
                                <Input
                                    prefix={<FormOutlined className="site-form-item-icon" />}
                                    placeholder="Address"
                                    autoComplete="off"

                                />
                            </Form.Item>

                            <Form.Item
                                name="qualifications"

                            >
                                <TextArea
                                    rows={4}
                                    placeholder="Qualifications"
                                    autoComplete="off"

                                />
                            </Form.Item>
                            <Form.Item
                                name="experience"

                            >
                                <Input
                                    prefix={<FormOutlined className="site-form-item-icon" />}
                                    placeholder="Experience"
                                    autoComplete="off"

                                />
                            </Form.Item>
                            <Form.Item
                                name="publications"

                            >
                                <Input
                                    prefix={<FormOutlined className="site-form-item-icon" />}
                                    placeholder="Publications"
                                    autoComplete="off"

                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="add-student-form-button"
                                    loading={loading}
                                >
                                    Save Changes
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </>
        )
    else
        return <h5> Loading </h5>
}

export default EditFaculty;