import React, { useEffect, useState } from "react";
import CourseCard from "../../../../components/Cards/CourseCard/CourseCard";
import LoadingData from "./LoadingData";
import ServerService from "../../../../API/ServerService";
import courseDummyData from "./courseDummyData";
import styles from "./RedirectionList.module.css";
import { EyeOutlined } from "@ant-design/icons";
import { useHistory,Link } from "react-router-dom";
import {Breadcrumb} from 'antd';

const CoursesList = (props) => {

  const history = useHistory();
  const [courseData, setCourseData] = useState(null);

  let url = window.location.href;
  url = url.split('/');
  url.splice(2,1,"Dashboard")

  useEffect(() => {
    ServerService.GetCourses()
      .then((res) => {
        // console.log(res);
        setCourseData(res.data);
        // setCourseData(courseDummyData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (courseData === null) {
    return <LoadingData />;
  }

  return (
    <div className={styles.displayArea}>
       <Breadcrumb style={{marginBottom:"24px"}}>
        {url.slice(2).map((entry,index)=> {
          return<Breadcrumb.Item key={index}>
                  <Link style={{textDecoration:'none'}} to={`${entry}`}>
                    {entry}
                  </Link>
                </Breadcrumb.Item>}
        )}
      </Breadcrumb>

      <h6
        onClick={() => history.push(`/view-students/all`)}
        className={styles.viewAllCourseButton}
      >
        View all students in institute
        <EyeOutlined style={{ marginLeft: "6px" }} twoToneColor="#52c41a" />
      </h6>

      <div className={styles.allCourses}>
        {courseData.map((course, index) => {
          return <CourseCard viewStudents={true} data={course} index={index} />;
        })}
      </div>
    </div>
  );
};

export default CoursesList;
