import { useEffect, useState } from "react"
import ServerService from "../../../API/ServerService"
import FacultyCard from "../../../components/Cards/FacultyCard/FacultyCard"
import LoadingData from "./LoadingData"
import styles from "./ViewFaculty.module.css"
import { Breadcrumb } from "antd"
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import { Link } from 'react-router-dom';




const ViewFaculty = () => {
    const [faculty, setFaculty] = useState([])

    const url = [{ name: "Dashboard", link: "/AdminDashboard" }, { name: "Faculty", link: "/view-faculty" }]

    const [currPage, setCurrPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [displayNumItems, setDisplayNumItems] = useState(9);
    const [totalItems, setTotalItems] = useState(0)

    useEffect(() => {
        ServerService.ViewAllFaculty(displayNumItems, currPage, 0).then(res => {
            // console.log(res)
            let totPages = parseInt(res.data.count / displayNumItems);
            if (res.data.count % displayNumItems != 0) {
                totPages += 1;
            }
            setTotalPages(totPages);
            setTotalItems(res.data.count);
            setFaculty(res.data.results)
        }).catch(err => {
            console.log(err.response)
        })
    }, [currPage, displayNumItems])

    // Pagination
    const handlePageChange = (e, currentPage) => {
        // console.log(currentPage)
        setCurrPage(currentPage);
    };

    let itemsData = (
        <>
            ({(currPage - 1) * displayNumItems + 1} -{" "}
            {(currPage - 1) * displayNumItems + displayNumItems})
        </>
    );
    if (currPage === totalPages) {
        itemsData = (
            <>
                ({(currPage - 1) * displayNumItems + 1} - {totalItems})
            </>
        );
    }

    let facultyList;
    if (faculty.length)
        facultyList = faculty.map((data, i) => {
            return <FacultyCard key={i} index={i} data={data} />
        })

    if (faculty.length)
        return (
            <>

                <div className={styles.displayArea}>
                    <Breadcrumb style={{ marginBottom: "24px" }} >
                        {url.map((entry, index) => {
                            return <Breadcrumb.Item key={index}>
                                <Link style={{ textDecoration: 'none' }} to={`${entry.link}`}>
                                    {entry.name}
                                </Link>
                            </Breadcrumb.Item>
                        }
                        )}
                    </Breadcrumb>
                    <div className={styles.allFaculty}>
                        {facultyList}
                    </div>

                    {totalPages > 1 ? (
                        <Grid
                            container
                            justify="space-between"
                        >
                            <span style={{ fontSize: "1rem", fontWeight: "400" }}>
                                Showing {itemsData} of {totalItems} results
                            </span>

                            <Pagination
                                onChange={handlePageChange}
                                count={totalPages}
                                size="small"
                                page={currPage}
                            />
                        </Grid>
                    ) : null}

                </div>

            </>
        )
    else return <LoadingData />
}

export default ViewFaculty;