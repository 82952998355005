import axios from 'axios';
import ServerService from './../ServerService';
import url from '../baseURL/url';
const instance = axios.create(
    {
        baseURL: "https://app.wiztex.in",
        // baseURL: "http://127.0.0.1:8000",
        // baseURL: "https://moces.in"
    }
);

instance.interceptors.response.use((response) => { 
    // console.log("basse url ")
    return response
 }, function (error) {

    const originalRequest = error.config;
    // console.log(originalRequest);
    
    if(error){
        if (error.response.status === 401 && originalRequest.url === url + "/auth/login-refresh/") {
            localStorage.clear()
            window.location.href = '/UserLogin'
            console.log("we will referesh")
            return Promise.reject(error);
        }
     
        if (error.response.status === 401 && !originalRequest._retry) {
     
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refreshToken');
            if(refreshToken){
                // console.log("calling reqqq")
                // return axios.post(url + 'auth/login-refresh/'
                // )
                // ServerService.GetAccessToken('/auth/login-refresh/',refreshToken)
                axios.post(url+"/auth/login-refresh/", { refresh:refreshToken }) 
                .then(res => {
                    
                        // console.log("sucess")
                        localStorage.setItem("refreshToken",res.data.refresh);
                        localStorage.setItem("accessToken",res.data.access);
                        originalRequest.headers["Authorization"] = 'Bearer ' + localStorage.getItem('accessToken');
                        return axios(originalRequest);
                    
                })
                .catch(err=>{
                    console.log(err.response);
                    localStorage.clear();
                    window.location.href = '/UserLogin'
                    console.log("we will referesh 33")

                })
            }
            else {
                console.log("No ref token was found")
                localStorage.clear();
                return window.location.href = '/UserLogin'
    
            }
            
        }
    }

   
    return Promise.reject(error);
 });


export default instance;
