import React, { useState, useEffect } from "react";
import { Menu, message, Button, DatePicker } from "antd";
import { Select, Input } from "antd";
import { DownOutlined, DownloadOutlined } from "@ant-design/icons";
import CustomTable from "../../components/Table/Table";
import AllColumns from "./TableColumns";
import SortList from "./SortingProp";
import styles from "./AllFeeStatus.module.css";
import LoadingScreen from "../../routes/Students/Promotion/LoadingScreen";
import ServerService from "../../API/ServerService";
import exportFromJSON from 'export-from-json'

const { Search } = Input;
const { Option } = Select;
const { SubMenu } = Menu;

const FeeStatus = () => {
  const [choiceData, setChoiceData] = useState(null);

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBranchId, setSelectedBranchId] = useState(null);

  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedSessionId, setSelectedSessionId] = useState(null);

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedYearId, setSelectedYearId] = useState(null);

  const [timeSpans, setTimeSpans] = useState([
    "All Time",
    "Today",
    "This Week",
    "This Month",
    "Custom",
  ]);
  const [selectedSpan, setSelectedSpan] = useState("All Time");

  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const [sortProp, setSortProp] = useState(SortList[0].value);
  const [sortValue, setSortValue] = useState("asc");
  const [currPage, setCurrPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [displayNumItems, setDisplayNumItems] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [columns, setColumns] = useState(AllColumns);
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState(null);
  const [total, setTotal] = useState(0);
  const [value, setValue] = useState('');

  useEffect(() => {
    // For getting branches, batches & years
    ServerService.GetFeeSelectionDropdown()
      .then((res) => {
        setChoiceData(res.data);
        setSelectedCourse(res.data[0]);
        setSelectedCourseId(res.data[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const reqBody = {
      courses: [],
      branches: [],
      batches: [],
      fee_spans: [],
      selected_span: selectedSpan,
      startDate: selectedSpan==="Custom"?selectedStartDate:null,
      endDate: selectedSpan==="Custom"?selectedEndDate:null,
      // order_by: sortProp,
      // order: sortValue,
    };

    // console.log(
    //   selectedCourseId,
    //   selectedBranchId,
    //   selectedSessionId,
    //   selectedYearId,
    //   "values"
    // );
    if (selectedCourseId) {
      reqBody.courses = [Number(selectedCourseId)];
    }
    if (selectedBranchId) {
      reqBody.branches = [Number(selectedBranchId)];
    }
    if (selectedSessionId) {
      reqBody.batches = [Number(selectedSessionId)];
    }
    if (selectedYearId) {
      reqBody.fee_spans = [Number(selectedYearId)];
    }


    // console.log(reqBody, "values");

    ServerService.GetFeeStatusTableData(displayNumItems, currPage, reqBody)
      .then((res) => {
        // console.log(res);
        let totPages = parseInt(res.data.count / displayNumItems);
        if (res.data.count % displayNumItems != 0) {
          totPages += 1;
        }
        setTotalPages(totPages);
        setTotalItems(res.data.count);
        setTableData(res.data.results);
        setFilteredData(res.data.results);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedCourseId,
    selectedBranchId,
    selectedSessionId,
    selectedYearId,
    selectedSpan,
    selectedStartDate,
    selectedEndDate,
    currPage,
    sortProp,
    sortValue,
    displayNumItems,
  ]);

  // Course-change-handler
  const handleChangeCourse = (value) => {
    choiceData.map((course) => {
      if (course.label === value) {
        setSelectedCourse(course);
        setSelectedCourseId(course.id);

        if (course.id != 0) {
          setSelectedBranch(course.branch[0]);
          setSelectedBranchId(course.branch[0].id);
        }
      }
    });
  };

  // Branch-change-handler
  const handleChangeBranch = (value) => {
    selectedCourse.branch.map((branch) => {
      if (branch.label === value) {
        setSelectedBranch(branch);
        setSelectedBranchId(branch.id);

        if (branch.id != 0) {
          setSelectedSession(branch.batches[0]);
          setSelectedSessionId(branch.batches[0].id);

          setSelectedYear(branch.years[0]);
          setSelectedYearId(branch.years[0].id);
        }
      }
    });
  };

  // Session-change-handler
  const handleChangeSession = (value) => {
    selectedBranch.batches.map((session) => {
      if (session.label === value) {
        setSelectedSession(session);
        setSelectedSessionId(session.id);
      }
    });
  };

  // Year-change-handler
  const handleChangeYear = (value) => {
    selectedBranch.years.map((year) => {
      if (year.label === value) {
        setSelectedYear(year);
        setSelectedYearId(year.id);
      }
    });
  };

  // Time-span handling
  const handleChangeTimeSpan = (value) => {
    setSelectedSpan(value);
  };

  // Start-date change handeler
  const startDateChangeHandeler = (value) => {
    setSelectedStartDate(value);
    // console.log(value);
  }

  // End-date change Handeler
  const endDateChangeHandeler = (value) => {
    setSelectedEndDate(value);
    // console.log(value);
  }
  
  // Pagination + Row selection
  const handlePageChange = (currentPage) => {
    setCurrPage(currentPage);
  };

  // Number of rows to display handler
  const handleRowCountChange = (selection) => {
    setDisplayNumItems(selection.value);
    setCurrPage(1);
  };

  // Sorting handler
  const sortHandler = (sortProperty, sortType) => {
    setSortProp(sortProperty);
    setSortValue(sortType);
    setCurrPage(1);
  };
  const sortMenu = (
    <Menu>
      {SortList.map((sortProp) => {
        return (
          <SubMenu title={sortProp.label}>
            <Menu.Item onClick={() => sortHandler(sortProp.value, "asc")}>
              Ascending A-Z
            </Menu.Item>
            <Menu.Item onClick={() => sortHandler(sortProp.value, "desc")}>
              Descending Z_A
            </Menu.Item>
          </SubMenu>
        );
      })}
    </Menu>
  );

  // Handle downloading student data
  const handleDownloadAll = () => {
    // API call

    const reqBody = {
      courses: [],
      branches: [],
      batches: [],
      fee_spans: [],
      selected_span: selectedSpan==="Custom"?null:selectedSpan,
      startDate: selectedSpan==="Custom"?selectedStartDate:null,
      endDate: selectedSpan==="Custom"?selectedEndDate:null,
      // order_by: sortProp,
      // order: sortValue,
    };

    // console.log(
    //   selectedCourseId,
    //   selectedBranchId,
    //   selectedSessionId,
    //   selectedYearId,
    //   "values"
    // );
    if (selectedCourseId) {
      reqBody.courses = [Number(selectedCourseId)];
    }
    if (selectedBranchId) {
      reqBody.branches = [Number(selectedBranchId)];
    }
    if (selectedSessionId) {
      reqBody.batches = [Number(selectedSessionId)];
    }
    if (selectedYearId) {
      reqBody.fee_spans = [Number(selectedYearId)];
    }
    let key = "updateable";

    const hide = message.loading( "Please wait while we are preparing your record!", 0);

    ServerService.DownloadFeeStatusTableData(reqBody)
      .then((res) => {
        // console.log(res);
        // let totPages = parseInt(res.data.count / displayNumItems);
        // if (res.data.count % displayNumItems != 0) {
        //   totPages += 1;
        // }
        // setTotalPages(totPages);
        // setTotalItems(res.data.count);
        // setTableData(res.data.results);
        const data = res.data;
        const fileName = "Fee Report";
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });
        message.success("Downloaded", 2.5);
      })
      .catch((err) => {
        console.log(err);
      }).finally(()=>{
        hide();
      })
  };

  if (choiceData === null) {
    return <LoadingScreen />;
  }

  return (
    <div className={styles.displayArea}>
      {/* Search-bar */}
      <div className={styles.selectionContainer}>
        {/* <div className={styles.feeSearchForm}>
          <img src={SearchIcon} alt="searchIcon" />
          <input
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Enter student name or student ID to search"
          />
        </div> */}
        <div className={styles.choiceArea}>
          {/* Course-handling */}
          <div className={styles.choice}>
            <h6 className={styles.dataLabel}>Select Course</h6>
            <Select
              onChange={handleChangeCourse}
              style={{ width: "80%" }}
              value={selectedCourse ? selectedCourse.label : null}
            >
              {choiceData
                ? choiceData.map((course, index) => {
                  return (
                    <Option key={index} value={course.label}>
                      {course.label}
                    </Option>
                  );
                })
                : null}
            </Select>
          </div>

          {/* Branch-handling */}
          <div className={styles.choice}>
            <h6 className={styles.dataLabel}>Select Branch</h6>
            <Select
              onChange={handleChangeBranch}
              style={{ width: "80%" }}
              disabled={selectedCourseId == 0}
              value={selectedBranch ? selectedBranch.label : null}
            >
              {selectedCourseId
                ? selectedCourse.branch.map((branch, index) => {
                  return (
                    <Option key={index} value={branch.label}>
                      {branch.label}
                    </Option>
                  );
                })
                : null}
            </Select>
          </div>

          {/* Session-handling */}
          <div className={styles.choice}>
            <h6 className={styles.dataLabel}>Select Session</h6>
            <Select
              onChange={handleChangeSession}
              style={{ width: "80%" }}
              disabled={selectedBranchId == 0 || selectedCourseId == 0}
              value={selectedSession ? selectedSession.label : null}
            >
              {selectedBranchId
                ? selectedBranch.batches.map((batch, index) => {
                  return (
                    <Option key={index} value={batch.label}>
                      {batch.label}
                    </Option>
                  );
                })
                : null}
            </Select>
          </div>

          {/* Year-handling */}
          <div className={styles.choice}>
            <h6 className={styles.dataLabel}>Select Year</h6>
            <Select
              onChange={handleChangeYear}
              style={{ width: "80%" }}
              value={selectedYear ? selectedYear.label : null}
              disabled={selectedBranchId == 0 || selectedCourseId == 0}
            >
              {selectedBranchId
                ? selectedBranch.years.map((year, index) => {
                  return (
                    <Option key={index} value={year.label}>
                      {year.label}
                    </Option>
                  );
                })
                : null}
            </Select>
          </div>

          {/* Time-span-handling */}
          <div className={styles.choice}>
            <h6 className={styles.dataLabel}>Select Timespan</h6>
            <Select
              style={{ width: "80%" }}
              onChange={handleChangeTimeSpan}
              value={selectedSpan}
            >
              {timeSpans.map((span, index) => {
                return (
                  <Option key={index} value={span}>
                    {span}
                  </Option>
                );
              })}
            </Select>
          </div>
          {selectedSpan === "Custom" ? <>
            {/* start data */}
            <div className={styles.choice}>
              <h6 className={styles.dataLabel}>Start Date</h6>
              <DatePicker
              onChange={startDateChangeHandeler}
                style={{ width: "80%" }}
              />
            </div>
            {/* end date */}
            <div className={styles.choice}>
              <h6 className={styles.dataLabel}>End Date</h6>
              <DatePicker
              onChange={endDateChangeHandeler}
                style={{ width: "80%" }}
              />
            </div>
          </> : null}

        </div>
      </div>

      {/* Table-container */}
      <div className={styles.feeStatusTableBody}>
        <div className={styles.tableTop}>
          <h6 className={styles.tableTopHeading}>Fee Report</h6>
          <div>
            <Search
              placeholder="Search by Student Name"
              allowClear
              onChange={e => {
                const currValue = e.target.value;
                // console.log(currValue);
                setValue(currValue);
                const filtered = tableData.filter(entry =>
                  entry.studentName.toLowerCase().startsWith(currValue.toLowerCase())
                );
                currValue === "" ? setFilteredData(tableData) : setFilteredData(filtered);
              }}
              style={{
                width: 300,
                marginRight: "14px",
              }}
            />
            {/* <Dropdown overlay={sortMenu}>
              <Button style={{ width: "100px", marginRight: "14px" }}>
                Sorting
                <DownOutlined />
              </Button>
            </Dropdown> */}

            <Button onClick={handleDownloadAll} style={{ marginRight: "14px" }}>
              Download All
              <DownloadOutlined />
            </Button>

            <Select
              trigger={["hover", "click"]}
              labelInValue
              defaultValue={{ value: 10 }}
              style={{ width: 162 }}
              onChange={handleRowCountChange}
            >
              <Option value={10}>Show 10 students</Option>
              <Option value={25}>Show 25 students</Option>
              <Option value={50}>Show 50 students</Option>
              <Option value={100}>Show 100 students</Option>
            </Select>
          </div>
        </div>

        <CustomTable
          currentPage={currPage}
          changePage={handlePageChange}
          columns={columns}
          data={filteredData}
          totalNum={totalItems}
          displayNum={displayNumItems}
          totalPages={totalPages}
          selectRow={false}
          scrollX="max-content"
        />
        <h3 style={{ paddingLeft: "1rem", fontWeight: "800" }}>Total Amount: ₹{total} </h3>
      </div>
    </div>
  );
};

export default FeeStatus;
