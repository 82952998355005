import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Menu, Dropdown, Button, Select, Breadcrumb, message, Input } from "antd";
import { DownOutlined, DownloadOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import Table from "../../../../components/Table/Table";
import AllColumns from "./TableColumns";
// import dummyData from "./dummyData";
import SortList from "./SortingProp";
import ServerService from "../../../../API/ServerService";
// import baseUrl from "../../../../API/baseURL/url";
import LoadingData from "./LoadingData/LoadingData";
// import FileSaver from "file-saver";
import styles from "./StudentsList.module.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import exportFromJSON from 'export-from-json'

const { Search } = Input;
const { Option } = Select;
const { SubMenu } = Menu;

const StudentsList = () => {
  const { courseId, branchId, year } = useParams();

  const [sortProp, setSortProp] = useState(SortList[0].value);
  const [sortValue, setSortValue] = useState("asc");

  const [currPage, setCurrPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [displayNumItems, setDisplayNumItems] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const [columns,] = useState(AllColumns);
  const [tableData, setTableData] = useState(null);
  const [value , setValue] = useState('');
  const [filteredData, setFilteredData] = useState(null);

  const url = [{ name: "Dashboard", link: "/AdminDashboard" }, { name: "view-students", link: "/view-students" }]
  const location = useLocation();
  const history = useHistory();


  useEffect(() => {
    // API call
    const reqBody = {
      order_by: sortProp,
      order: sortValue,
    };
    if (courseId !== undefined) {
      reqBody.courses = [Number(courseId)];
    }
    if (branchId !== undefined) {
      reqBody.branches = [Number(branchId)];
    }
    if (year !== undefined) {
      reqBody.passing_spans = [Number(year)];
    }

    // console.log(reqBody);

    ServerService.GetViewStudents(displayNumItems, currPage, reqBody)
      .then((res) => {
        // console.log(res);
        let totPages = parseInt(res.data.count / displayNumItems);
        if (res.data.count % displayNumItems !== 0) {
          totPages += 1;
        }
        setTotalPages(totPages);
        setTotalItems(res.data.count);
        setTableData(res.data.results);
        setFilteredData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    courseId,
    branchId,
    year,
    currPage,
    sortProp,
    sortValue,
    displayNumItems,
  ]);

  // Pagination
  const handlePageChange = (currentPage) => {
    setCurrPage(currentPage);
  };

  // Number of rows to display handler
  const handleRowCountChange = (selection) => {
    setDisplayNumItems(selection.value);
    setCurrPage(1);
  };

  // Sorting handler
  const sortHandler = (sortProperty, sortType) => {
    // console.log(sortProperty,sortType);
    setSortProp(sortProperty);
    setSortValue(sortType);
    setCurrPage(1);
  };
  const sortMenu = (
    <Menu>
      {SortList.map((sortProp) => {
        return (
          <SubMenu title={sortProp.label}>
            <Menu.Item onClick={() => sortHandler(sortProp.value, "asc")}>
              Ascending A-Z
            </Menu.Item>
            <Menu.Item onClick={() => sortHandler(sortProp.value, "desc")}>
              Descending Z-A
            </Menu.Item>
          </SubMenu>
        );
      })}
    </Menu>
  );


  // Download-all function
  const handleDownloadAll = () => {
    // API call
    const reqBody = {
      order_by: sortProp,
      order: sortValue,
    };
    if (courseId !== undefined) {
      reqBody.courses = [Number(courseId)];
    }
    if (branchId !== undefined) {
      reqBody.branches = [Number(branchId)];
    }
    if (year !== undefined) {
      reqBody.passing_spans = [Number(year)];
    }
    // let downloadURL = `${baseUrl}/api/students/download/`;
    // if(courseId){
    //   downloadURL += `?course=${courseId}`;
    // }
    // if(branchId){
    //   downloadURL += `&branch=${branchId}`;
    // }
    // if(year){
    //   downloadURL += `&year=${year}`;
    // }
    // console.log(downloadURL);
    // window.location.href = downloadURL;
    const hide = message.loading( "Please wait while we are preparing you data.",0);
    ServerService.DownloadViewStudents(reqBody, courseId, branchId, year)
      .then((res) => {
        // console.log(res);
        const data = res.data;
        const fileName = `Student List ${Date()}`;
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });
        message.success({ content: "Downloaded Successfully", key: "download" });
      })
      .catch((err) => {
        console.log(err);
        message.error({ content: "Error in Downloading", key: "download" });
      })
      .finally(() => {
        hide();
    })
  };

  if (tableData === null) {
    return <LoadingData />;
  }

  return (
    <div className={styles.displayArea}>
      <div style={{ display: "flex", gap: "10px" }} >
        <span className="back-arrow" onClick={() => history.goBack()} ><ArrowLeftOutlined /></span>
        <Breadcrumb style={{ marginBottom: "24px" }}>
          {url.map((entry, index) => {
            return <Breadcrumb.Item key={index}>
              <Link style={{ textDecoration: 'none' }} to={`${entry.link}`}>
                {entry.name}
              </Link>
            </Breadcrumb.Item>
          }
          )}
          {location.state ? (
            <>
              <Breadcrumb.Item><Link style={{ textDecoration: 'none' }} to={{ pathname: `/view-students/course/${courseId}`, state: location.state }}>
                {location.state.course}
              </Link></Breadcrumb.Item>
              {branchId ? (
                <>
                  <Breadcrumb.Item><Link style={{ textDecoration: 'none' }} to={{ pathname: `/view-students/${courseId}/${branchId}`, state: location.state }}>
                    {location.state.branch}
                  </Link></Breadcrumb.Item>

                  {year ? (

                    <Breadcrumb.Item>
                      {location.state.year}
                    </Breadcrumb.Item>
                  ) : null}
                </>
              ) : null}

            </>
          ) : null}

        </Breadcrumb>
      </div>
      <div className={styles.studentsListTableBody}>
        <div className={styles.tableTop}>
          <h6 className={styles.tableTopHeading}>View Students</h6>
          <div>
            <Search
              placeholder="Search by Student Name"
              allowClear
              onChange={e => {
                const currValue = e.target.value;
                setValue(currValue);
                let filtered = tableData.filter(entry =>
                  entry.studentName.toLowerCase().includes(currValue.toLowerCase())
                );
                if (filtered.length === 0) {
                  filtered = tableData.filter(entry =>
                    entry.father_name.toLowerCase().includes(currValue.toLowerCase()));
                }
                currValue === "" ? setFilteredData(tableData) : setFilteredData(filtered);
              }}
              style={{
                width: 300,
                marginRight: "14px",
              }}
            />
            {/* Sorting-dropdown */}
            <Dropdown overlay={sortMenu}>
              <Button style={{ width: "100px", marginRight: "14px" }}>
                Sorting
                <DownOutlined />
              </Button>
            </Dropdown>

            <Button onClick={handleDownloadAll} style={{ marginRight: "14px" }}>
              Download All
              <DownloadOutlined />
            </Button>

            {/* Row-count-change */}
            <Select
              trigger={["hover", "click"]}
              labelInValue
              defaultValue={{ value: 10 }}
              style={{ width: 162 }}
              onChange={handleRowCountChange}
            >
              <Option value={10}>Show 10 students</Option>
              <Option value={25}>Show 25 students</Option>
              <Option value={50}>Show 50 students</Option>
              <Option value={100}>Show 100 students</Option>
            </Select>
          </div>
        </div>
        <Table
          currentPage={currPage}
          changePage={handlePageChange}
          columns={columns}
          data={filteredData}
          totalNum={totalItems}
          displayNum={displayNumItems}
          totalPages={totalPages}
          selectRow={false}
          // onSearch={onSearch}
        />
      </div>
    </div>
  );
};

export default StudentsList;
