// import React from 'react';
// import styles from "./StudentSearchCard.module.css";

// const SearchSearchCard = (props) => {

 

//   return (
//     <div className={styles.card}>
//         <p className={styles.nameHeading}>Name:</p>
//         <p className={styles.name}>{props.name}</p>

//         <p className={styles.enrollementHeading}>Enrollment ID:</p>
//         <p className={styles.enrollement}>{props.enrollement}</p>

//         <p className={styles.enrollementHeading}>Father's Name:</p>
//         <p className={styles.enrollement}>{props.data.father_name}</p>

//     </div>
//   );
// };

// export default SearchSearchCard;


import React from "react";
import styles from "./StudentSearchCard.module.css";
import { Avatar } from "antd";
import MaleStudent from "../../assets/MaleStudent.svg";
import FemaleStudent from "../../assets/FemaleStudent.svg";
// import { UserOutlined } from "@ant-design/icons";
// import { Link } from "react-router-dom";

const StudentSearchCard = (props) => {
  return (
    <div
      style={{ borderColor: `${props.data.gender==="F"?"#DAD2ED":"#9ACC98"}` }}
      className={styles.studentCard}
    >
      {props.data.gender=="F" ? (
        <Avatar size={64} className={styles.img} src={FemaleStudent} />
      ) : (
        <Avatar size={64} className={styles.img} src={MaleStudent} />
      )}

      <div className={styles.cardContent}>
        <h6 className={styles.courseName}>{props.data.full_name}</h6>
        {props.cardType ==='receipt' ? (<h5>
          Receipt ID:{" "}
          <span className={styles.courseData}> {props.data.receipt_id} </span>
        </h5>):null}
        <h5>
          Enrollment ID:{" "}
          <span className={styles.courseData}> {props.data.enrollment_id} </span>
        </h5>
        <h5>
          Father Name:{" "}
          <span className={styles.courseData}> {props.data.father_name}</span>
        </h5>
      </div>

        {/* <Link
          to={{
            pathname: `/edit-faculty/${props.data.id}`,
            state: {
              name: props.data.name,
            },
          }}
        >
          <div className={styles.arrowIcon}>
            <i class="fas fa-chevron-right" />
          </div>
        </Link>
       */}
    </div>
  );
};

export default StudentSearchCard;
