import React from "react";
import styles from "../Account/AccountType/AdminAccount/AdminAccount.module.css";
import {
  Checkbox,
  Button,
  Spin,
  Input,
  Form,
  InputNumber,
  message,
  notification,
  Breadcrumb,
} from "antd";
import ServerService from "../../API/ServerService";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";
// import moment from 'moment';
import { Redirect, Link, useParams } from "react-router-dom";

const EditUserProfile = () => {
  const [ProfileData, setProfileData] = React.useState(null);
  const [checkboxData, setCheckbox] = React.useState({
    admin: null,
    management: null,
  });
  const [redirect, setRedirect] = React.useState(null);

  const [form] = Form.useForm();
  const { editId } = useParams();

  const openNotification = (title, desc, icon, duration) => {
    notification.open({
      message: title,
      description: desc,
      icon: icon,
      duration: duration,
    });
  };

  const url = [
    { name: "DashBoard", link: "/AdminDashboard" },
    { name: "view Account", link: "/viewAccount" },
    { name: "Profile", link: `/viewAccount/Profile/${editId}` },
    { name: "edit", link: "" },
  ];

  React.useEffect(() => {
    ServerService.GetProfile(editId)
      .then((res) => {
        // console.log(res);
        setProfileData(res.data);
        setCheckbox((prevState) => ({
          admin: res.data.is_admin,
          management: res.data.management,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onChangeAdmin = (e) => {
    setCheckbox((prevState) => ({ ...prevState, admin: e.target.checked }));
  };
  const onChangeManagement = (e) => {
    setCheckbox((prevState) => ({
      ...prevState,
      management: e.target.checked,
    }));
  };

  const onSubmit = (values) => {
    let key = "updatable";
    message.loading({ content: "Action in progress...", key });

    const data = {
      fullName: values.name,
      phone: values.phone,
      designation: values.designation,
      is_admin: checkboxData.admin,
      management: checkboxData.management,
    };

    //  console.log(data);

    ServerService.EditStaffProfile(editId, data)
      .then((res) => {
        // console.log(res);
        setRedirect("true");
        message.success({ content: "Action completed!", key, duration: 2 });
        openNotification(
          "Success",
          "Details editted successfully!",
          <SmileOutlined style={{ color: "#108ee9" }} />
        );
      })
      .catch((err) => {
        console.log(err);

        //If some error occured
        message.error({ content: "Something went wrong!", key, duration: 2 });
        openNotification(
          "Something went wrong!",
          err,
          <FrownOutlined style={{ color: "red" }} />,
          6
        );
        // form.resetFields();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (redirect) {
    return <Redirect to={`/viewAccount/Profile/${editId ? editId : null}`} />;
  }

  return (
    <div className={styles.displayArea}>
      <h6 className={styles.heading}>Edit Profiles Details</h6>
      {/* <h6 className={styles.subHeading}>ADMIN ACCOUNT</h6> */}

      <Breadcrumb style={{ marginBottom: "24px" }}>
        {url.map((entry, index) => {
          return (
            <Breadcrumb.Item key={index}>
              <Link style={{ textDecoration: "none" }} to={`${entry.link}`}>
                {entry.name}
              </Link>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>

      <div className={styles.institutionData}>
        {!ProfileData ? (
          <div style={{ textAlign: "center" }}>
            <Spin size="large" />
          </div>
        ) : (
          <div>
            <Form
              form={form}
              name="edit"
              className="add-course-form"
              initialValues={{
                name: ProfileData.fullName,
                designation: ProfileData.designation,
                phone: parseInt(ProfileData.phone),
              }}
              onFinish={onSubmit}
              onFinishFailed={onFinishFailed}
            >
              {/* enrty 1 */}

              <h6 className={styles.dataLabel}>Name</h6>

              <Form.Item
                name="name"
                rules={[
                  {
                    // required: true,
                    // message: "Please enter the InstitutionName!",
                  },
                ]}
              >
                <Input
                  autoComplete="off"
                  placeholder="Enter name"

                  //   value={CollegeData.institutionName}
                />
              </Form.Item>

              {/* entry 2 */}

              <h6 className={styles.dataLabel}>Designation</h6>

              <Form.Item name="designation">
                <Input autoComplete="off" placeholder="Enter designation" />
              </Form.Item>

              {/* entry 3  */}

              <h6 className={styles.dataLabel}>Contact Number</h6>

              <Form.Item
                name="phone"
                rules={[
                  {
                    type: "number",
                    // min:10,
                    // max:10,
                    // message:"Contact number should be of 10 digits"
                  },
                ]}
              >
                <InputNumber
                  placeholder="Contact Number"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>

              <Checkbox
                defaultChecked={ProfileData.is_admin}
                onChange={onChangeAdmin}
              >
                Admin
              </Checkbox>
              <Checkbox
                defaultChecked={ProfileData.management}
                onChange={onChangeManagement}
              >
                Management
              </Checkbox>

              <Form.Item>
                <Button
                  block
                  htmlType="submit"
                  style={{
                    color: "white",
                    background: "rgb(9, 220, 168)",
                    marginTop: "1rem",
                  }}
                >
                  Submit Edit Details
                </Button>
              </Form.Item>
            </Form>

            {/* <Button block style={{color:'white',background:"rgb(9, 220, 168)"}}> Submit Edit Details</Button> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditUserProfile;
