import React, { useState  } from "react";
import { Spin,Breadcrumb, Button, Modal} from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import styles from "../Account/AccountType/AdminAccount/AdminAccount.module.css";
// import LockIcon from "../../../../assets/LockIcon.svg";
import { useHistory,useParams,Link } from "react-router-dom";
import ServerService from "../../API/ServerService"

const AgentProfile = () => {

  
    const history = useHistory()
    const {ProfileId}=useParams()

    const [userData,setUserData] = useState(null);

    const { confirm } = Modal;

    const DeleteAgent = ()=> {
      confirm({
        title: 'Are you sure delete this Agent Detail?',
        icon: <ExclamationCircleOutlined />,
        // content: 'All the student data within the branch will be deleted.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          console.log('OK');
          ServerService.DeleteAgent(ProfileId)
          .then((res) => {
            console.log(res);
            history.push(`/viewAgent`);
          })
          .catch((err) => {
            console.log(err);
          });
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }


    React.useEffect(()=>{
        ServerService.GetAgentsProfile(ProfileId)
        .then(res=>{
            // console.log(res)
            setUserData(res.data)

        }).catch(err=>{
            // console.log(err)
        })
    },[])

    const url = [ {name: "DashBoard",link:"/AdminDashboard"}, 
                 {name:"view Agent",link:"/viewAgent"}, 
                 {name:"Profile",link:"/viewAgent/Profile"}]
    
    
  return(

    <div className={styles.displayArea}>

      <h6 className={styles.heading}>Agent Profile Details</h6>
      <Button
          onClick={DeleteAgent}
          style={{
            float: "right",
          }}
        >
          <DeleteOutlined
            style={{
              color: "red",
              fontSize: "17.2px",
              paddingBottom: "0px",
              marginRight: "0px",
            }}
          />
          Delete Agent
        </Button>
      {/* <h6 className={styles.subHeading}>ADMIN ACCOUNT</h6> */}

      <Breadcrumb style={{marginBottom:"24px"}}>
        {url.map((entry,index)=> {
          return<Breadcrumb.Item key={index}>
                  <Link style={{textDecoration:'none'}} to={`${entry.link}`}>
                    {entry.name}
                  </Link>
                </Breadcrumb.Item>}
        )}
      </Breadcrumb>

      <div className={styles.institutionData}>
        {!userData ? <div style={{textAlign:"center"}}><Spin size="large" /></div> : (
        <div>
            <h6 className={styles.dataLabel}>Name</h6>
            <h6 style={{textDecoration:"underline"}} className={styles.dataValue}>{userData ? userData.name : "__"}</h6>
            <h6 className={styles.dataLabel}>Address</h6>
            <h6 className={styles.dataValue}>{userData ? userData.address : "__"}</h6>
            <h6 className={styles.dataLabel}>Commission</h6>
            <h6 className={styles.dataValue}>{userData ? userData.commission : "__"}</h6>
            <h6 className={styles.dataLabel}>Phone Number</h6>
            <h6 className={styles.dataValue}>{userData ? userData.phone_number : "__"}</h6>

            
            {/* <div className={styles.editDetails}>Edit Details</div> */}
         
            
            <div onClick={() => history.push(`/viewAgent/profile/edit/${ProfileId}`)} className={styles.editDetails}>Edit Details</div> 

        </div>


        )}
            

      </div>
    </div>

  );
};

export default AgentProfile;
