import React from 'react';
import EditAdminAccount from "./AccountType/EditAdminAccount/EditAdminAccount";
// import UserAccount from "./AccountType/UserAccount/UserAccount";

const EditAccount = () => {
  return (
    <>
      <EditAdminAccount/>
    </>
  );
};

export default EditAccount;