import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {  Button, Select, Breadcrumb } from "antd";
import {  DownloadOutlined } from "@ant-design/icons";
import Table from "../../components/Table/Table";
import AllColumns from "./TableColumns";
import ServerService from "../../API/ServerService";
// import baseUrl from "../../API/baseURL/url";
import LoadingData from "./LoadingData/LoadingData";
// import FileSaver from "file-saver";
import { Link } from "react-router-dom";
import styles from "./ViewOtherStudents.module.css";

const { Option } = Select;
// const { SubMenu } = Menu;

const ViewOtherStudents = () => {
  const { studentsType } = useParams();

  const [currPage, setCurrPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [displayNumItems, setDisplayNumItems] = useState(20);
  const [totalItems, setTotalItems] = useState(0);

  const [columns,] = useState(AllColumns);
  const [tableData, setTableData] = useState([]);

  let url = window.location.href;
  url = url.split("/");
  url.splice(2, 1, "Dashboard");

  useEffect(() => {
    // API call
    let reqBody = {};
    if (studentsType === "inactive") {
      reqBody = {
        inactive: true,
      };
    } else if (studentsType === "passout") {
      reqBody = {
        type: "old",
      };
    } else if (studentsType === "failed") {
      reqBody = {
        failed: true,
      };
    }

    ServerService.GetOtherViewStudents(displayNumItems, currPage, reqBody)
      .then((res) => {
        // console.log(res);
        let totPages = parseInt(res.data.count / displayNumItems);
        if (res.data.count % displayNumItems !== 0) {
          totPages += 1;
        }
        setTotalPages(totPages);
        setTotalItems(res.data.count);
        setTableData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currPage, displayNumItems, studentsType]);

  // Pagination
  const handlePageChange = (currentPage) => {
    setCurrPage(currentPage);
  };

  // Number of rows to display handler
  const handleRowCountChange = (selection) => {
    setDisplayNumItems(selection.value);
    setCurrPage(1);
  };

  // Download-all function
  const handleDownloadAll = () => {
    // API call
    // const reqBody = {
    //   courses: [Number(courseId)],
    //   branches: [Number(branchId)],
    //   passing_spans: [Number(year)],
    // };
    // ServerService.DownloadViewStudents(courseId, branchId, year)
    //   .then((res) => {
    //     console.log(res);
    //     const csvData = new Blob([res.data], {
    //       // type: "text/xlsx;charset=utf-8;",
    //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //     });
    //     FileSaver.saveAs(csvData, "Students_List.xlsx");
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  if (tableData === null) {
    return <LoadingData />;
  }

  return (
    <div className={styles.displayArea}>
      <Breadcrumb style={{ marginBottom: "24px" }}>
        {url.slice(2).map((entry, index) => {
          return (
            <Breadcrumb.Item key={index}>
              <Link style={{ textDecoration: "none" }} to={`/${entry}`}>
                {entry}
              </Link>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
      <div className={styles.studentsListTableBody}>
        <div className={styles.tableTop}>
          <h6 className={styles.tableTopHeading}>View Students</h6>
          <div>
            {/* Download button */}
            <Button onClick={handleDownloadAll} style={{ marginRight: "14px" }}>
              Download All
              <DownloadOutlined />
            </Button>

            {/* Row-count-change */}
            <Select
              trigger={["hover", "click"]}
              labelInValue
              defaultValue={{ value: 20 }}
              style={{ width: 162 }}
              onChange={handleRowCountChange}
            >
              <Option value={20}>Show 20 students</Option>
              <Option value={50}>Show 50 students</Option>
              <Option value={100}>Show 100 students</Option>
              <Option value={150}>Show 150 students</Option>
            </Select>
          </div>
        </div>

        <Table
          currentPage={currPage}
          changePage={handlePageChange}
          columns={columns}
          data={tableData}
          totalNum={totalItems}
          displayNum={displayNumItems}
          totalPages={totalPages}
          selectRow={false}
        />
      </div>
    </div>
  );
};

export default ViewOtherStudents;
