import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Select, message, notification } from "antd";
import {
  DownloadOutlined,
  SmileOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useDropzone } from "react-dropzone";
import ServerService from "../../../../API/ServerService";
import BaseUrl from "../../../../API/baseURL/url";
import styles from "./BulkAddStudent.module.css";

const { Option } = Select;

const BulkAddStudent = () => {
  const [form] = Form.useForm();

  const [uploadedFile, setUploadedFile] = useState(null);

  const [choiceData, setChoiceData] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedYearId, setSelectedYearId] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedSessionId, setSelectedSessionId] = useState(null);

  useEffect(() => {
    // For getting branches, batches & years
    ServerService.GetBranchSelectionData()
      .then((res) => {
        // console.log(res);
        setChoiceData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const openNotification = (title, desc, icon, duration) => {
    notification.open({
      message: title,
      description: desc,
      icon: icon,
      duration: duration,
    });
  };

  // Branch-change-handler
  const handleChangeBranch = (value) => {
    choiceData.map((branch) => {
      if (branch.label === value) {
        setSelectedBranch(branch);
        setSelectedBranchId(branch.id);

        setSelectedSession(branch.batches[0]);
        setSelectedSessionId(branch.batches[0].id);
        form.setFieldsValue({
          batch: branch.batches[0].label,
        });

        setSelectedYear(branch.years[0]);
        setSelectedYearId(branch.years[0].id);
        form.setFieldsValue({
          year: branch.years[0].label,
        });
      }
    });
  };
  // Session-change-handler
  const handleChangeSession = (value) => {
    selectedBranch.batches.map((batch) => {
      if (batch.label === value) {
        setSelectedSession(batch);
        setSelectedSessionId(batch.id);
      }
    });
  };
  // Year-change-handler
  const handleChangeYear = (value) => {
    selectedBranch.years.map((year) => {
      if (year.label === value) {
        setSelectedYear(year);
        setSelectedYearId(year.id);
      }
    });
  };

  const downloadSample = () => {
    window.location.href = `${BaseUrl}/api/students/add/${selectedSessionId}/`;
  };

  const uploadData = () => {

    let key = "updatable";
    message.loading({ content: "Action in progress...", key });

    // console.log(uploadedFile);
    let formData = new FormData();
    formData.append('excel_file',uploadedFile);
    formData.append('passing_span',Number(selectedYearId));

    ServerService.UploadBulkStudents(selectedSessionId, formData)
      .then((res) => {
        // console.log(res);
        message.success({ content: "Action completed!", key, duration: 2 });
        openNotification(
          "Data uploaded successfully!",
          "The file was uploaded successfully and the students will be added to the database!",
          <SmileOutlined style={{ color: "#108ee9" }} />,
          4
        );
        setTimeout(() => {
          window.location.reload();
        },1500)
      })
      .catch((err) => {
        console.log(err);
        message.error({ content: "Something went wrong!", key, duration: 2 });
        openNotification(
          "Something went wrong!",
          "We are unable to process your request right now! Please try again after sometime.",
          <ExclamationCircleOutlined style={{ color: "red" }} />,
          4
        );
      });
  };

  const onDrop = useCallback((acceptedFiles) => {
    let filesList = [];
    acceptedFiles.forEach((file) => {
      filesList.push(file);
    });
    setUploadedFile(filesList[0]);
  });

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div className={styles.displayArea}>
      <div className={styles.bulkUpload}>
        <h6 className={styles.formHeader}>Bulk Upload</h6>

        <Form form={form} onFinish={downloadSample}>
          {/* STUDENT'S BRANCH */}
          <Form.Item
            name="branch"
            rules={[
              {
                required: true,
                message: "Please enter student's branch!",
              },
            ]}
            style={{ width: "98%", marginLeft: "auto", marginRight: "auto" }}
          >
            <Select
              placeholder="Select a branch"
              onChange={handleChangeBranch}
              allowClear
              value={selectedBranch ? selectedBranch.label : null}
            >
              {choiceData
                ? choiceData.map((branch, index) => {
                    return <Option value={branch.label}>{branch.label}</Option>;
                  })
                : null}
            </Select>
          </Form.Item>

          {/* STUDENT'S SESSION */}
          <Form.Item
            name="batch"
            rules={[
              {
                required: true,
                message: "Please enter student's session!",
              },
            ]}
            style={{ width: "98%", marginLeft: "auto", marginRight: "auto" }}
          >
            <Select
              placeholder="Select a session"
              disabled={selectedBranch === null}
              onChange={handleChangeSession}
              allowClear
              value={selectedSession ? selectedSession.label : null}
            >
              {selectedBranch !== null
                ? selectedBranch.batches.map((batch) => {
                    return <Option value={batch.label}>{batch.label}</Option>;
                  })
                : null}
            </Select>
          </Form.Item>

          {/* STUDENT'S YEAR */}
          <Form.Item
            name="year"
            rules={[
              {
                required: true,
                message: "Please enter student's year!",
              },
            ]}
            style={{ width: "98%", marginLeft: "auto", marginRight: "auto" }}
          >
            <Select
              placeholder="Select an academic year"
              disabled={selectedBranch === null}
              onChange={handleChangeYear}
              allowClear
              value={selectedYear ? selectedYear.label : null}
            >
              {selectedBranch !== null
                ? selectedBranch.years.map((year) => {
                    return <Option value={year.label}>{year.label}</Option>;
                  })
                : null}
            </Select>
          </Form.Item>

          <div className={styles.downloadSample}>
            <h6>Download Sample file</h6>
            <Button
              htmlType="submit"
              style={{ width: "100%", marginBottom: "24px" }}
            >
              Download <DownloadOutlined />
            </Button>
          </div>
        </Form>

        <div className={styles.upload}>
          <h6 className={styles.uploadHeader}>Download Sample file</h6>
          <div {...getRootProps({ className: styles.dropzone })}>
            <input multiple={false} {...getInputProps()} accept="text/csv" />
            <DownloadOutlined
              style={{ fontSize: "20px", marginBottom: "12px" }}
            />
            <h6 className={styles.headline}>
              {acceptedFiles.length > 0 ? "File Uploaded!" : "Upload .CSV file"}
            </h6>
            <h6 className={styles.bottomLine}>
              {acceptedFiles.length > 0
                ? "The file was uploaded successfully!"
                : "Drag and drop the file or click here to select a file from computer"}
            </h6>
          </div>
        </div>

        <Button className={styles.uploadButton} onClick={uploadData}>
          Upload Data
        </Button>
      </div>
    </div>
  );
};

export default BulkAddStudent;
