import React from "react";
import { NavLink } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { LockOutlined, MailOutlined, BankOutlined, PhoneOutlined } from "@ant-design/icons";

function SignupForm(props) {

  const onFinish = (values) => {
    const userData = {
      collegeName : values.collegeName,
      email : values.email,
      phone : values.phone,
      password : values.password
    } 
    props.submitHandler(userData);
  }

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="collegeName"
        rules={[
          {
            required: true,
            message: "Please input your Institution name!",
          },
        ]}
      >
        <Input
          style={{
            backgroundColor: "#EEF0F4",
            borderRadius: "8px",
          }}
          prefix={<BankOutlined className="site-form-item-icon" />}
          placeholder="Institution Name"
        />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          { required: true, message: "Please input your E-Mail!" },
        ]}
      >
        <Input
          style={{
            backgroundColor: "#EEF0F4",
            borderRadius: "8px",
          }}
          prefix={<MailOutlined />}
          placeholder="E-Mail"
        />
      </Form.Item>

      <Form.Item
        name="phone"
        rules={[
          { 
            required: true, 
            message: "Please input your Phone number!" 
          },
          {
            pattern: /^[0-9]\d{0,13}$/,
            message: "Value should be less than 15 digits",
          }
        ]}
      >
        <Input
          style={{
            backgroundColor: "#EEF0F4",
            borderRadius: "8px",
          }}
          prefix={<PhoneOutlined />}
          placeholder="Phone Number"
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
             required: true, 
             message: "Please input your Password!" 
          },
          {
            pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
            message: "Minimum eight characters, at least one letter and one number required"
          }
        ]}
      >
        <Input.Password
          style={{
            backgroundColor: "#EEF0F4",
            borderRadius: "8px",
          }}
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Create Account
        </Button>
      </Form.Item>

      <h6 className="formFooter">
        Already have an acccount?<NavLink to="/UserLogin">Login</NavLink>
      </h6>
    </Form>
  );
}

export default SignupForm;
