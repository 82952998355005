import React from 'react';
import styles from "../AdminAccount/AdminAccount.module.css";
import { Select,Button,Spin,Input, Form,InputNumber,DatePicker,message, notification,Breadcrumb} from 'antd';
import ServerService from '../../../../API/ServerService';
import {  SmileOutlined, FrownOutlined } from "@ant-design/icons";
import moment from 'moment';
import {Redirect,Link} from 'react-router-dom';
import { useSelector } from "react-redux";


const EditAdminAccount = () => {

    const [CollegeData,setCollegeData]=React.useState(null);
    const [redirect,setRedirect]=React.useState(null)
    const [LogoImage,setLogoImage]=React.useState('');
    const [SelectedLogoImage,setSelectedLogoImage]=React.useState(null);
    const [CoverImage,setCoverImage]=React.useState('');
    const [SelectedCoverImage,setSelectedCoverImage]=React.useState(null);
    const [form] = Form.useForm();
    const { Option } = Select;
    const UserType = useSelector((state) => {
      if(state.auth.user){
        return state.auth.user.role
      }
    });

    const openNotification = (title, desc, icon, duration) => {
    notification.open({
      message: title,
      description: desc,
      icon: icon,
      duration: duration,
    });
  };

  let url = window.location.href;
  url = url.split('/');
  url.splice(2,1,"Dashboard")

  const onImageChange = event => {
    if (event.target.files && event.target.files[0]) {
      //console.log(event.target.files[0])
      let img = event.target.files[0];
      setLogoImage(img)
      setSelectedLogoImage("true")
    }
  };
  const onImageChange2 = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setCoverImage(img)
      setSelectedCoverImage("true")
    }
  };


    React.useEffect(() => {

        ServerService.GetStaffList()
          .then((res) => {
            // console.log(res);
            setLogoImage(res.data.adminDetails.institutionDetails.logo)
            setCoverImage(res.data.adminDetails.institutionDetails.cover_pic)
            setCollegeData(res.data.adminDetails.institutionDetails);
          })
          .catch((err) => {
            // console.log(err);
          })
    
      },[])

    const onSubmit=(values)=>{
        //console.log(LogoImage)
        //console.log("this is cover",CoverImage)
        let key = "updatable";
        message.loading({ content: "Action in progress...", key });
        let enrollement_id_length_count = 0
        
      //  console.log(values)
        for(let i=0;i<values.Enrollment_id_digit_length.length;i++){
          if(values.Enrollment_id_digit_length[i]==9) enrollement_id_length_count++
        }
        let data = new FormData();

        data.append('institutionName', values.InstitutionName);
        data.append('Receipt_start_prefix', values.Receipt_start_prefix);
        data.append('contactNumber', values.ContactNumber);
        data.append('affiliationCode', values.AffilationCode);
        data.append('affiliatedTo', values.AffilatedTo);
        if(values.EstablishedIn){
          data.append('establishedIn', values.EstablishedIn.format('YYYY-MM-DD'));
        }
        data.append('address', values.Address);
        data.append('about', values.About);
        data.append('institutionEmail', values.InstitutionEmail);
        data.append(' enrollment_id_prefix', values.Enrollment_id_prefix);
        data.append('enrollment_id_digit_length', enrollement_id_length_count.toString());
        data.append('receipt_id_prefix', values.Receipt_id_prefix)
        if(SelectedLogoImage)
          data.append(' logo', LogoImage);

        if(SelectedCoverImage)
          data.append(' cover_pic', CoverImage);


        // data = {
        //     institutionName: values.InstitutionName,
        //     Receipt_start_prefix:values.Receipt_start_prefix,
        //     email:values.EmailID,
        //     contactNumber:values.ContactNumber,
        //     affiliationCode:values.AffilationCode,
        //     affiliatedTo:values.AffilatedTo,
        //     establishedIn:values.EstablishedIn.format('YYYY-MM-DD'),
        //     address:values.Address,
        //     about:values.About,
        //     institutionEmail:values.InstitutionEmail,
        //     enrollment_id_prefix:values.Enrollment_id_prefix,
        //     enrollment_id_digit_length:enrollement_id_length_count.toString(),
        //     receipt_id_prefix:values.Receipt_id_prefix,
        //     logo:LogoImage,
        //     cover_pic:CoverImage
        
        // };
      //  console.log(data);

        ServerService.EditAdminDetails(data)
        .then((res) => {
            // console.log(res);
            setRedirect("true");
            message.success({ content: "Action completed!", key, duration: 2 });      
            openNotification(
            "Success",
            "Details editted successfully!",
            <SmileOutlined style={{ color: "#108ee9" }} />,
            );
        
        })
        .catch((err) => {
            // console.log(err);

            //If some error occured
            message.error({ content: "Something went wrong!", key, duration: 2 });
            openNotification(
            "Something went wrong!",
            "We are having some trouble editing the details. Please try again",
            <FrownOutlined style={{ color: "red" }} />,
            6
            );
            // form.resetFields();
        });
       
    }


    const onFinishFailed = (errorInfo) => {
        //console.log('Failed:', errorInfo);
    };

      if(redirect){
          return <Redirect to="/viewAccount" />
      }


      const OnprefixChange = (value: string) => {
        switch (value) {
          case '0-99':
            form.setFieldsValue({ note: '0-99' });
            return;
          case '0-999':
            form.setFieldsValue({ note: '0-999' });
            return;
          case '0-9999':
            form.setFieldsValue({ note: '0-9999' });
            return;
          case '0-99999':
            form.setFieldsValue({ note: '0-99999' });
            return;
          case '0-999999':
            form.setFieldsValue({ note: '0-999999' });
            return;
          case 'other':
            form.setFieldsValue({ note: '0-999' });
            return;
              
        }
      };

  return (
    <div className={styles.displayArea}>
        <h6 className={styles.heading}>Edit Account Details</h6>
        <h6 className={styles.subHeading}>ADMIN ACCOUNT</h6>
        
         <Breadcrumb style={{marginBottom:"24px"}}>
            {url.slice(2).map((entry,index)=> {
            return<Breadcrumb.Item key={index}>
                    <Link style={{textDecoration:'none'}} to={`/${entry}`}>
                        {entry}
                    </Link>
                    </Breadcrumb.Item>}
            )}
        </Breadcrumb>

        <div className={styles.institutionData}>
        
        {!CollegeData ? <div style={{textAlign:"center"}}><Spin size="large" /></div> : (
        
        <div>
            <Form
                form={form}
                name="edit"
                className="add-course-form"
                initialValues={{
                "InstitutionName":CollegeData.institutionName,
                // "EmailID":CollegeData.email,
                "ContactNumber":CollegeData.contactNumber,
                "AffilationCode":CollegeData.affiliationCode,
                "AffilatedTo":CollegeData.affiliatedTo,
                "EstablishedIn":CollegeData.establishedIn  ? moment(CollegeData.establishedIn) : null ,
                "About":CollegeData.about,
                "Address":CollegeData.address,
                "InstitutionEmail":CollegeData.email,
                "Enrollment_id_prefix":CollegeData.enrollment_id_prefix,
                "Enrollment_id_digit_length":CollegeData.enrollment_id_digit_length,
                "Receipt_id_prefix": CollegeData.receipt_id_prefix,
                "Receipt_start_prefix":parseInt(CollegeData.receipt_start_digit)

            }}
                onFinish={onSubmit}
                onFinishFailed={onFinishFailed}
            >
            {/* enrty 1 */}

            <h6 className={styles.dataLabel}>
                Institution Name 
            </h6>

            <Form.Item
                name="InstitutionName"
                rules={[
                {
                    // required: true,
                    // message: "Please enter the InstitutionName!",
                },
                ]}
            >
            <Input
              autoComplete="off"
              placeholder="Institution Name"
              
            //   value={CollegeData.institutionName}
            />
            </Form.Item>

            {/* entry 2 */}

            {/* <h6 className={styles.dataLabel}>
                Email Id 
            </h6>

            <Form.Item
                name="EmailID"
                rules={[
                {
                    type:"email",                    
                },
                ]}
            >
            <Input
              autoComplete="off"
              placeholder="Email ID"
              disabled={true}

            />
            </Form.Item> */}

            {/* entry 3  */}

            <h6 className={styles.dataLabel}>
                Contact Number  
            </h6>

            <Form.Item
                name="ContactNumber"
                rules={[
                {   
                    
                    // min:10,
                    // max:10,
                    // message:"Contact number should be of 10 digits"
                },
                ]}
            >
            <Input
              placeholder="Contact Number"
              style={{
                width: '100%',
              }}

            />
            </Form.Item>

             {/* entry 4  */}

             <h6 className={styles.dataLabel}>
                Affilation Code 
            </h6>

            <Form.Item
                name="AffilationCode"
            >
            <Input
            //   autoComplete="off"
              placeholder="AffilationCode"
                style={{width: '100%', }}
            />
            </Form.Item>

            {/* entry 5 */}

            <h6 className={styles.dataLabel}>
                Affilated To
            </h6>

            <Form.Item
                name="AffilatedTo"
                rules={[
                
                ]}
            >
            <Input
              autoComplete="off"
              placeholder="Affilated To"
              

            />
            </Form.Item>

             {/* entry 6 */}

             <h6 className={styles.dataLabel}>
             Established in
            </h6>

            <Form.Item
                name="EstablishedIn"
                rules={[
                {
                    type: 'date',
                },
                ]}
            >
                <DatePicker format={"YYYY-MM-DD"} allowEmpty />

            </Form.Item>

            {/* entry 7 */}

             <h6 className={styles.dataLabel}>
             About
            </h6>

            <Form.Item
                name="About"
                rules={[
                {
                },
                ]}
            >
            <Input.TextArea
              autoComplete="off"
              placeholder="About"
              

            />
            </Form.Item>

          {/* logo image */}
            <img src={SelectedLogoImage ? URL.createObjectURL(LogoImage) : LogoImage} alt="No institution logo" style={{width:"50px",height:"50px", marginBottom:"16px"}}/>

            <h6 className={styles.dataLabel}>Select Logo</h6>
            <input style={{marginBottom:'24px'}} type="file" name="myImage" onChange={onImageChange} />

            <img src={SelectedCoverImage ? URL.createObjectURL(CoverImage) : CoverImage}  alt="No Cover logo" style={{width:"50px",height:"50px",marginBottom:"16px"}}/>

            <h6 className={styles.dataLabel}>Select Cover</h6>
            <input style={{marginBottom:'24px'}} type="file" name="myImage2" onChange={onImageChange2} />

            {/* entry 8 */}

            <h6 className={styles.dataLabel}>
             Address
            </h6>

            <Form.Item
                name="Address"
                rules={[
                {
                    // type: 'w',
                },
                ]}
            >
            <Input.TextArea
              autoComplete="off"
              placeholder="Address"
              

            />
            </Form.Item>

            {/* entry 9 */}

            <h6 className={styles.dataLabel}>
            Institution Email
            </h6>

            <Form.Item
                name="InstitutionEmail"
                rules={[
                {
                    type:'email'
                },
                ]}
            >
            <Input
              autoComplete="off"
              placeholder="Institution Email"
              

            />
            </Form.Item>

            {/* entry 10 */}

            <h6 className={styles.dataLabel}>
                Enrollment id prefix
            </h6>

            <Form.Item
                name="Enrollment_id_prefix"
            >
                
            <Input
              disabled={UserType!=='admin' ? true : false}
              style={{
                width: '100%',
              }}

            />
            </Form.Item>

            {/* entry 11 */}

            <h6 className={styles.dataLabel}>
                Enrollment id digit length
            </h6>

            <Form.Item
                name="Enrollment_id_digit_length"
              
            >
                <Select
                    placeholder="Select a option "
                    onChange={OnprefixChange}
                    allowClear
                    disabled={UserType!=='admin' ? true : false}
                    >
                    <Option value="0-99">0-99</Option>
                    <Option value="0-999">0-999</Option>
                    <Option value="0-9999">0-9999</Option>
                    <Option value="0-99999">0-99999</Option>
                    <Option value="0-999999">0-999999</Option>

                </Select>
            </Form.Item>

            {/* entry 11 */}

            <h6 className={styles.dataLabel}>
                Receipt id prefix
            </h6>

            <Form.Item
                name="Receipt_id_prefix"
                // rules={[
                //     {
                //         type:"number",
                //     },
                //     ]}
            >
            <Input
            //   autoComplete="off"
                disabled={UserType!=='admin' ? true : false}
                placeholder="Receipt_id_prefix"
                style={{width: '100%', }}
            

            />
            </Form.Item>

            {/* entry 12 */}

            <h6 className={styles.dataLabel}>
                Receipt start prefix
            </h6>

            <Form.Item
                name="Receipt_start_prefix"
                
                rules={[
                {
                    type: 'number',
                },
                ]}
            >
            <InputNumber 
                disabled={UserType!=='admin' ? true : false}
                placeholder="Receipt_start_prefix"
                style={{
                  width: '100%',
                }}/>

            </Form.Item>

            
           
            <Form.Item>
                <Button
                block
                htmlType="submit"
                style={{color:'white',background:"rgb(9, 220, 168)"}}
                >Save
                </Button>
             </Form.Item>

            </Form>

           {/* <Button block style={{color:'white',background:"rgb(9, 220, 168)"}}> Submit Edit Details</Button> */}
            
           </div>)} 
           
        </div>
    </div>
  );
};

export default EditAdminAccount;