import React, { useState, useEffect } from "react";
import moment from "moment";
import { Form, Input, Button, DatePicker, Select, Breadcrumb} from "antd";
import { useParams, useHistory, Link } from "react-router-dom";
// import dummyData from "./dummyData";
import { message, notification } from "antd";
import {
  // BookOutlined,
  // SwitcherOutlined,
  FormOutlined,
  SmileOutlined,
  FrownOutlined,
} from "@ant-design/icons";
import styles from "./AddBatch.module.css";
import ServerService from "../../../API/ServerService";

const { Option } = Select;


const AddBatch = () => {
  
  const { branchId } = useParams();
  const [form] = Form.useForm();
  const [branchList, setBranchList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const history = useHistory();
  
  let url;
    url = [{name:"Dashboard",link:"/AdminDashboard"},
          {name:"Add-Batch",link:"/add-batch"} ]
  


  useEffect(() => {
    // console.log(branchId);

    ServerService.GetBranches('all')
      .then((res) => {
        let branches = [];
        res.data.forEach((branch, index) => {
          if (branch.id == branchId
            //  && branchId !== "new"
             ) {
            setSelectedBranch(branch);
            setSelectedBranchId(branch.id);
            form.setFieldsValue({
              branch: `${branch.courseName} | ${branch.branch_name}`,
            });
          }
          branches.push({
            id: branch.id,
            label: `${branch.courseName} | ${branch.branch_name}`,
          });
        });
        // console.log(branches);
        setBranchList(branches);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const openNotification = (title, desc, icon, duration) => {
    notification.open({
      message: title,
      description: desc,
      icon: icon,
      duration: duration,
    });
  };

  const handleChangeBranch = (value) => {
    branchList.map((branch) => {
      if (branch.label === value) {
        setSelectedBranch(branch);
        setSelectedBranchId(branch.id);
      }
    });
  };

  const onSubmit = (values) => {
    const reqBody = {
      branch: selectedBranchId,
      name: values.batchName,
      default_fee: values.defaultFee,
      start_date: moment(values.startDate).format("YYYY-MM-DD"),
    };
    // console.log(reqBody);

    let key = "updatable";
    message.loading({ content: "Action in progress...", key });

    ServerService.AddBatch(reqBody)
      .then((res) => {
        console.log(res);
        // If batch was created successfully
        message.success({ content: "Action completed!", key, duration: 2 });
        
        openNotification(
          "Batch added successful!",
          "The batch was created successfully. Please head to the branch to view all batches.",
          <SmileOutlined style={{ color: "#108ee9" }} />,
          4.5
        );
        form.resetFields();
        // history.push(`/`);
      })
      .catch((err) => {
        console.log(err);
        //If some error occured
        message.error({ content: "Something went wrong!", key, duration: 2 });
        openNotification(
          "Something went wrong!",
          "We are having some trouble creating the course. Please try again later",
          <FrownOutlined style={{ color: "red" }} />,
          6
        );
        form.resetFields();
      });
  };

  return (
    <div className={styles.displayArea}>
      <Breadcrumb style={{marginBottom:"24px"}} className={styles.Breadcrumb}>
        {url.map((entry,index)=> {
          return<Breadcrumb.Item key={index}>
                  <Link style={{textDecoration:'none'}} to={`${entry.link}`}>
                    {entry.name}
                  </Link>
                </Breadcrumb.Item>}
        )}
      </Breadcrumb>
      <div className={styles.addBatchForm}>
        <Form
          form={form}
          name="normal_login"
          className="add-course-form"
          onFinish={onSubmit}
        >
          <h6 className={styles.formHeader}>Add New Batch</h6>
          <h6 className={styles.label}>
            Select Branch<span style={{ color: "red" }}>*</span>
          </h6>
          <Form.Item
            name="branch"
            rules={[
              {
                required: true,
                message: "Please select an option!",
              },
            ]}
          >
            <Select
              allowClear
              onChange={handleChangeBranch}
              value={selectedBranch ? selectedBranch.label : null}
            >
              {branchList.map((branch, index) => {
                return (
                  <Option value={branch.label} key={index}>
                    {branch.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <h6 className={styles.label}>
            Batch Name<span style={{ color: "red" }}>*</span>
          </h6>
          <Form.Item
            name="batchName"
            rules={[
              {
                required: true,
                message: "Please enter the batch name!",
              },
              { max: 14, message: "Length must be less than 15 characters" },
            ]}
          >
            <Input
              autoComplete="off"
              prefix={<FormOutlined className="site-form-item-icon" />}
              placeholder="Batch Name"
            />
          </Form.Item>

          <h6 className={styles.label}>
            Batch Default Fee<span style={{ color: "red" }}>*</span>
          </h6>
          <Form.Item
            name="defaultFee"
            rules={[
              {
                required: true,
                message: "Please enter the batch default fee for each year/semester",
              },
            ]}
          >
            <Input
              autoComplete="off"
              prefix={<FormOutlined className="site-form-item-icon" />}
              placeholder="Default Fee"
              type="number"
            />
          </Form.Item>

          <h6 className={styles.label}>
            Starting Date<span style={{ color: "red" }}>*</span>
          </h6>
          <Form.Item
            name="startDate"
            rules={[
              {
                required: true,
                message: "Please enter a starting-date!",
              },
            ]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.addBatchButton}
            >
              Create Batch
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddBatch;
