const data = [
  { id: 1,
    branch_name: 'CSE',
    courseName: 'B.Tech',
    years: 4,
    numOfStudents: 1264,
    yearsList: [1,2,3]
  },
  { id: 2,
    branch_name: 'ECE',
    courseName: 'B.Tech',
    years: 4,
    numOfStudents: 1264,
    yearsList: [1,2,3]
  },
  { id: 3,
    branch_name: 'CS & IT',
    courseName: 'B.Tech',
    years: 4,
    numOfStudents: 1264,
    yearsList: [1,2,3]
  },
  { id: 4,
    branch_name: 'CIVIL',
    courseName: 'B.Tech',
    years: 4,
    numOfStudents: 1264,
    yearsList: [1,2,3]
  },
  { id: 5,
    branch_name: 'MECHANICAL',
    courseName: 'B.Tech',
    years: 4,
    numOfStudents: 1264,
    yearsList: [1,2,3]
  },
];

export default data;