import React from "react";
import Background from "../../../components/Overlay/Background/Background";

const AddStudent = () => {
  return (
    <>
      <Background closeOverlay={false} addStudent={true} />
    </>
  );
};

export default AddStudent;
