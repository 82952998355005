import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./ViewBranches.module.css";
import BranchCard from "../../../components/Cards/BranchCard/BranchCard";
// import dummyData from "./dummyData";
import AddIcon from "../../../assets/AddIcon.svg";
import LoadingData from "./LoadingData";
import ServerService from "../../../API/ServerService";
import { useHistory } from "react-router";
import { Breadcrumb, Button, Modal } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useParams, Link } from "react-router-dom";

const { confirm } = Modal;

const AllBranches = () => {
  const history = useHistory();
  const UserType = useSelector((state) => {
    if (state.auth.user) {
      return state.auth.user.role
    }
  });
  const [allBranches, setAllBranches] = useState(null);
  const [branchName, setBranch] = useState("branches");
  const { courseId } = useParams();

  // let branch = "branches" + window.location.href[window.location.href.length -1];
  let url = ["Dashboard", "allCourses", branchName];
  useEffect(() => {
    console.log(courseId);
    ServerService.GetBranches(courseId)
      .then((res) => {
        console.log(res);
        setAllBranches(res.data);
        setBranch(res.data[0].courseName);
        // setAllBranches(dummyData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [courseId]);

  if (allBranches === null) {
    return <LoadingData />;
  }

  const DeleteCourse = () => {
    confirm({
      title: 'Are you sure delete this Course?',
      icon: <ExclamationCircleOutlined />,
      content: 'All the student within the course will be deleted.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        console.log('OK');
        ServerService.DeleteCourse(courseId)
          .then((res) => {
            history.push("/allCourses");
          })
          .catch((err) => {
            console.log(err);
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  return (
    <div className={styles.displayArea}>
      <Breadcrumb style={{ marginBottom: "24px" }}>
        {url.map((entry, index) => {
          return (
            <Breadcrumb.Item key={index}>
              <Link style={{ textDecoration: "none" }} to={`/${entry}`}>
                {entry}
              </Link>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
      {UserType === "admin" ? (
        <Button
          onClick={DeleteCourse}
          style={{
            float: "right",
          }}
        >
          <DeleteOutlined
            style={{
              color: "red",
              fontSize: "17.2px",
              paddingBottom: "0px",
              marginRight: "0px",
            }}
          />
          Delete Course
        </Button>
      ) : null}
      <h6 className={styles.heading}>
        {courseId === "all" ? "All Branches" : "Available Branches"}
      </h6>
      <div className={styles.allBranches}>
        {UserType === "admin" ? (
          <div
            onClick={() => history.push("/addBranch")}
            className={styles.addBranch}
          >
            <span>Add Branch</span>
            <img src={AddIcon} />
          </div>
        ) : null}

        {allBranches.map((branch, index) => {
          return (
            <BranchCard
              viewBranch={true}
              data={branch}
              index={index}
              courseId={courseId}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AllBranches;
