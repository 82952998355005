import MaleIcon from "../../assets/MaleUser.svg";
import FemaleIcon from "../../assets/FemailUser.svg";
import { NavLink } from "react-router-dom";

// const columns = [
//   {
//     name: '',
//     cell: (row) => {
//       if(row.gender === 'M'){
//         return(
//           <img src={MaleIcon} alt="male-icon"/>
//         )
//       }
//       else{
//         return(
//           <img src={FemaleIcon} alt="femail-icon"/>
//         )
//       }
//     }
//   },
//   {
//     name: "Receipt ID",
//     selector: "recieptId",
//     cell: (row) => {
//       return(
//         <NavLink style={{ color: 'black' }} to={`/studentDetails/${row.studentId}/view-receipt/${row.id}`}>
//           <div>{row.recieptId}</div>
//         </NavLink> 
//       )
//     }
//   },
//   {
//     name: "Roll No",
//     selector: "enrollmentId",
//     cell: (row) => {
//       return(
//         <NavLink style={{ color: 'black' }} to={`/studentDetails/${row.studentId}`}>
//           <div>{row.enrollmentId}</div>
//         </NavLink>
//       )
//     }
//   },
//   {
//     name: "Name",
//     selector: "studentName",
//   },
//   {
//     name: "Father Name",
//     selector: "fatherName",
//   },
//   {
//     name: "Year/Semester",
//     selector: "passing_span",
//   },
//   {
//     name: "Batch",
//     selector: "batch",
//   },
//   {
//     name: "Submitted At",
//     selector: "createdAt",
//     cell: (row) => {
//       return(
//         <div>{row.createdAt}</div>
//       )
//     }
//   },
//   {
//     name: "Amount Paid",
//     selector: "amount_paid",
//     cell: (row) => {
//       return(
//         <div>{`₹ ${row.amount_paid}`}</div>
//         )
//       }
//     },
//     {
//       name: "Submitted By",
//       selector: "submittedBy",
//     },
//     {
//       name: "Reference",
//       selector: "agent_name",
//     },
//     {
//       name: "Total Payable Amount",
//       selector: "totalPayableFee",
//       cell: (row) => {
//         return(
//           <div>{`₹ ${row.totalPayableFee}`}</div>
//           )
//         }
//       },
//     {
//       name: "Pending Amount",
//       selector: "pendingAmount",
//       cell: (row) => {
//         return(
//           <div>{`₹ ${row.pendingAmount}`}</div>
//           )
//         }
//       }
//   ];



const columns = [
  {
    title: '',
    dataIndex: '',
    render: (row) => {
      if (row.gender === 'M') {
        return (
          <img src={MaleIcon} alt="male-icon" />
        )
      }
      else {
        return (
          <img src={FemaleIcon} alt="femail-icon" />
        )
      }
    },
    width: 50,
  },
  {
    title: 'Receipt ID',
    dataIndex: ['recieptId','studentId','id'],
    key: 'recieptId',
    width: 90,
    render: (text, row) => {
      return (
        <NavLink style={{ color: 'black' }} to={`/studentDetails/${row['studentId']}/view-receipt/${row['id']}`}>
          <div>{row['recieptId']}</div>
        </NavLink>
      )
    }
  },
  {
    title: 'Enrollment ID',
    dataIndex: ['enrollmentId','studentId'],
    key: 'enrollmentId',
    width: 120,
    render: (text,row) => {
      return (
        <NavLink style={{ color: 'black' }} to={`/studentDetails/${row['studentId']}`}>
          <div>{row['enrollmentId']}</div>
        </NavLink>
      )
    }
  },
  {
    title: 'Name',
    dataIndex: 'studentName',
    key: 'studentName',
    width: 140,
  },
  {
    title: 'Father Name',
    dataIndex: 'fatherName',
    key: 'fatherName',
    width: 140,
  },
  {
    title: 'Year/Semester',
    dataIndex: 'passing_span',
    key: 'passing_span',
    width: 140,
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Batch',
    dataIndex: 'batch',
    key: 'batch',
    width: 100,
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Submitted At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 120,
  },
  {
    title: 'Amount Paid',
    dataIndex: 'amount_paid',
    key: 'amount_paid',
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (text, row) => {
      return (
        <div>{`₹ ${row['amount_paid']}`}</div>
      )
    }
  },
  {
    title: 'Submitted By',
    dataIndex: 'submittedBy',
    key: 'submittedBy',
    width: 120,
  },
  {
    title: 'Reference',
    dataIndex: 'agent_name',
    key: 'agent_name',
    width: 120,
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Total Payable Amount',
    dataIndex: 'totalPayableFee',
    key: 'totalPayableFee',
    width: 140,
    render: (text, row) => {
      return (
        <div>{`₹ ${text}`}</div>
      )
    }
  },
  {
    title: 'Pending Amount',
    dataIndex: 'pendingAmount',
    key: 'pendingAmount',
    width: 120,
    render: (text, row) => {
      return (
        <div>{`₹ ${text}`}</div>
      )
    }
  },
];

export default columns;