const data = [
  {
    course: 'B.Tech',
    session: '2019-23',
    promotedFrom: '1st Year',
    promotedTo: '2nd Year',
    date: '03-Dec-2021' 
  },
  {
    course: 'MCA',
    session: '2019-23',
    promotedFrom: '2nd Year',
    promotedTo: '3rd Year',
    date: '03-Dec-2021' 
  },
  {
    course: 'M.Tech',
    session: '2019-23',
    promotedFrom: '3rd Year',
    promotedTo: '4th Year',
    date: '03-Dec-2021' 
  },
  {
    course: 'B.Pharma',
    session: '2019-23',
    promotedFrom: '1st Year',
    promotedTo: '2nd Year',
    date: '03-Dec-2021' 
  },
  {
    course: 'MBA',
    session: '2019-23',
    promotedFrom: '3rd Year',
    promotedTo: '4th Year',
    date: '03-Dec-2021' 
  },
];

export default data;