import MaleIcon from "../../../../assets/MaleUser.svg";
import FemaleIcon from "../../../../assets/FemailUser.svg";
import { NavLink } from "react-router-dom";

function extraData(data){

  return 
}

// const columns = [
//   {
//     name: '',
//     cell: (row) => {
//       if(row.gender === 'M'){
//         return(
//           <img src={MaleIcon} alt="male-icon"/>
//         )
//       }
//       else{
//         return(
//           <img src={FemaleIcon} alt="femail-icon"/>
//         )
//       }
//     }
//   },
//   {
//     name: "Enrollment ID",
//     selector: "enrollment_id",
//     cell: (row) => {
//       return(
//         <NavLink style={{ color: 'black' }} to={`/studentDetails/${row.id}`}>
//           <div>{row.enrollment_id}</div>
//         </NavLink>
//       )
//     }
//   },
//   {
//     name: "Student Name",
//     selector: "first_name",
//     cell: (row) => {
//       return `${row.first_name} ${row.last_name}`
//     }
//   },
//   {
//     name: "Father Name",
//     selector: "father_name",
//   },
//   {
//     name: "Category",
//     selector: "category",
//   },
//   {
//     name: "Course",
//     selector: "course",
//   },
//   {
//     name: "Branch",
//     selector: "branch",
//   },
//   {
//     name: "Academic Year",
//     selector: "passing_span",
//   },
//   {
//     name: "Phone No.",
//     selector: "phone_number"
//   },
//   {
//     name: "Reference",
//     selector: "agent_detail"
//   },
//   {
//     name: "Added On",
//     selector: "created_at",
//   },
//   {
//     name: "Paid Fee",
//     selector: "paidAmount",
//   },
//   {
//     name: "Total Payable Fee",
//     selector: "totalPayableFee",
//   },
// ];

const columns = [
  {
    title: '',
    dataIndex: '',
    render: (row) => {
      if (row.gender === 'M') {
        return (
          <img src={MaleIcon} alt="male-icon" />
        )
      }
      else {
        return (
          <img src={FemaleIcon} alt="femail-icon" />
        )
      }
    },
    width: 50,
  },
  {
    title: 'Enrollment ID',
    dataIndex: ['enrollment_id','id'],
    key: 'enrollment_id',
    width: 120,
    render: (text,row) => {
      return (
        <NavLink style={{ color: 'black' }} to={`/studentDetails/${row['id']}`}>
          <div>{row['enrollment_id']}</div>
        </NavLink>
      )
    },
  },
  {
    title: 'Name',
    dataIndex: 'studentName',
    key: 'studentName',
    ellipsis: {
      showTitle: false,
    },
    width: 180,
    sorter: (a, b) => a.studentName.length - b.studentName.length,
    sortDirections: ['descend'],
  },
  {
    title: 'Father Name',
    dataIndex: 'father_name',
    key: 'father_name',
    width: 180,
    ellipsis: {
      showTitle: false,
    },
    sorter: (a, b) => a.father_name.length - b.father_name.length,
    sortDirections: ['descend'],
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    width: 100,
    filters: [
      {
        text: 'General',
        value: 'General',
      },
      {
        text: 'OBC',
        value: 'OBC',
      },
      {
        text: 'SC',
        value: 'SC',
      },
      {
        text: 'ST',
        value: 'ST',
      },
      {
        text: 'Other',
        value: 'Other',
      },
    ],
    onFilter: (value, record) => record.category.indexOf(value) === 0,
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Course',
    dataIndex: 'course',
    key: 'course',
    width: 100,
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Branch',
    dataIndex: 'branch',
    key: 'branch',
    width: 100,
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Academic Year',
    dataIndex: 'passing_span',
    key: 'passing_span',
    width: 140,
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Batch',
    dataIndex: 'batchName',
    key: 'batch',
    width: 100,
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Phone No.',
    dataIndex: 'phone_number',
    key: 'phone_number',
    width: 120,
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Reference',
    dataIndex: 'details',
    key: 'details',
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (text, row) => {
      return (
        <div>{row['details']['Reference']!=undefined?`${row['details']['Reference']}`:''}</div>
      )
    }
  },
  {
    title: 'Added On',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 120,
    ellipsis: {
      showTitle: false,
    },
  },
  // {
  //   title: 'Reference',
  //   dataIndex: 'agent_name',
  //   key: 'agent_name',
  //   width: 120,
  //   ellipsis: {
  //     showTitle: false,
  //   },
  // },
  {
    title: 'Paid Fee',
    dataIndex: 'paidAmount',
    key: 'paidAmount',
    width: 140,
    ellipsis: {
      showTitle: false,
    },
    render: (text, row) => {
      return (
        <div>{`₹ ${text}`}</div>
      )
    },
    sorter: (a, b) => a.paidAmount - b.paidAmount,
    sortDirections: ['descend'],
  },
  {
    title: 'Total Payable Fee',
    dataIndex: 'totalPayableFee',
    key: 'totalPayableFee',
    width: 120,
    ellipsis: {
      showTitle: false,
    },
    render: (text, row) => {
      return (
        <div>{`₹ ${text}`}</div>
      )
    }
  },
  {
    title: 'Total Pending Fee',
    dataIndex: 'pendingAmount',
    key: 'pendingAmount',
    width: 140,
    ellipsis: {
      showTitle: false,
    },
    render: (text, row) => {
      return (
        <div>{`₹ ${text}`}</div>
      )
    },
    sorter: (a, b) => a.pendingAmount - b.pendingAmount,
    sortDirections: ['descend'],
  },
];

export default columns;
