import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useParams } from "react-router";
import { Button, Switch } from "antd";
import ViewReceipt from "./ViewReceipt";
import ViewReport from "./ViewReport";
import { PrinterOutlined } from "@ant-design/icons";
import "./ViewReceipt.css";

const PrintViewReceipt = () => {
  const componentRef = useRef();
  const { studentId } = useParams();
  const { receiptId } = useParams();
  const { span_id } = useParams();

  return (
    <div className="displayArea">
      <ReactToPrint
        trigger={() => (
          <Button
            type="primary"
            style={{
              backgroundColor: "#09DCA8",
              margin: "auto",
              marginBottom: "14px",
              width: "803px",
            }}
          >
            <PrinterOutlined style={{ marginRight: "1.4px" }} />
            Print
          </Button>
        )}
        content={() => componentRef.current}
      />
        <ViewReceipt
        ref={componentRef}
        studentId={studentId}
        receiptId={receiptId}
        spanId={span_id}
      />
    </div>
  );
};

export default PrintViewReceipt;
