import React from "react";
import styles from "./Promotion.module.css";
import { Select, Button } from "antd";
import Skeleton from "react-loading-skeleton";

const { Option } = Select;

const LoadingScreen = () => {
  return (
    <div className={styles.displayArea}>
      <h6 className={styles.heading}>Promote students</h6>

      <div className={styles.promoteBlock}>
        <div className={styles.choiceArea}>
          <div className={styles.choice}>
            <Skeleton width={90} />
            <br />
            <Skeleton width={150} />
          </div>
          <div className={styles.choice}>
            <Skeleton width={90} />
            <br />
            <Skeleton width={150} />
          </div>

          <div className={styles.choice}>
            <Skeleton width={90} />
            <br />
            <Skeleton width={150} />
          </div>
        </div>

        <div className={styles.footer}>
        <Skeleton style={{marginRight:'30px'}} width={180} height={35}/>
          <Skeleton width={180} height={35}/>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
