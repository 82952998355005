import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import CourseCard from "../../../components/Cards/CourseCard/CourseCard";
import styles from "./AllCourses.module.css";
// import dummyData from "./dummyData";
import AddIcon from "../../../assets/AddIcon.svg";
import LoadingData from "./LoadingData";
import ServerService from "../../../API/ServerService";
import { Breadcrumb } from "antd";

const AllCourses = () => {
  let history = useHistory();
  const UserType = useSelector((state) => {
    if(state.auth.user){
      return state.auth.user.role
    }
  });
  const [courseData, setCourseData] = useState(null);
  let url = window.location.href;
  url = url.split("/");
  url.splice(2, 1, "Dashboard");

  useEffect(() => {
    ServerService.GetCourses()
      .then((res) => {
        // console.log(res);
        setCourseData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (courseData === null) {
    return <LoadingData />;
  }

  return (
    <div className={styles.displayArea}>
      <Breadcrumb style={{ marginBottom: "24px" }}>
        {url.slice(2).map((entry, index) => {
          return (
            <Breadcrumb.Item key={index}>
              <Link style={{ textDecoration: "none" }} to={`${entry}`}>
                {entry}
              </Link>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
      <div className={styles.allCourses}>
        {UserType === "admin" ? (
          <div
            onClick={() => history.push("/addCourse")}
            className={styles.addCourse}
          >
            <span>Add Course</span>
            <img src={AddIcon} />
          </div>
        ) : null}

        {courseData.map((course, index) => {
          return <CourseCard viewCourse={true} data={course} index={index} />;
        })}
      </div>
    </div>
  );
};

export default AllCourses;
