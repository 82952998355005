import axios from "./baseURL/baseURL";

class ServerService {
  GetHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
      },
    };
  }

  // // to get access token using refresh token
  // GetAccessToken(refresh) {
  //   return 
  // }

  // For logos and footer details
  ConfigApi(data) {
    // console.log("tring----------------->",data);
    return axios.post("/", data);
  }

  // AUTH-RELATED
  RegisterInstitution(registerData) {
    return axios.post(`/api/user/register/`, registerData);
  }
  SigninInstitution(signinData) {
    return axios.post(`/auth/login/`, signinData);
  }

  // DASHBOARD
  GetAdminDashboardData() {
    return axios.get(`/api/dashboard/`, this.GetHeaders());
  }

  // FOR ADMIN
  AddCourse(courseData) {
    return axios.post(`/api/course/`, courseData, this.GetHeaders());
  }
  EditCourse(id, courseData) {
    return axios.put(`/api/course/${id}/`, courseData, this.GetHeaders());
  }
  DeleteCourse(courseId) {
    return axios.delete(`/api/course/${courseId}/`, this.GetHeaders());
  }

  AddBranch(branchData) {
    return axios.post(`/api/branch/`, branchData, this.GetHeaders());
  }
  EditBranch(branchData, id) {
    return axios.put(`/api/branch/${id}/`, branchData, this.GetHeaders());
  }
  DeleteBranch(id) {
    return axios.delete(`/api/branch/${id}/`, this.GetHeaders());
  }
  AddBatch(batchData) {
    return axios.post(`/api/batch/`, batchData, this.GetHeaders());
  }
  DeleteBatch(batchId){
    return axios.delete(`/api/batch/${batchId}/`, this.GetHeaders());
  }
  GetBatchFee(batchNo) {
    return axios.get(`/api/batch/fee/${batchNo}/`, this.GetHeaders());
  }

  GetStaffList() {
    return axios.get(`/auth/staff/`, this.GetHeaders());
  }

  // For creating a staff
  AddNewUser(data) {
    return axios.post(`/auth/staff/`, data, this.GetHeaders());
  }
  // to view profile of the staff
  GetProfile(id) {
    return axios.get(`/auth/staff/${id}/`, this.GetHeaders());
  }
  // to edit profile of the staff
  EditStaffProfile(id, data) {
    return axios.patch(`/auth/staff/edit/${id}/`, data, this.GetHeaders());
  }

  EditAdminDetails(data) {
    return axios.patch(`/auth/institution/`, data, this.GetHeaders());
  }
  ResetPassword(data) {
    return axios.post(`/auth/change-password/`, data, this.GetHeaders());
  }

  // FOR ADMIN & STAFF
  GetCourses() {
    return axios.get(`/api/course/`, this.GetHeaders());
  }
  GetBranches(courseId) {
    if (courseId === "all") {
      return axios.get(`/api/branch/?course=`, this.GetHeaders());
    }
    return axios.get(`/api/branch/?course=${courseId}`, this.GetHeaders());
  }

  // CREATING-FEES
  GetBatchesForAddFee(branchId) {
    if (branchId === undefined) {
      return axios.get(`/api/addfee/dropdown/?branch=`, this.GetHeaders());
    }
    return axios.get(
      `/api/addfee/dropdown/?branch=${branchId}`,
      this.GetHeaders()
    );
  }
  GetSubmitFeeDropdown(studentId) {
    return axios.get(
      `/api/submitfee/dropdown/${studentId}/`,
      this.GetHeaders()
    );
  }
  GetFeeSelectionDropdown() {
    return axios.get(`/api/feereceipt/dropdown/`, this.GetHeaders());
  }
  GetFeeStatusTableData(pageSize, pageNo, reqBody) {
    return axios.post(
      `/api/fee/all-receipt/?page=${pageNo}&page_size=${pageSize}`,
      reqBody,
      this.GetHeaders()
    );
  }
  DownloadFeeStatusTableData(reqBody) {
    return axios.post(
      `/api/fee/all-receipt/?download=True`,
      reqBody,
      this.GetHeaders()
    );
  }
  GetFeeData(feeId) {
    return axios.get(`/api/fee/?id=${feeId}`, this.GetHeaders());
  }
  AddFeeForBatch(requestBody) {
    return axios.post(`/api/fee/`, requestBody, this.GetHeaders());
  }
  EditFeeForBatch(requestBody, feeId) {
    return axios.patch(`/api/fee/?id=${feeId}`, requestBody, this.GetHeaders());
  }

  SubmitStudentFee(requestBody) {
    return axios.post(`/api/fee/submit/0/`, requestBody, this.GetHeaders());
  }
  GetReceiptData(receiptId, studentId) {
    if (receiptId === "all") {
      return axios.get(
        `/api/fee/receipt/${studentId}/?query=all`,
        this.GetHeaders()
      );
    } else {
      return axios.get(`/api/fee/receipt/${receiptId}/`, this.GetHeaders());
    }
  }
  DeleteReceipt(receiptId) {
    return axios.delete(`/api/fee/receipt/${receiptId}/delete/`, this.GetHeaders());
  }
  
  GetReportData(spanId, studentId) {
    if (spanId === "all") {
      return axios.get(
        `/api/fee/student/${studentId}/report/${spanId}/?query=all`,
        this.GetHeaders()
      );
    } else {
      return axios.get(`/api/fee/student/${studentId}/report/${spanId}/`, this.GetHeaders());
    }
  }
  DeleteStudentData(studentId) {
    return axios.delete(`/api/studentdetails/${studentId}/`, this.GetHeaders());
  }

  EditStudentFee(requestBody, receiptId) {
    return axios.put(
      `/api/fee/submit/${receiptId}/`,
      requestBody,
      this.GetHeaders()
    );
  }

  // FORM-CREATION
  IsFormCreated() {
    return axios.get(`/api/student-form/`, this.GetHeaders());
  }
  CreateNewForm(reqBody) {
    return axios.post(`/api/student-form/`, reqBody, this.GetHeaders());
  }
  EditForm(reqBody) {
    return axios.put(`/api/student-form/`, reqBody, this.GetHeaders());
  }

  // ADD-STUDENTS
  GetBranchSelectionData() {
    return axios.get(`/api/addstudent/dropdown/`, this.GetHeaders());
  }
  UploadBulkStudents(batchId, reqBody) {
    return axios.post(
      `/api/students/add/${batchId}/`,
      reqBody,
      this.GetHeaders()
    );
  }

  // AGENTS-RELATED
  AddAgent(data) {
    return axios.post(`/api/agent/0/`, data, this.GetHeaders());
  }
  GetAgentsDropdown() {
    return axios.get(
      `/api/agent-dropdown/`,
      this.GetHeaders()
    );
  }
  GetAgentsList(pageNo, pageSize) {
    return axios.get(
      `/api/agent/?page=${pageNo}&page_size=${pageSize}`,
      this.GetHeaders()
    );
  }
  GetAgentsProfile(id) {
    return axios.get(`/api/agent/${id}/`, this.GetHeaders());
  }
  EditAgentsProfile(id, data) {
    return axios.put(`/api/agent/${id}/`, data, this.GetHeaders());
  }

  DeleteAgent(id) {
    return axios.delete(`/api/agent/${id}/`, this.GetHeaders());
  }

  // INDIVIDUAL STUDENT-RELATED
  GetStudentDetails(studentId) {
    return axios.get(`/api/studentdetails/${studentId}/`, this.GetHeaders());
  }
  GetStudentReceipts(studentId) {
    return axios.get(`/api/student/${studentId}/fee/`, this.GetHeaders());
  }
  AddIndividualStudent(reqBody) {
    return axios.post(`/api/student/`, reqBody, this.GetHeaders());
  }
  EditIndividualStudent(reqBody, studentId) {
    return axios.put(`/api/student-edit/${studentId}/`, reqBody, this.GetHeaders());
  }

  // STUDENT'S PROMOTION
  GetPromotionDropdownSelection() {
    return axios.get(`/api/promotestudent/dropdown/`, this.GetHeaders());
  }
  PromoteStudents(reqBody) {
    return axios.post(`/api/students/promote/`, reqBody, this.GetHeaders());
  }

  // TABLE-DATA
  GetViewStudents(pageSize, pageNum, reqBody) {
    return axios.post(
      `/api/students/?page_size=${pageSize}&page=${pageNum}`,
      reqBody,
      this.GetHeaders()
    );
  }
  GetOtherViewStudents(pageSize, pageNum, reqBody) {
    return axios.post(
      `/api/students/?page_size=${pageSize}&page=${pageNum}`,
      reqBody,
      this.GetHeaders()
    );
  }
  DownloadViewStudents(reqBody,courseId, branchId, year) {
    let downloadURL = `/api/students/download/`;
    if (courseId) {
      downloadURL += `?course=${courseId}`;
    }
    if (branchId) {
      downloadURL += `&branch=${branchId}`;
    }
    if (year) {
      downloadURL += `&year=${year}`;
    }
    if (courseId || branchId || year) {
      downloadURL += `&download=true`;
    }
    else{

      downloadURL += `?download=true`; 
    }
    return axios.get(downloadURL,
      // reqBody,
      this.GetHeaders());
  }

  // SEARCH-RELATED
  GetSearchStudents(searchType, nextPage=null, query=null) {
    console.log(nextPage);
    var path = `/api/search/?${searchType}=true&page_size=100`;
    if(query !== null){
     path = `/api/search/?${searchType}=true&query=${query}&page_size=100`;
    }
    if (nextPage !== null) {
      path+=`&page=${nextPage}`;
    }
    return axios.get(path, this.GetHeaders());
  }

  // FACULTY
  ViewAllFaculty(pageSize, pageNum, id) {
    return axios.get(
      `/api/faculty/${id}/?page=${pageNum}&page_size=${pageSize}`,
      this.GetHeaders()
    );
  }
  ViewFaculty(id) {
    return axios.get(`/api/faculty/${id}/`, this.GetHeaders());
  }
  AddFaculty(data) {
    return axios.post(`/api/faculty/0/`, data, this.GetHeaders());
  }
  EditFaculty(id, data) {
    return axios.put(`/api/faculty/${id}/`, data, this.GetHeaders());
  }
  DeleteFaculty(id) {
    return axios.delete(`/api/faculty/${id}/`, this.GetHeaders());
  }
}

export default new ServerService();
