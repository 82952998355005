import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Input, Button } from "antd";
import { message, notification } from "antd";
import AddFeeType from "./AddFeeType/AddFeeType";
import {
  TagsOutlined,
  DeleteTwoTone,
  ProfileOutlined,
} from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import ServerService from "../../API/ServerService";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";
import styles from "./EditFee.module.css";

const AddFee = () => {
  const { feeId } = useParams();
  const history = useHistory();

  const [selectedBatch, setSelectedBatch] = useState("Batch will come here...");
  const [selectedYear, setSelectedYear] = useState(
    "Semester/Year will come here..."
  );
  const [batchId, setBatchId] = useState(null);
  const [feeSpanId, setFeeSpanId] = useState(null);
  const [feeStatusData, setFeeStatusData] = useState([]);
  const [showAddField, setShowAddField] = useState(true);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setFeeStatusData([]);
    setShowAddField(true);
    setTotal(0);

    // Request to fetch data for batch
    ServerService.GetFeeData(feeId)
      .then((res) => {
        //console.log(res);
        setBatchId(res.data[0].batch);
        setFeeSpanId(res.data[0].feeSpanId);
        setSelectedBatch(res.data[0].batch_data);
        setSelectedYear(res.data[0].year_data);
        setFeeStatusData(res.data[0].fee_details);
        setTotal(Number(res.data[0].total_amount));
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);

  useEffect(() => {
    let total = 0;
    if (feeStatusData) {
      feeStatusData.map((feeData) => {
        total += Number(feeData.amount);
      });
      setTotal(total);
    }
  }, [feeStatusData]);

  const AddFeeDetails = (feeDetails) => {
    let newFeeData = {
      name: feeDetails.name,
      amount: Number(feeDetails.amount),
      comment: feeDetails.comment,
      included_in_base_fee: feeDetails.included_in_base_fee,
    };

    let feeData = [...feeStatusData];
    feeData.push(newFeeData);
    setFeeStatusData(feeData);
    setShowAddField(false);
  };

  const DeleteField = (idx) => {
    let feeData = [];
    feeStatusData.map((fee, index) => {
      if (index !== idx) {
        feeData.push(fee);
      }
    });
    setFeeStatusData(feeData);
  };

  const openNotification = (title, desc, icon, duration) => {
    notification.open({
      message: title,
      description: desc,
      icon: icon,
      duration: duration,
    });
  };

  const submitForm = () => {
    let key = "updatable";
    message.loading({ content: "Action in progress...", key });

    const reqBody = {
      batch: Number(batchId),
      fee_span: Number(feeSpanId),
      fee_details: feeStatusData,
    };
    //console.log(reqBody);

    ServerService.EditFeeForBatch(reqBody,feeId)
      .then((res) => {
        //console.log(res);
        // If fees for batch was edited successfully!
        message.success({ content: "Action completed!", key, duration: 2 });
        openNotification(
          "Fee editted successful!",
          "The fee was successfully editted for the selected batch!",
          <SmileOutlined style={{ color: "#108ee9" }} />,
          4.5
        );
        history.goBack();
      })
      .catch((err) => {
        //console.log(err);
        //If some error occured
        message.error({ content: "Something went wrong!", key, duration: 2 });
        openNotification(
          "Something went wrong!",
          "We are having some trouble creating the course. Please try again later",
          <FrownOutlined style={{ color: "red" }} />,
          6
        );
      });
  };

  return (
    <div className={styles.displayArea}>
      <div className={styles.addFeeForm}>
        <h6 className={styles.formHeader}>Edit Fee</h6>

        <h6 className={styles.label}>
          Batch<span style={{ color: "red" }}>*</span>
        </h6>
        <Input
          value={selectedBatch}
          style={{
            width: "100%",
            marginBottom: "24px",
          }}
          disabled={true}
          prefix={<ProfileOutlined className="site-form-item-icon" />}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <div style={{ width: "46%" }}>
            <h6 className={styles.label}>
              Semester/Year<span style={{ color: "red" }}>*</span>
            </h6>
            <Input
              value={selectedYear}
              style={{ width: "100%" }}
              disabled={true}
              prefix={<ProfileOutlined className="site-form-item-icon" />}
            />
          </div>
          <div style={{ width: "46%" }}>
            <h6 className={styles.label}>Total Fee Amount</h6>
            <Input
              value={total}
              disabled={true}
              prefix={<TagsOutlined className="site-form-item-icon" />}
            />
          </div>
        </div>

        <h6 className={styles.breakdown}>Fee - Breakdown</h6>
        <div className={styles.feeDetails}>
          <div className={styles.header}>
            <h6>Fee Type</h6>
            <h6>Fee Amount</h6>
            <h6>Note (Optional)</h6>
            <h6>Include in base-fee</h6>
          </div>
          <div className={styles.statusBody}>
            {feeStatusData &&
              feeStatusData.map((feeData, index) => {
                return (
                  <div className={styles.feeRow}>
                    <div className={styles.feeType}>{feeData.name}</div>
                    <div className={styles.feeAmount}>{feeData.amount}</div>
                    <div className={styles.feeNote}>
                      <span className={styles.feeNoteNote}>
                        {feeData.comment}
                      </span>
                    </div>
                    <div className={styles.check}>
                      <Checkbox
                        checked={feeData.included_in_base_fee}
                        style={{
                          transform: "translateY(-3px)",
                        }}
                        disabled={true}
                      />
                      <DeleteTwoTone
                        onClick={() => DeleteField(index)}
                        style={{ cursor: "pointer", transform: "scale(1.15)" }}
                        twoToneColor="red"
                      />
                    </div>
                  </div>
                );
              })}

            {showAddField ? (
              <AddFeeType AddFee={AddFeeDetails} />
            ) : (
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <Button
                  style={{
                    margin: "8px 0px",
                  }}
                  type="link"
                  onClick={() => setShowAddField(true)}
                >
                  + Add more fields
                </Button>
              </div>
            )}
          </div>
        </div>

        <Button
          type="primary"
          onClick={submitForm}
          className={styles.addFeeButton}
        >
          Edit Fee
        </Button>
      </div>
    </div>
  );
};

export default AddFee;
