import React from "react";
import "./UserDetail.css";
import UserDefaultPic from "../../../../../assets/UserDefaultPic.png";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { Menu, Dropdown } from "antd";
import { useHistory } from "react-router-dom";
import { SetUser } from "../../../../../redux/actions/actions";

const UserDetail = (props) => {
  const userData = useSelector((state) => state.auth.user);
  const history = useHistory("");

  const menu = (
    <Menu style={{marginTop: "4.8px"}}>
      <Menu.Item
        onClick={() => {
          history.push("/viewAccount");
        }}
      >
        Profile
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          props.setUserData(null);
          localStorage.clear();
          history.push("/UserLogin");
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <div className="userDetail">
        <div className="textDetails">
          <h6 className="userName">{userData.full_name}</h6>
          <h6 className="userStatus">{userData.role}</h6>
        </div>

        <img src={UserDefaultPic} alt="moess avatar"/>
      </div>
    </Dropdown>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (user) => dispatch(SetUser(user)),
  };
};

export default connect(null, mapDispatchToProps)(UserDetail);
