import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DashboardCard from "../../components/Cards/DashboardCard/DashboardCard";
import DoughnutGraph from "../../components/Cards/Graphs/DoughnutGraph/DoughnutGraph";
import BarGraph from "../../components/Cards/Graphs/BarGraph/BarGraph";
import LineGraph from "../../components/Cards/Graphs/LineGraph/LineGraph";
import Calendar from "../../components/Cards/Calender/Calendar";
// import NoticesCard from "../../components/Cards/NoticesCard/NoticesCard";
import "./Dashboard.css";

import TotalStudentsIcon from "../../assets/TotalStudentsIcon.svg";
import TotalTeachersIcon from "../../assets/TotalTeachersIcon.svg";
import TotalEarningsIcon from "../../assets/TotalEarningsIcon.svg";
import ServerService from "../../API/ServerService";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const history = useHistory();
  const UserType = useSelector((state) => {
    if (state.auth.user) {
      return state.auth.user.role
    }
  });
  const [pieGraphData, setPieGraphData] = useState(null);
  const [barGraphData, setBarGraphData] = useState(null);
  const [lineGraphData, setLineGraphData] = useState(null);
  const [noticesData, setNoticesData] = useState(null);
  const [Dashboard, setDashboard] = useState(null);

  useEffect(() => {
    ServerService.GetAdminDashboardData()
      .then((res) => {
        // console.log(res);
        setDashboard(res.data);
        setPieGraphData({
          males: res.data.male_count,
          females: res.data.female_count,
        });
        setBarGraphData(res.data.last_seven_days);
        setLineGraphData(res.data.last_seven_days);
        setNoticesData(res.data.notices);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="adminDashboard">
      <h6 className="heading">Dashboard</h6>
      <div className="dashboardDisplay">
        <DashboardCard
          icon={TotalStudentsIcon}
          label="Total Students"
          value={Dashboard ? Dashboard.students_count : null}
        />
        <DashboardCard
          icon={TotalTeachersIcon}
          label="Total Teachers"
          value={Dashboard ? Dashboard.faculty_count : null}
        />
        {UserType && UserType === "admin" ? (
          <DashboardCard
            icon={TotalEarningsIcon}
            label="Total Earning of Year"
            value={Dashboard ? Dashboard.total_earning : null}
          />
        ) : null}

        <DashboardCard
          hatIcon={true}
          icon={TotalEarningsIcon}
          label="Courses"
          color={0}
          value={Dashboard ? Dashboard.courses : null}
        />
        <DashboardCard
          icon={TotalEarningsIcon}
          label="Branches"
          hatIcon={true}
          color={1}
          value={Dashboard ? Dashboard.branches : null}
        />
        <DashboardCard
          icon={TotalEarningsIcon}
          label="Total Batches"
          hatIcon={true}
          color={2}
          value={Dashboard ? Dashboard.batches : null}
        />

        {/* <NoticesCard data={noticesData} /> */}
      </div>
      <div className="dashboardElements">
        <DoughnutGraph data={pieGraphData} />
        {/* {UserType === "admin" ? <BarGraph data={barGraphData} /> : null} */}
        {UserType === "admin" ? <LineGraph data={lineGraphData} /> : null}
        <Calendar />
      </div>
    </div>
  );
};

export default Dashboard;
