import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./BranchCard.module.css";
import { EditFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";

const BranchCard = (props) => {
  const history = useHistory();
  const UserType = useSelector((state) => state.auth.user.role);
  const colors = ["#66CDAA", "#CD8282", "#82A8CD", "#999999"];

  const editHandler = () => {
    // history.push(`/view-students/${props.courseId}/${props.data.id}`)
    history.push({
      pathname: `/addBranch`,
      state: {
        CourseName: props.data.courseName,
        branchName: props.data.branch_name,
        branchId: props.data.id,
        courseId: props.data.course,
      },
    });
  };

  const handleRedirection = () => {
    if (props.viewStudents) {
      history.push(`/view-students/${props.courseId}/${props.data.id}`);
    } else if (props.viewBranch) {
      history.push({
        pathname: `/batches/${props.data.id}`,
        state: {
          courseName: props.data.courseName,
          branchName: props.data.branch_name,
          courseId: props.courseId,
        },
      });
    } else {
      // history.push(`/branch/view/${props.data.id}`);
    }
  };

  return (
    <div
      style={{ borderColor: `${colors[props.index % 4]}` }}
      className={styles.branchCard}
    >
      <div
        style={{ backgroundColor: `${colors[props.index % 4]}` }}
        className={styles.cardIcon}
      >
        {/* <i class="fas fa-graduation-cap" /> */}
        <i class="fas fa-university" />
      </div>

      <div className={styles.cardContent}>
        <h6 className={styles.courseName}>{props.data.branch_name}</h6>
        <h6>
          <span style={{ paddingRight: "6px" }} className={styles.courseData}>
            {props.data.total_passing_timespans} Academic years
          </span>
          <span style={{ paddingLeft: "6px" }} className={styles.courseData}>
            {props.data.courseName}
          </span>
        </h6>
        <h6>
          <span className={styles.courseData}>
            {props.data.numOfStudents} Students
          </span>
        </h6>
      </div>

      <div onClick={handleRedirection} className={styles.arrowIcon}>
        <i class="fas fa-chevron-right" />
      </div>

      {UserType === "admin" ? (
        <span onClick={editHandler} className={styles.editButton}>
          <EditFilled />
        </span>
      ) : null}
    </div>
  );
};

export default BranchCard;
