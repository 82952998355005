import React from 'react';
import styles from "../Account/AccountType/AdminAccount/AdminAccount.module.css";
import { Button, Spin, Input, Form, InputNumber, message, notification, Breadcrumb } from 'antd';
import ServerService from '../../API/ServerService';
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";
import { Redirect, Link, useParams } from 'react-router-dom';

const EditAgentProfile = () => {

  const [ProfileData, setProfileData] = React.useState(null);
  const [redirect, setRedirect] = React.useState(null)

  const [form] = Form.useForm();
  const { editId } = useParams();

  const openNotification = (title, desc, icon, duration) => {
    notification.open({
      message: title,
      description: desc,
      icon: icon,
      duration: duration,
    });
  };

  const url = [{ name: "DashBoard", link: "/AdminDashboard" },
  { name: "view Agent", link: "/viewAgent" },
  { name: "Profile", link: `/viewAgent/Profile/${editId}` },
  { name: "edit", link: "" }]





  React.useEffect(() => {


    ServerService.GetAgentsProfile(editId)
      .then((res) => {
        // console.log(res);
        setProfileData(res.data)
      })
      .catch((err) => {
        // console.log(err);
      })

  }, [])


  const onSubmit = (values) => {

    let key = "updatable";
    message.loading({ content: "Action in progress...", key });

    const data =
    {
      name: values.name,
      phone_number: values.phone,
      address: values.address,
      commission: values.commission,
    }

    //  console.log(data);

    ServerService.EditAgentsProfile(editId, data)
      .then((res) => {
        // console.log(res);
        setRedirect("true");
        message.success({ content: "Action completed!", key, duration: 2 });
        openNotification(
          "Success",
          "Details editted successfully!",
          <SmileOutlined style={{ color: "#108ee9" }} />,
        );

      })
      .catch((err) => {
        // console.log(err);

        //If some error occured
        message.error({ content: "Something went wrong!", key, duration: 2 });
        openNotification(
          "Something went wrong!",
          "We are having some trouble editing the details. Please try again",
          <FrownOutlined style={{ color: "red" }} />,
          6
        );
        // form.resetFields();
      });

  }


  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  if (redirect) {
    return <Redirect to={`/viewAgent/Profile/${editId ? editId : null}`} />
  }

  return (
    <div className={styles.displayArea}>
      <h6 className={styles.heading}>Edit Agent Profiles Details</h6>
      {/* <h6 className={styles.subHeading}>ADMIN ACCOUNT</h6> */}

      <Breadcrumb style={{ marginBottom: "24px" }}>
        {url.map((entry, index) => {
          return <Breadcrumb.Item key={index}>
            <Link style={{ textDecoration: 'none' }} to={`${entry.link}`}>
              {entry.name}
            </Link>
          </Breadcrumb.Item>
        }
        )}
      </Breadcrumb>

      <div className={styles.institutionData}>

        {!ProfileData ? <div style={{ textAlign: "center" }}><Spin size="large" /></div> : (

          <div>
            <Form
              form={form}
              name="edit"
              className="add-course-form"
              initialValues={{
                "name": ProfileData.name,
                "address": ProfileData.address,
                "phone": parseInt(ProfileData.phone_number),
                "commission": parseInt(ProfileData.commission),

              }}
              onFinish={onSubmit}
              onFinishFailed={onFinishFailed}
            >
              {/* enrty 1 */}

              <h6 className={styles.dataLabel}>
                Name
              </h6>

              <Form.Item
                name="name"
                rules={[
                  {
                    // required: true,
                    // message: "Please enter the InstitutionName!",
                  },
                ]}
              >
                <Input
                  autoComplete="off"
                  placeholder="Enter name"

                //   value={CollegeData.institutionName}
                />
              </Form.Item>

              <h6 className={styles.dataLabel}>
                Commission
              </h6>

              <Form.Item
                name="commission"
                rules={[
                  {

                  },
                ]}
              >
                <Input
                  autoComplete="off"
                  placeholder="Enter Commission"
                  type="number"
                />
              </Form.Item>
              

              {/* entry 2 */}

              <h6 className={styles.dataLabel}>
                Address
              </h6>

              <Form.Item
                name="address"

              >
                <Input
                  autoComplete="off"
                  placeholder="Enter Address"
                />
              </Form.Item>

              {/* entry 3  */}

              <h6 className={styles.dataLabel}>
                Contact Number
              </h6>

              <Form.Item
                name="phone"
                rules={[
                  {
                    type: "number",
                    max: "15",
                    message: "Up to 15 digits allowed"
                  },
                ]}
              >
                <InputNumber
                  prefix="+"
                  placeholder="Contact Number"
                  style={{
                    width: '100%',
                  }}

                />
              </Form.Item>

              <Form.Item>
                <Button
                  block
                  htmlType="submit"
                  style={{ color: 'white', background: "rgb(9, 220, 168)", marginTop: "1rem" }}
                >Submit Edit Details
                </Button>
              </Form.Item>

            </Form>

            {/* <Button block style={{color:'white',background:"rgb(9, 220, 168)"}}> Submit Edit Details</Button> */}

          </div>)}

      </div>
    </div>
  );
};

export default EditAgentProfile;