import { useState } from "react";
import {
    Form,
    Input,
    Button,
    message,
} from "antd";
import {
    PlusOutlined,
    UserOutlined,
    FormOutlined,

} from "@ant-design/icons";
import ServerService from "../../../API/ServerService";
import { useHistory } from "react-router";

const { TextArea } = Input;

const AddFaculty = () => {
    const [img, setImg] = useState("");
    const [imageUrl, setImageUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();


    const handleUpload = (event) => {
        if (event.target.value.length > 0) {
            setImageUrl(URL.createObjectURL(event.target.files[0]));
            setImg(event.target.files[0]);
        }
    };

    const submitHandler = (values) => {
        setLoading(true);
        const data = {
            ...values,
            photo: img
        }
        const fd = new FormData();

        for (let formElement in data) {
            fd.append(formElement, data[formElement]);
        }
        let key = "updatable";

        ServerService.AddFaculty(fd).then(res => {
            // console.log(res)
            setLoading(false);
            message.success({ content: "Action completed!", key, duration: 2 });

            const timer = setTimeout(
                () => history.push("/view-faculty")
                ,
                2000
            );
            return () => clearTimeout(timer);
        }).catch(err => {
            console.log(err.response)
        })
    }
    return (
        <>
            <div className="displayArea">
                <div className="addStudentForm">
                    <h6 className="formHeader">Add Faculty</h6>

                    <Form
                        name="add_student"
                        onFinish={submitHandler}
                    >
                        {/* FACULTY'S IMAGE */}

                        <input
                            type="file"
                            id="facultyImg"
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={handleUpload}
                        />
                        <label htmlFor="facultyImg">
                            {imageUrl === null ? (
                                <div className="uploadButton">
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Upload</div>
                                </div>
                            ) : (
                                <img className="uploadButton" src={imageUrl} />
                            )}
                        </label>


                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter the faculty's name!",
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Name"
                                autoComplete="off"
                            />
                        </Form.Item>
                        <Form.Item
                            name="position"

                        >
                            <Input
                                prefix={<FormOutlined className="site-form-item-icon" />}
                                placeholder="Position"
                                autoComplete="off"
                            />
                        </Form.Item>

                        <Form.Item
                            name="contact_no"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter the faculty's contact number!",
                                },
                            ]}
                        >
                            <Input
                                prefix={<FormOutlined className="site-form-item-icon" />}
                                placeholder="Contact No."
                                autoComplete="off"
                                type="number"
                            />

                        </Form.Item>

                        <Form.Item
                            name="address"

                        >
                            <Input
                                prefix={<FormOutlined className="site-form-item-icon" />}
                                placeholder="Address"
                                autoComplete="off"
                            />
                        </Form.Item>

                        <Form.Item
                            name="qualifications"

                        >
                            <TextArea
                                rows={4}
                                placeholder="Qualifications"
                                autoComplete="off"
                            />
                        </Form.Item>
                        <Form.Item
                            name="experience"

                        >
                            <Input
                                prefix={<FormOutlined className="site-form-item-icon" />}
                                placeholder="Experience"
                                autoComplete="off"
                            />
                        </Form.Item>
                        <Form.Item
                            name="publications"

                        >
                            <Input
                                prefix={<FormOutlined className="site-form-item-icon" />}
                                placeholder="Publications"
                                autoComplete="off"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="add-student-form-button"
                                loading={loading}
                            >
                                Add Faculty
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default AddFaculty;