import React, { useState } from "react";
import { Spin, Breadcrumb } from "antd";
// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import styles from "../Account/AccountType/AdminAccount/AdminAccount.module.css";
// import LockIcon from "../../../../assets/LockIcon.svg";
import { useHistory, useParams, Link } from "react-router-dom";
import ServerService from "../../API/ServerService";

const UserProfile = () => {
  const history = useHistory();
  const { ProfileId } = useParams();

  const [userData, setUserData] = useState(null);

  React.useEffect(() => {
    ServerService.GetProfile(ProfileId)
      .then((res) => {
        console.log(res);
        setUserData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const url = [
    { name: "DashBoard", link: "/AdminDashboard" },
    { name: "view Account", link: "/viewAccount" },
    { name: "Profile", link: "/viewAccount/Profile" },
  ];

  return (
    <div className={styles.displayArea}>
      <h6 className={styles.heading}>Profile Details</h6>
      {/* <h6 className={styles.subHeading}>ADMIN ACCOUNT</h6> */}

      <Breadcrumb style={{ marginBottom: "24px" }}>
        {url.map((entry, index) => {
          return (
            <Breadcrumb.Item key={index}>
              <Link style={{ textDecoration: "none" }} to={`${entry.link}`}>
                {entry.name}
              </Link>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>

      <div className={styles.institutionData}>
        {!userData ? (
          <div style={{ textAlign: "center" }}>
            <Spin size="large" />
          </div>
        ) : (
          <div>
            <h6 className={styles.dataLabel}>Name</h6>
            <h6
              style={{ textDecoration: "underline" }}
              className={styles.dataValue}
            >
              {userData ? userData.fullName : "__"}
            </h6>
            <h6 className={styles.dataLabel}>Institution Name</h6>
            <h6 className={styles.dataValue}>
              {userData ? userData.institution : "__"}
            </h6>
            <h6 className={styles.dataLabel}>Email Address</h6>
            <h6 className={styles.dataValue}>
              {userData ? userData.email : "__"}
            </h6>
            <h6 className={styles.dataLabel}>Phone Number</h6>
            <h6 className={styles.dataValue}>
              {userData ? userData.phone : "__"}
            </h6>
            <h6 className={styles.dataLabel}>Designation</h6>
            <h6 className={styles.dataValue}>
              {userData ? userData.designation : "__"}
            </h6>
            <h6 className={styles.dataLabel}>Admin</h6>
            <h6 className={styles.dataValue}>
              {userData ? [userData.is_admin ? "Yes" : "No"] : "__"}
            </h6>
            <h6 className={styles.dataLabel}>Management</h6>
            <h6 className={styles.dataValue}>
              {userData ? [userData.management ? "Yes" : "No"] : "__"}
            </h6>

            {/* <div className={styles.editDetails}>Edit Details</div> */}

            <div
              onClick={() =>
                history.push(`/viewAccount/profile/edit/${ProfileId}`)
              }
              className={styles.editDetails}
            >
              Edit Details
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
