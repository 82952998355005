import { Line } from '@ant-design/charts';
import "./LineGraph.css";


const LineGraph = (props) => {
    let graphData=[]
    for (let i in props.data) {
    graphData.push(props.data[i]);}
    const data = graphData;

    const config = {
        data,
        xField: 'created_at__date',
        yField: 'sum',
        point: {
            size: 8,
            shape: 'diamond',
        },
        label: {
            style: {
                fill: '#aaa',
            },
        },
    };
    return (
        <div className="lineGraph">
            <h6 className="heading">EARNINGS</h6>
            <Line {...config} />
        </div>
    );
};

export default LineGraph;

